import * as React from "react";
import { Menu } from "@base-ui-components/react/menu";
import styled from "styled-components";

interface MenuItem {
  id: string;
  label: string;
  disabled?: boolean;
  selected?: boolean;
}

interface MenuSection {
  items: MenuItem[];
  showSeparator?: boolean;
}

interface DropdownButtonProps {
  label: string;
  icon?: React.ReactNode;
  sections: MenuSection[];
  onChange?: (itemId: string) => void;
  disabled?: boolean;
  selectedId?: string;
  selectedIds?: string[];
  multiSelect?: boolean;
}

const StyledButton = styled(Menu.Trigger)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #dbdcdd;
  background: #fcfcfc;
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 0;
  outline: 0;
  font-family: inherit;
  color: var(--PP-Text-Primary);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: var(--font-inter);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  user-select: none;
  cursor: pointer;
  svg {
    opacity: 0.8;
  }
  @media (hover: hover) {
    &:hover {
      background-color: var(--color-gray-100);
    }
  }

  &:active {
    background-color: var(--color-gray-100);
  }

  &[data-popup-open] {
    background-color: var(--color-gray-100);
  }

  &:focus-visible {
    outline: 2px solid var(--color-blue);
    outline-offset: -1px;
  }
`;

const ButtonIcon = styled.svg`
  margin-right: -0.25rem;
`;

const StyledPositioner = styled(Menu.Positioner)`
  outline: 0;
`;

const StyledPopup = styled(Menu.Popup)`
  box-sizing: border-box;
  padding-block: 0.25rem;
  border-radius: 0.375rem;
  background-color: canvas;
  color: var(--color-gray-900);
  padding: 0.25rem;
  transform-origin: var(--transform-origin);
  border: 1px solid #dbdcdd;
  padding: 0.25rem;
  border-color: hsl(240 5.9% 90%);
  box-shadow:
    0 0 #0000,
    0 0 #0000,
    0 10px 15px -3px rgba(0, 0, 0, 0.05),
    0 4px 6px -4px rgba(0, 0, 0, 0.05);
  transition:
    transform 150ms,
    opacity 150ms;

  &[data-starting-style],
  &[data-ending-style] {
    opacity: 0;
    transform: scale(0.9);
  }

  @media (prefers-color-scheme: light) {
    outline: 1px solid var(--color-gray-200);
    box-shadow:
      0px 10px 15px -3px var(--color-gray-200),
      0px 4px 6px -4px var(--color-gray-200);
  }

  @media (prefers-color-scheme: dark) {
    outline: 1px solid var(--color-gray-300);
    outline-offset: -1px;
  }
`;

const StyledArrow = styled(Menu.Arrow)`
  display: flex;

  &[data-side="top"] {
    bottom: -8px;
    rotate: 180deg;
  }
  &[data-side="bottom"] {
    top: -8px;
    rotate: 0deg;
  }
  &[data-side="left"] {
    right: -13px;
    rotate: 90deg;
  }
  &[data-side="right"] {
    left: -13px;
    rotate: -90deg;
  }
`;

const ArrowFillPath = styled.path`
  fill: canvas;
`;

const ArrowOuterStrokePath = styled.path`
  @media (prefers-color-scheme: light) {
    fill: var(--color-gray-200);
  }
`;

const ArrowInnerStrokePath = styled.path`
  @media (prefers-color-scheme: dark) {
    fill: var(--color-gray-300);
  }
`;

const StyledCheckboxItem = styled(Menu.CheckboxItem)`
  outline: 0;
  cursor: default;
  user-select: none;
  padding-block: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  gap: 8px;

  line-height: 1rem;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 4px;
  color: #09090b;

  &[data-highlighted] {
    z-index: 0;
    position: relative;
    color: var(--color-gray-50);
  }

  &[data-highlighted]::before {
    content: "";
    z-index: -1;
    position: absolute;
    inset-block: 0;
    inset-inline: 0.25rem;
    border-radius: 0.25rem;
    background-color: var(--color-gray-900);
  }

  &:hover {
    background-color: #f4f4f5;
  }
`;

const CheckboxIndicatorWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.1rem;
`;

const CheckboxIndicator = styled(Menu.CheckboxItemIndicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
`;

const CheckboxItemText = styled.span`
  flex: 1;
`;

const Separator = styled(Menu.Separator)`
  margin: 0.375rem 1rem;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

export default function DropdownButton({
  label,
  icon,
  sections,
  onChange,
  disabled,
  selectedId,
  selectedIds,
  multiSelect,
}: DropdownButtonProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleCheckedChange = (itemId: string) => {
    onChange?.(itemId);
    if (!multiSelect) {
      setIsOpen(false);
    }
  };

  const isItemSelected = (itemId: string) => {
    if (multiSelect && selectedIds) {
      return selectedIds.includes(itemId);
    }
    return itemId === selectedId;
  };

  return (
    <Menu.Root open={isOpen} onOpenChange={setIsOpen}>
      <StyledButton disabled={disabled}>
        {icon || <ChevronDownIcon />}
        {label}
      </StyledButton>
      <Menu.Portal>
        <StyledPositioner sideOffset={8} align='start'>
          <StyledPopup>
            <StyledArrow>
              <ArrowSvg />
            </StyledArrow>
            {sections?.map((section, sectionIndex) => (
              <React.Fragment key={sectionIndex}>
                {section.items?.map((item) => (
                  <StyledCheckboxItem
                    key={item.id}
                    checked={isItemSelected(item.id)}
                    disabled={item.disabled}
                    onCheckedChange={() => handleCheckedChange(item.id)}>
                    <CheckboxIndicatorWrapper>
                      <CheckboxIndicator>
                        <CheckedIcon />
                      </CheckboxIndicator>
                    </CheckboxIndicatorWrapper>
                    <CheckboxItemText>{item.label}</CheckboxItemText>
                  </StyledCheckboxItem>
                ))}
                {section.showSeparator && <Separator />}
              </React.Fragment>
            ))}
          </StyledPopup>
        </StyledPositioner>
      </Menu.Portal>
    </Menu.Root>
  );
}

function ArrowSvg(props: React.ComponentProps<"svg">) {
  return (
    <svg width='20' height='10' viewBox='0 0 20 10' fill='none' {...props}>
      <ArrowFillPath d='M9.66437 2.60207L4.80758 6.97318C4.07308 7.63423 3.11989 8 2.13172 8H0V10H20V8H18.5349C17.5468 8 16.5936 7.63423 15.8591 6.97318L11.0023 2.60207C10.622 2.2598 10.0447 2.25979 9.66437 2.60207Z' />
      <ArrowOuterStrokePath d='M8.99542 1.85876C9.75604 1.17425 10.9106 1.17422 11.6713 1.85878L16.5281 6.22989C17.0789 6.72568 17.7938 7.00001 18.5349 7.00001L15.89 7L11.0023 2.60207C10.622 2.2598 10.0447 2.2598 9.66436 2.60207L4.77734 7L2.13171 7.00001C2.87284 7.00001 3.58774 6.72568 4.13861 6.22989L8.99542 1.85876Z' />
      <ArrowInnerStrokePath d='M10.3333 3.34539L5.47654 7.71648C4.55842 8.54279 3.36693 9 2.13172 9H0V8H2.13172C3.11989 8 4.07308 7.63423 4.80758 6.97318L9.66437 2.60207C10.0447 2.25979 10.622 2.2598 11.0023 2.60207L15.8591 6.97318C16.5936 7.63423 17.5468 8 18.5349 8H20V9H18.5349C17.2998 9 16.1083 8.54278 15.1901 7.71648L10.3333 3.34539Z' />
    </svg>
  );
}

function ChevronDownIcon(props: React.ComponentProps<"svg">) {
  return (
    <svg width='10' height='10' viewBox='0 0 10 10' fill='none' {...props}>
      <path d='M1 3.5L5 7.5L9 3.5' stroke='currentcolor' strokeWidth='1.5' />
    </svg>
  );
}

function CheckedIcon(props: React.ComponentProps<"svg">) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      stroke='#000'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}>
      <path d='M13.3333 4L6 11.3333L2.66667 8' />
    </svg>
  );
}
