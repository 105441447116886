import NextLazyImage from "@/components/Core/NextLazyImage";
import mq from "@/utils/mq";
import { motion } from "motion/react";
import { useState } from "react";
import styled, { css } from "styled-components";

import Arrow from "@/assets/svg/arrowToTopRight.svg";
import Link from "next/link";

const BlogCollectionPreviewCardContainer = styled(motion.div)`
  border-radius: 16px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #4a7c55;
  cursor: pointer;
  overflow: hidden;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
`;

const BlogCollectionPreviewCardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  z-index: 5;
  position: relative;
  padding: 1.5rem;
  ${mq.mobile(css`
    padding: 16px;
  `)};
`;

const Title = styled.div`
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: #fff;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  user-select: none;
  text-decoration: none;
  outline: none;
  ${mq.mobile(css`
    font-size: 18px;
    line-height: 24px;
  `)};
`;

const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.5;
  background: linear-gradient(0deg, rgba(31, 33, 33, 0.66) 0%, rgba(31, 33, 33, 0) 62.53%),
    linear-gradient(180deg, rgba(31, 33, 33, 0.3) 0%, rgba(31, 33, 33, 0) 20.29%),
    linear-gradient(0deg, rgba(31, 33, 33, 0.2) 0%, rgba(31, 33, 33, 0.2) 100%);
`;

const ImageFade = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 80%);
`;

const BlogCollectionPreviewThumbnail = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  user-select: none;
  overflow: hidden;
  border-radius: 16px;
`;

const LowerLevel = styled(motion.div)`
  width: 100%;
  height: 0px;
  background: #4a7c55;
  overflow: hidden;
  position: relative;
`;

const LowerLabel = styled.div`
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1.25rem;
  font-weight: 500;
  color: #fff;
  margin: 0;
  text-decoration: none;
  outline: none;
`;

const LowerLevelInner = styled(motion.div)`
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #4a7c55;
  user-select: none;
`;

const ArrowIcon = styled.div`
  width: 20px;
  height: 20px;
  color: #fff;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const CardLayerAnim = {
  hidden: {
    height: 0,
    transition: {
      duration: 0.35,
      ease: [0.77, 0, 0.175, 1],
    },
  },
  visible: {
    height: 70,
    transition: {
      duration: 0.55,
      ease: [0.77, 0, 0.175, 1],
      delay: 0.1,
    },
  },
};

const LowerLayerAnim = {
  hidden: {
    padding: "0 0rem",
    transition: {
      duration: 0.35,
      ease: [0.77, 0, 0.175, 1],
    },
  },
  visible: {
    padding: "0 1.5rem",
    transition: {
      duration: 0.5,
      ease: [0.77, 0, 0.175, 1],
      delay: 0.1,
    },
  },
};

const ParallaxLayer = styled.div`
  position: absolute;
  width: 150%;
  height: 150%;
  transform: translateX(-25%);
  will-change: transform;
  transform-origin: center center;
  object-fit: cover;
`;

export default function BlogCollectionPreviewCard({ story }) {
  const [isActive, setIsActive] = useState(false);
  if (!story || !story?.content) return null;
  return (
    <Link href={story.full_slug} style={{ textDecoration: "none", outline: "none" }}>
      <BlogCollectionPreviewCardContainer onHoverStart={() => setIsActive(true)} onHoverEnd={() => setIsActive(false)}>
        <MainContent>
          <BlogCollectionPreviewCardContent>
            <Title>{story?.content?.title}</Title>
          </BlogCollectionPreviewCardContent>
          <BlogCollectionPreviewThumbnail>
            <ParallaxLayer className='parallax-layer'>
              <ImageOverlay />
              <ImageFade />
              <NextLazyImage
                src={story?.content?.thumbnail?.filename}
                alt={story?.content?.thumbnail?.alt}
                fill
                style={{ width: "100%", objectFit: "cover" }}
              />
            </ParallaxLayer>
          </BlogCollectionPreviewThumbnail>
        </MainContent>
        <LowerLevel variants={CardLayerAnim} initial='hidden' animate={isActive ? "visible" : "hidden"}>
          <LowerLevelInner variants={LowerLayerAnim} initial='hidden' animate={isActive ? "visible" : "hidden"}>
            <LowerLabel>Read full article</LowerLabel>
            <LowerLabel>→</LowerLabel>
          </LowerLevelInner>
        </LowerLevel>
      </BlogCollectionPreviewCardContainer>
    </Link>
  );
}
