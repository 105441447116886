import Link from "next/link";
import styled, { css } from "styled-components";

import ChevronRight from "@/assets/svg/chevronRight.svg";
import CollectionsIcon from "@/assets/svg/collections.svg";
import JobListingBreadcrumbIcon from "@/assets/svg/jobListings.svg";
import IconRenderer from "@/components/Shared/IconRenderer";
import mq from "@/utils/mq";

const BreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: fit-content;
  border: 1px solid #e8e8e8;
  background: var(--Light300);
  gap: 10px;
  padding: 4px 18px;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 0px;
`;

const BreadcrumbItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 3px;
  align-items: center;
  color: #677076;
  transition: all 0.2 (--ease-out-expo);
  &:hover {
    color: var(--PP-Text-Primary);
  }
`;

const BreadcrumbLabel = styled.div`
  color: var(--PP-Text-Primary);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 22px;
  ${mq.mobile(css`
    white-space: normal;
  `)};
`;

const BreadcrumbLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 22px;
`;

const ChevronContainer = styled.div`
  width: 12px;
  height: 12px;
  color: inherit;
`;

const BreadcrumbIconContainer = styled.div`
  width: 12px;
  height: 12px;
  color: inherit;
`;

const BreadcrumbImageIconContainer = styled.div`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background: var(--PrimaryAccent);
  margin-right: 2.5px;
`;

export default function Breadcrumbs({ breadcrumbs }) {
  return (
    <BreadcrumbsContainer>
      {breadcrumbs?.map((item, i) => {
        if (i === breadcrumbs.length - 1) {
          return <ActiveBreadcrumb key={i} breadcrumb={item} />;
        } else {
          return <NestedBreadcrumb key={i} breadcrumb={item} />;
        }
      })}
    </BreadcrumbsContainer>
  );
}

const NestedBreadcrumb = ({ breadcrumb }) => {
  return (
    <BreadcrumbItemContainer>
      {breadcrumb.hasIcon && breadcrumb.icon && (
        <BreadcrumbImageIconContainer>
          <img src={breadcrumb.icon} />
        </BreadcrumbImageIconContainer>
      )}
      {!breadcrumb.hasIcon && breadcrumb.isCollections && (
        <BreadcrumbIconContainer>
          <CollectionsIcon />
        </BreadcrumbIconContainer>
      )}
      {!breadcrumb.hasIcon && breadcrumb.isJobListing && (
        <BreadcrumbIconContainer>
          <JobListingBreadcrumbIcon />
        </BreadcrumbIconContainer>
      )}
      <BreadcrumbLink href={breadcrumb.url && breadcrumb.url}>{breadcrumb.title}</BreadcrumbLink>
      <ChevronContainer>
        <ChevronRight />
      </ChevronContainer>
    </BreadcrumbItemContainer>
  );
};

const ActiveBreadcrumb = ({ breadcrumb }) => {
  return (
    <BreadcrumbItemContainer>
      {breadcrumb.hasIcon && breadcrumb.icon && (
        <BreadcrumbImageIconContainer>
          <img src={breadcrumb.icon} />
        </BreadcrumbImageIconContainer>
      )}
      <BreadcrumbLabel>{breadcrumb.title}</BreadcrumbLabel>
    </BreadcrumbItemContainer>
  );
};
