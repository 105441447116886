import CardsSlider from "./CardsSlider";

const CardsSliderWithProps = ({ blok }) => {
  return (
    <CardsSlider
      sectionTitle={blok.sectionTitle}
      cardItems={blok.cardItems}
      displayControls={blok.displayControls}
      displayProgressBar={blok.displayProgressBar}
    />
  );
};

export default CardsSliderWithProps;
