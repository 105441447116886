import TeamGrid from "./TeamGrid";

const TeamGridWithProps = ({ blok }) => {
  return (
    <TeamGrid
      topLayerTitle={blok.topLayerTitle}
      topLayerIcon={blok.topLayerIcon}
      teamCategories={blok.teamCategories}
      theme={blok.theme}
      mainTitle={blok.mainTitle}
    />
  );
};

export default TeamGridWithProps;
