import React, { useState } from "react";
import CaseStudySectorSearch from "@/components/CaseStudiesRootHero/CaseStudySectorSearch";
import styled from "styled-components";
import mq from "@/utils/mq";
import { css } from "styled-components";

const CaseStudiesRootHeroContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 22px;
  padding-top: 64px;
  padding-bottom: 64px;
  @media (max-width: 1000px) {
    padding-top: 7rem;
  }
  ${mq.mobile(css`
    padding: 7rem 0 3rem 0;
    align-items: center;
    gap: 12px;
  `)};
`;

const Title = styled.h1`
  color: var(--PP-Text-Primary, #10181c);
  text-align: center;
  font-family: var(--inter);
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  max-width: 740px;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 32px;
    line-height: 44px;
    padding: 0 32px;
  `)};
`;

const Description = styled.h2`
  color: var(--PP-Text-Secondary, #677076);
  text-align: center;
  font-family: var(--inter);
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  max-width: 740px;
  text-wrap: pretty;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 16px;
    line-height: 28px;
    padding: 0 32px;
  `)};
`;

const SearchContainer = styled.div`
  width: 100%;
  padding-top: 32px;
  padding: 32px 16px 0 16px;
`;

const CaseStudiesRootHero = ({ title, description, activeSectors, onSectorToggle, setActiveSectors }) => {
  return (
    <CaseStudiesRootHeroContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <SearchContainer>
        <CaseStudySectorSearch
          activeSectors={activeSectors}
          onSectorToggle={onSectorToggle}
          setActiveSectors={setActiveSectors}
        />
      </SearchContainer>
    </CaseStudiesRootHeroContainer>
  );
};

export default CaseStudiesRootHero;
