import LargeCallToAction from "./LargeCallToAction";

const LargeCallToActionWithProps = ({ blok }) => {
  return (
    <LargeCallToAction
      pretextLabel={blok.pretextLabel}
      pretextIcon={blok.pretextIcon}
      title={blok.title}
      body={blok.body}
      ctas={blok.ctas}
      imageOne={blok.imageOne}
      imageTwo={blok.imageTwo}
      imageThree={blok.imageThree}
      imageFour={blok.imageFour}
      imageFive={blok.imageFive}
      imageSix={blok.imageSix}
      theme={blok.theme}
      addVerticalPaddingTop={blok.addVerticalPaddingTop}
      addVerticalPaddingBottom={blok.addVerticalPaddingBottom}
    />
  );
};

export default LargeCallToActionWithProps;
