import ContactUsHero from "./ContactUsHero";

const ContactUsHeroWithProps = ({ blok }) => {
  return (
    <ContactUsHero
      pillText={blok.pillText}
      title={blok.title}
      description={blok.description}
      actions={blok.actions}
      hubspotFormId={blok.hubspotFormId}
    />
  );
};

export default ContactUsHeroWithProps;
