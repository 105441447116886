import BlogPostAuthors from "@/components/Blog/BlogPostHero/BlogPostAuthors";
import styled, { css } from "styled-components";
import { format } from "date-fns";
import BlogPostLeadingImage from "@/components/BlogRework/BlogPostLeadingImage";
import BlogKeyTakeAways from "@/components/BlogRework/BlogKeyTakeAways";
import BlogPostBackButton from "@/components/BlogRework/BlogPostBackButton";
import mq from "@/utils/mq";
import BlogPostTopLayer from "@/components/BlogRework/BlogPostTopLayer";

const BlogPostHeroContainer = styled.header`
  padding: 4rem;
  padding-top: 1rem;
  padding-inline-start: 4rem;
  padding-inline-end: 4rem;
  @media (max-width: 1000px) {
    padding-top: 68px;
  }
  ${mq.mobile(css`
    padding-inline: 0;
    padding-top: 68px;
  `)};
`;

const Title = styled.h1`
  margin: 0;
  line-height: 1.2;
  letter-spacing: -0.0625rem;
  text-wrap: balance;
  font-size: 3rem;
  font-weight: 500;
  color: #09090b;
  ${mq.mobile(css`
    font-size: 2rem;
  `)};
`;

const BlogPostHeroMeta = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: 40% auto;
  margin-top: 2rem;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
  ${mq.mobile(css`
    margin-top: 1rem;
  `)};
`;

const BlogDescription = styled.div`
  font-size: 1em;
  line-height: 1.6;
  color: var(--PP-Text-Secondary);
`;

const PostLabelsWrapper = styled.div`
  display: grid;
  place-content: start end;
  margin-left: calc(0.33rem * -1);
  @media (max-width: 1000px) {
    margin-left: 0;
    place-content: start start;
  }
`;

const PostLabels = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-bottom: 3rem;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    margin-bottom: 0.5rem;
  }
`;

const PostLabel = styled.div`
  line-height: 0;
  padding: 6px 20px;
  border: 1px solid;
  border-color: var(--PrimaryAccentBackground);
  color: var(--PP-Text-Primary);
  background-color: var(--PrimaryAccentBackground);
  border-radius: 100px;
  display: inline-block;
  width: auto;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  border-radius: 30px;
  border: 1px solid #e8e8e8;
  background: var(--Light300);
  color: #677076;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 22px;
  span {
    color: var(--PP-Text-Primary);
  }
  ${mq.mobile(css`
    white-space: normal;
  `)};
`;

const PostAuthors = styled.div`
  display: grid;
  align-content: center;
`;

const PostAuthorsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  align-items: center;
`;

const BlogPostDate = styled.div`
  font-size: 1em;
  line-height: 1.45;
  color: var(--PP-Text-Secondary);
  opacity: 0.9;
`;

export default function BlogPostHero({
  title,
  description,
  category,
  tags,
  authors,
  publishDate,
  leadingImage,
  keyTakeAways,
  primaryCollection,
  advertiseAsPartOfCollection,
}: {
  title: string;
  description: string;
  category: { name: string };
  tags: { name: string }[];
  authors: any[];
  publishDate: string;
  leadingImage: any;
  keyTakeAways: any[];
  primaryCollection: any;
  advertiseAsPartOfCollection: any;
}) {
  return (
    <BlogPostHeroContainer>
      <BlogPostTopLayer
        primaryCollection={primaryCollection}
        advertiseAsPartOfCollection={advertiseAsPartOfCollection}
      />
      <Title>{title}</Title>
      <BlogPostHeroMeta>
        <BlogDescription>{description}</BlogDescription>
        <PostLabelsWrapper>
          <PostLabels>
            {category && <PostLabel>{category?.name}</PostLabel>}
            {tags && tags?.map((tag) => <PostLabel key={tag.name}>{tag.name}</PostLabel>)}
          </PostLabels>
        </PostLabelsWrapper>
        <PostAuthors>
          <PostAuthorsInner>
            <BlogPostAuthors authors={authors} />
            <BlogPostDate>{format(new Date(publishDate), "do MMMM yyyy")}</BlogPostDate>
          </PostAuthorsInner>
        </PostAuthors>
      </BlogPostHeroMeta>

      {leadingImage?.filename && <BlogPostLeadingImage image={leadingImage} />}
    </BlogPostHeroContainer>
  );
}
