import SectorPill from "@/components/CaseStudiesRootHero/SectorPill";
import { useAllSectors } from "@/hooks/useAllSectors";
import mq from "@/utils/mq";
import { motion } from "motion/react";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";

const SectorPillGridContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 700px;
  gap: 8px;
  ${mq.mobile(css`
    max-width: 100%;
  `)};
`;

export default function SectorPillGrid({ activeSectors, onSectorToggle }) {
  const { data: allSectors, isLoading, error } = useAllSectors();

  const inactiveSectors = useMemo(() => {
    if (!allSectors) return [];
    return allSectors.filter((sector) => !activeSectors.some((activeSector) => activeSector.slug === sector.slug));
  }, [allSectors, activeSectors]);

  if (isLoading) {
    return (
      <SectorPillGridContainer layout>
        {[...Array(20)]?.map((_, index) => (
          <SectorPill key={index} isLoading={true} width={Math.floor(Math.random() * (80 - 160 + 1)) + 100} />
        ))}
      </SectorPillGridContainer>
    );
  }

  if (error) {
    console.error("Error loading sectors:", error);
    return null;
  }

  return (
    <SectorPillGridContainer>
      {inactiveSectors?.map((sector) => (
        <SectorPill
          label={sector.content.menuTitle || sector.name}
          icon={sector.content.icon}
          id={sector.id}
          key={sector.id}
          isSelected={false}
          onClick={() => onSectorToggle(sector)}
        />
      ))}
    </SectorPillGridContainer>
  );
}
