/* eslint-disable react/no-unescaped-entities */
import styled from "styled-components";

const OuterContainer = styled.div`
  width: calc(100% - 20px);
  padding: 40px;
  background-color: #ffd3d4;
  color: #980202;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  max-width: 100vw;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 22px;
  line-height: 22px;
  font-weight: bold;
`;

const Description = styled.div`
  max-width: 450px;
  margin-top: 10px;
  text-align: center;
  line-height: 1.5;
`;

const LogoContainer = styled.div`
  height: 50px;
  width: 50px;
  margin-bottom: 0px;
`;

function BlokNotFound({ blok }: { blok: { component: string } }) {
  return (
    <OuterContainer>
      <LogoContainer />
      <Title>Block not found.</Title>
      <Description>
        Error: Block "<b>{blok.component}</b>" could not be found. If you think this is a mistake, please contact Josh.
      </Description>
    </OuterContainer>
  );
}

export default BlokNotFound;
