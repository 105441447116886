import { render } from "storyblok-rich-text-react-renderer";
import styled from "styled-components";
import Chevron from "@/assets/svg/chevronDown.svg";
import { useState } from "react";
import { motion } from "motion/react";

const QuestionRow = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const ChevronContainer = styled(motion.div)``;

const FaqItemWrapper = styled.div``;

const Question = styled.div`
  color: var(--PP-Text-Primary, #10181c);
  font-feature-settings:
    "liga" off,
    "clig" off;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
`;

const AnswerRow = styled(motion.div)`
  overflow: hidden;
  height: 0;
`;

const Answer = styled.div`
  color: var(--PP-Text-Secondary, #677076);
  font-feature-settings:
    "liga" off,
    "clig" off;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-top: 16px;
  text-align: left;
`;

export default function FAQItem({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FaqItemWrapper>
      <QuestionRow onClick={() => setIsOpen(!isOpen)}>
        <Question>{item.question}</Question>
        <ChevronContainer initial={{ rotate: 0 }} animate={{ rotate: isOpen ? 0 : 180 }}>
          <Chevron />
        </ChevronContainer>
      </QuestionRow>
      <AnswerRow initial={{ height: 0 }} animate={{ height: isOpen ? "auto" : 0 }}>
        <Answer>{render(item.answer)}</Answer>
      </AnswerRow>
    </FaqItemWrapper>
  );
}
