import cssModule from "@vercel/turbopack-next/internal/font/google/cssmodule.module.css?{%22path%22:%22generateFonts.ts%22,%22import%22:%22Inter%22,%22arguments%22:[{%22subsets%22:[%22latin%22],%22weight%22:[%22300%22,%22400%22,%22500%22,%22700%22],%22style%22:[%22normal%22],%22display%22:%22swap%22}],%22variableName%22:%22inter%22}";
const fontData = {
    className: cssModule.className,
    style: {
        fontFamily: "'Inter', 'Inter Fallback'",
        fontStyle: "normal",

    },
};

if (cssModule.variable != null) {
    fontData.variable = cssModule.variable;
}

export default fontData;
