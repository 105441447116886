//Content Type Imports
import StoryblokPage from "@/lib/storyblok/contentTypes/StoryblokPage";
import StoryblokBlogRoot from "@/lib/storyblok/contentTypes/StoryblokBlogRoot";

//Component Imports
import HomepageHero from "@/components/HomepageHero";
import DualBentoBox from "@/components/DualBentoBox";
import LogoMarquee from "@/components/LogoMarquee";
import StoryblokBlogPost from "@/lib/storyblok/contentTypes/StoryblokBlogPost";
import BlogCollectionSlider from "@/components/BlogCollectionSlider";
import StoryblokBlogCollection from "@/lib/storyblok/contentTypes/StoryblokBlogCollection";
import StoryblokBlogCollectionsRoot from "@/lib/storyblok/contentTypes/StoryblokBlogCollectionsRoot";
import BlogRichText from "@/components/Blog/BlogRichText";
import BlogPostImage from "@/components/Blog/BlogPostImage";
import CaseStudyStoriesSlider from "@/components/CaseStudyStoriesSlider";
import FullWidthGallerySlider from "@/components/FullWidthGallerySlider";
import CardsGrid from "@/components/CardsGrid";
import StoryblokCaseStudiesRoot from "@/lib/storyblok/contentTypes/StoryblokCaseStudiesRoot";
import StoryblokCaseStudy from "@/lib/storyblok/contentTypes/StoryblokCaseStudy";
import RichText from "@/components/RichText";
import PageHeroWithProps from "@/components/PageHero";
import TeamGrid from "@/components/TeamGrid";
import TitleWithDescriptionWithProps from "@/components/TitleWithDescription";
import FaqsWithProps from "@/components/Faqs";
import StoryblokSectorPage from "@/lib/storyblok/contentTypes/StoryblokSectorPage";
import QuoteWithProps from "@/components/Quote";
import StoryblokSectorsRootPage from "@/lib/storyblok/contentTypes/StoryblokSectorsRootPage";
import EmbeddedVideoWithProps from "@/components/EmbeddedVideo";
import EmbeddedSpotifyLinkWithProps from "@/components/EmbeddedSpotifyLink";
import HubspotFormWithProps from "@/components/HubspotForm";
import SustainabilityHeroWithProps from "@/components/SustainabilityHero";
import CardGalleryWithProps from "@/components/CardGallery";
import CardsSliderWithProps from "@/components/CardsSlider";
import ContactUsHeroWithProps from "@/components/ContactUsHero";
import HubspotInlineFormWithProps from "@/components/HubspotInlineForm";
import AllCaseStudiesGridWithProps from "@/components/AllCaseStudiesGrid";
import BlogFAQ from "@/components/Blog/BlogFAQ";
import BlogRichTextSectionWithProps from "@/components/Blog/BlogRichTextSection";
import BlogTableOfContentsWithProps from "@/components/Blog/BlogTableOfContents";
import ProductProConReview from "@/components/ProductProConReview";
import LargeCallToAction from "@/components/LargeCallToAction";
import StoryblokJobListing from "@/lib/storyblok/contentTypes/StoryblokJobListing";
import BlogContentItem from "@/components/BlogRework/BlogContentItem";
import BlogProConList from "@/components/BlogRework/BlogProConList";
import BlogFaqItem from "@/components/BlogRework/BlogFaqItem";
import BlogCallToAction from "@/components/BlogRework/BlogCallToAction";
import CtaBlock from "@/components/CtaBlock";
import StoryblokResourceCentre from "@/lib/storyblok/contentTypes/StoryblokResourceCentre";
import BlogCollectionSliderRework from "@/components/BlogCollectionSliderRework";
import BlogCollectionPreview from "@/components/BlogRootRework/BlogCollectionPreview";
import StoryblokVacanciesRoot from "@/lib/storyblok/contentTypes/StoryblokVacanciesRoot";
import StoryblokVideoLibraryEntry from "@/lib/storyblok/contentTypes/StoryblokVideoLibraryEntry";

const components = {
  //Content Types
  page: StoryblokPage,
  blogRoot: StoryblokBlogRoot,
  blogPost: StoryblokBlogPost,
  blogCollection: StoryblokBlogCollection,
  blogCollectionsRoot: StoryblokBlogCollectionsRoot,
  caseStudiesRoot: StoryblokCaseStudiesRoot,
  caseStudy: StoryblokCaseStudy,
  sectorPage: StoryblokSectorPage,
  sectorRootPage: StoryblokSectorsRootPage,
  jobListing: StoryblokJobListing,
  resourceCentre: StoryblokResourceCentre,
  vacanciesRoot: StoryblokVacanciesRoot,
  videoLibraryEntry: StoryblokVideoLibraryEntry,
  //Component Types
  homepageHero: HomepageHero,
  dualBentoBox: DualBentoBox,
  logoMarquee: LogoMarquee,
  blogCollectionSlider: BlogCollectionPreview,
  blogRichText: BlogRichText,
  blogPostImage: BlogPostImage,
  caseStudyStories: CaseStudyStoriesSlider,
  fullWidthGallerySlider: FullWidthGallerySlider,
  cardsGrid: CardsGrid,
  richText: RichText,
  pageHero: PageHeroWithProps,
  teamGrid: TeamGrid,
  titleWithDescription: TitleWithDescriptionWithProps,
  faqs: FaqsWithProps,
  quote: QuoteWithProps,
  embeddedVideo: EmbeddedVideoWithProps,
  embeddedSpotifyLink: EmbeddedSpotifyLinkWithProps,
  hubspotForm: HubspotFormWithProps,
  sustainabilityHero: SustainabilityHeroWithProps,
  cardGallery: CardGalleryWithProps,
  cardsSlider: CardsSliderWithProps,
  contactUsHero: ContactUsHeroWithProps,
  hubspotInlineForm: HubspotInlineFormWithProps,
  allCaseStudiesGrid: AllCaseStudiesGridWithProps,
  blogFaq: BlogFAQ,
  blogRichTextSection: BlogRichTextSectionWithProps,
  blogTableOfContents: BlogTableOfContentsWithProps,
  advancedBlogItem: BlogContentItem,
  blogProConList: BlogProConList,
  blogFaqItem: BlogFaqItem,
  blogCallToAction: BlogCallToAction,
  ctaBlock: CtaBlock,
  largeCallToAction: LargeCallToAction,
};

export default components;
