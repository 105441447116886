import { SectorStory } from "@/types/sectors";
import { useQuery } from "@tanstack/react-query";
import StoryblokClient from "storyblok-js-client";

interface WikiCardStory {
  id: number;
  name: string;
  content: any;
}

interface StoryblokResponse {
  stories: WikiCardStory[];
}

const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
});

const fetchAllCollections = async (cardId: string): Promise<WikiCardStory[]> => {
  try {
    const response = await Storyblok.get("cdn/stories/", {
      version: process.env.NEXT_PUBLIC_PRODUCTION_SITE === "true" ? "published" : "draft",
      filter_query: {
        component: {
          in: "inlineCard",
        },
        id: {
          in: cardId,
        },
      },
    });

    const typedResponse = response.data as StoryblokResponse;
    return typedResponse.stories;
  } catch (error) {
    console.error("Error fetching sector pages:", error);
    throw error;
  }
};

export const useSbWikiCard = (cardId: string) => {
  return useQuery<WikiCardStory[], Error>({
    queryKey: ["sbWikiCard", cardId],
    queryFn: () => fetchAllCollections(cardId),
    enabled: !!cardId,
  });
};
