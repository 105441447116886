import { useQuery } from "@tanstack/react-query";
import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID!,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY!,
);

const index = searchClient.initIndex("content_index");

type ContentType = "blogPost" | "caseStudy" | "sectorEntry" | "blogCollection" | "sectorPage"; // Added "sectorPage"

interface SearchResult {
  objectID: string;
  slug: string;
  content_type: ContentType;
  title: string;
  _uid: string;
  icon: any[];
  component: string;
  // Add other fields as needed
}

interface SearchParams {
  query?: string;
  contentTypes?: ContentType[];
  hitsPerPage?: number;
  uids?: string[];
}

const performSearch = async ({
  query,
  contentTypes,
  hitsPerPage = 20,
  uids,
}: SearchParams): Promise<SearchResult[]> => {
  try {
    let filters = "";

    if (contentTypes && contentTypes.length > 0) {
      filters += contentTypes?.map((type) => `content_type:'${type}'`).join(" OR ");
    }

    if (uids && uids.length > 0) {
      const uidFilter = uids?.map((uid) => `_uid:'${uid}'`).join(" OR ");
      filters += filters ? ` AND (${uidFilter})` : uidFilter;
    }

    const { hits } = await index.search(query || "", {
      hitsPerPage,
      filters,
    });

    return hits as SearchResult[];
  } catch (error) {
    console.error("Error performing Algolia search:", error);
    throw error;
  }
};

export const useAlgoliaSearch = (searchParams: SearchParams) => {
  return useQuery<SearchResult[], Error>({
    queryKey: ["algoliaSearch", searchParams],
    queryFn: () => performSearch(searchParams),
    enabled: !!(searchParams.query || searchParams.uids),
  });
};
