import styled, { css } from "styled-components";
import CollectionIcon from "@/assets/svg/collections.svg";
import CtaRenderer from "@/components/Shared/CtaRenderer";
import mq from "@/utils/mq";
import IconRenderer from "@/components/Shared/IconRenderer";

const QuickLinkCardContainer = styled.div`
  width: 100%;
  //aspect-ratio: 2/1;
  border: 1px solid #e1e3e4;
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  ${mq.mobile(css`
    padding: 16px;
  `)};
`;

const IconContainer = styled.div`
  width: 26px;
  height: 26px;
  color: #677076;
  svg {
    width: 100%;
    height: 100%;
    stroke-width: 0.75;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Title = styled.h3`
  color: #10181c;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  ${mq.mobile(css`
    font-size: 18px;
    line-height: 22px;
  `)};
`;

const Description = styled.p`
  color: #677076;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
`;

export default function QuickLinkCard({ card }) {
  return (
    <QuickLinkCardContainer>
      <IconContainer>
        <IconRenderer icon={card?.icon} />
      </IconContainer>
      <Content>
        <Title>{card?.title}</Title>
        <Description>{card?.bodyText}</Description>
        {card?.cta && <CtaRenderer ctas={card?.cta} />}
      </Content>
    </QuickLinkCardContainer>
  );
}
