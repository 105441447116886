import CtaRenderer from "@/components/Shared/CtaRenderer";
import mq from "@/utils/mq";
import { themeProvider } from "@/utils/ThemeProvider";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
  padding: 168px 24px;
  ${mq.mobile(css`
    padding: 86px 24px;
  `)};
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  text-align: center;
  text-decoration-skip-ink: none;
  width: min(100%, 582px);
  ${mq.mobile(css`
    font-size: 24px;
    line-height: 32px;
    width: min(100%, 408px);
  `)};
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  width: min(100%, 428px);
  margin-top: 8px;
  color: #677076;
  ${mq.mobile(css`
    font-size: 14px;
    line-height: 24px;
    width: min(100%, 386px);
  `)};
`;

const CtaContainer = styled.div`
  margin-top: 24px;
`;

export default function CtaBlock({ title, description, ctas, theme }) {
  return (
    <OuterContainer style={{ backgroundColor: themeProvider(theme).backgroundColor }}>
      <Title>{title}</Title>
      <Description>{render(description)}</Description>
      <CtaContainer>
        <CtaRenderer ctas={ctas} />
      </CtaContainer>
    </OuterContainer>
  );
}
