// components/VideoLibrary/index.ts

// Core components
export { default as VideoLibrary } from "./core/VideoLibrary";
export { default as VideoPlayer } from "./core/VideoPlayer";
export { default as VideoPreviewBackground } from "./core/VideoPreviewBackground";
export { default as VideoLibraryNavigation } from "./core/VideoLibraryNavigation";

// Navigation components
export { default as VideoGrid } from "./navigation/VideoGrid";
export { default as VideoThumbnail } from "./navigation/VideoThumbnail";
export { default as VideoFilter } from "./navigation/VideoFilter";

// UI components
export { default as VideoMeta } from "./ui/VideoMeta";
export { default as VideoPlayButton } from "./ui/VideoPlayButton";
export { default as VideoLibraryControlButton } from "./ui/VideoLibraryControlButton";
export { default as PlayerPlayButton } from "./ui/PlayerPlayButton";
export { default as PlayerPositionSlider } from "./ui/PlayerPositionSlider";
export { default as PlayerVolumeSlider } from "./ui/PlayerVolumeSlider";
export { default as PlayerFullScreenToggle } from "./ui/PlayerFullScreenToggle";

// JSON-LD components
export { default as VideoJsonLd } from "@/components/Shared/JsonLD/VideoJsonLd";

// Hooks
export { useVideoPreloading } from "./hooks/useVideoPreloading";
export { useVideoNavigation } from "./hooks/useVideoNavigation";
export { useVideoLibraryCategories } from "@/hooks/useVideoLibraryCategories";

// Store
export { useVideoPlayerStore } from "./store/videoPlayerStore";

// Utils
export * from "./utils/videoHelpers";

// Types
export * from "./types";
