import React, { useRef } from "react";
import CardGalleryItem from "@/components/CardGallery/CardGalleryItem";
import ContentWrapper from "@/components/Core/ContentWrapper";
import mq from "@/utils/mq";
import { render, NODE_PARAGRAPH } from "storyblok-rich-text-react-renderer";
import styled from "styled-components";
import { css } from "styled-components";
import { motion, useInView } from "motion/react";

const Title = styled.h2`
  color: var(--PP-Text-Primary);
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin-top: -2px;
  padding-bottom: 3rem;
  z-index: 2;
  position: relative;
  ${mq.mobile(css`
    font-size: 24px;
    line-height: 32px;
  `)};
  .highlight {
    position: relative;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
`;

const svgUnderline = `
<svg width="775" height="157" viewBox="0 0 775 157" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.4961 116.906C39.8575 85.6468 40.3005 84.2063 90.5309 77.3009C143.796 69.9799 378.84 20.1582 743.869 94.0964" stroke="#1CD956" stroke-width="89.1324" stroke-linecap="round"/>
</svg>
`;

const encodedSvg = encodeURIComponent(svgUnderline);

const HighlightWrapper = styled.span`
  position: relative;
  display: inline-block;
`;

const HighlightAnimation = styled(motion.span)`
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("data:image/svg+xml,${encodedSvg}");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
`;

const highlightVariants = {
  hidden: { scaleX: 0, originX: 0 },
  visible: {
    scaleX: 1,
    originX: 0,
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
};

export default function CardGallery({ title, cards }) {
  const wrapHighlightElements = (content) => {
    if (typeof content === "string") {
      return content;
    }

    if (Array.isArray(content)) {
      return content?.map((child, index) => wrapHighlightElements(child));
    }

    if (React.isValidElement(content)) {
      const props = content.props as any;

      if (props.children) {
        const newChildren = wrapHighlightElements(props.children);
        return React.cloneElement<any>(content, { children: newChildren });
      }
    }

    return content;
  };

  const renderedTitle = render(title, {
    nodeResolvers: {
      [NODE_PARAGRAPH]: (children) => <p>{wrapHighlightElements(children)}</p>,
    },
  });

  return (
    <ContentWrapper>
      <Title>{renderedTitle}</Title>
      <CardsContainer>{cards?.map((card, i) => <CardGalleryItem key={i} item={card} />)}</CardsContainer>
    </ContentWrapper>
  );
}
