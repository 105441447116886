import EmbeddedSpotifyLink from "./EmbeddedSpotifyLink";

const EmbeddedSpotifyLinkWithProps = ({ blok }) => {
  return (
    <EmbeddedSpotifyLink
      spotifyLink={blok.spotifyLink}
      theme={blok.theme}
      wideMode={blok.wideMode}
      enableVerticalPadding={blok.enableVerticalPadding}
    />
  );
};

export default EmbeddedSpotifyLinkWithProps;
