import ContentWrapper from "@/components/Core/ContentWrapper";
import NextLazyImage from "@/components/Core/NextLazyImage";
import { BREAKPOINT_SM } from "@/constants";
import mq from "@/utils/mq";
import styled, { css } from "styled-components";

const QuoteContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  height: fit-content;
  position: relative;
  max-width: calc(800px + 3rem);
  width: 100%;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: ${BREAKPOINT_SM}px) {
    max-width: calc(900px + 4.5rem);
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  ${mq.mobile(css`
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1.5rem;
  `)};
`;

const QuoteInnerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  ${mq.mobile(css`
    gap: 0px;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
  `)};
`;

const QuoteLine = styled.div`
  height: 100%;
  width: 2px;
  opacity: 0.5;
  background-color: #677076;
  position: absolute;
  left: 0;
  top: 0;
`;

const QuoteDecoration = styled.div`
  font-size: 32px;
  line-height: 44px;
  color: var(--PP-Text-Secondary);
  margin-left: 1.5rem;
  ${mq.mobile(css`
    margin-left: 0rem;
    line-height: 32px;
  `)};
`;

const QuoteBody = styled.div``;

const QuoteText = styled.div`
  font-weight: 500;
  color: var(--PP-Text-Secondary, #677076);
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.01em;
  font-style: italic;
  font-family: var(--font-pp-neue);
  ${mq.mobile(css`
    font-size: 18px;
    line-height: 28px;
  `)};
`;

const QuoteMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 24px;
  ${mq.mobile(css`
    margin-top: 16px;
  `)};
`;

const QuoteeInfo = styled.div`
  font-size: 13px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
`;

const QuoteName = styled.div`
  font-weight: 500;
  color: var(--PP-Text-Primary);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  font-family: var(--font-pp-neue);
`;

const QuoteRole = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  font-weight: 500;
  color: var(--PP-Text-Secondary, #677076);
  font-family: var(--font-pp-neue);
`;

const QuoteAvatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 6px;
`;

export default function Quote({
  quote,
  quoteeName,
  quoteeRole,
  quoteeCompany,
  quoteeAvatar,
  theme,
  enablePaddingTop,
  enablePaddingBottom,
}) {
  return (
    <ContentWrapper
      theme={theme}
      addVerticalPaddingTop={enablePaddingTop}
      addVerticalPaddingBottom={enablePaddingBottom}>
      <QuoteContainer>
        <QuoteInnerWrapper>
          <QuoteLine />
          <QuoteDecoration>“</QuoteDecoration>
          <QuoteBody>
            <QuoteText>{quote}</QuoteText>
            <QuoteMeta>
              <QuoteAvatar>
                <NextLazyImage src={quoteeAvatar.filename} alt={quoteeAvatar.alt} width={40} height={40} />
              </QuoteAvatar>

              <QuoteeInfo>
                <QuoteName>{quoteeName}</QuoteName>
                <QuoteRole>{quoteeRole}</QuoteRole>
              </QuoteeInfo>
            </QuoteMeta>
          </QuoteBody>
        </QuoteInnerWrapper>
      </QuoteContainer>
    </ContentWrapper>
  );
}
