import React from "react";
import styled from "styled-components";
import { motion } from "motion/react";

import NextLazyImage from "@/components/Core/NextLazyImage";
import BackgroundVideo from "@/components/Shared/BackgroundVideo";
import { useRouter } from "next/router";
import parseStoryblokLink from "@/utils/parseStoryblokLink";
import StoryItemInactivePreview from "@/components/CaseStudyStoriesSlider/CaseStudyStoryItem/StoryItemInactivePreview";
import StoryItemControls from "@/components/CaseStudyStoriesSlider/CaseStudyStoryItem/StoryItemControls";

const DarkenOverlay = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: brightness(0.7) saturate(0.3);
  z-index: 5;

  pointer-events: none;
`;

const DarkenMedia = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 5;
  background: linear-gradient(0deg, rgba(31, 33, 33, 0.66) 0%, rgba(31, 33, 33, 0) 62.53%),
    linear-gradient(180deg, rgba(31, 33, 33, 0.3) 0%, rgba(31, 33, 33, 0) 20.29%),
    linear-gradient(0deg, rgba(31, 33, 33, 0.2) 0%, rgba(31, 33, 33, 0.2) 100%);
`;

export default function StoryItemMediaHandler({
  active,
  isHovered,
  backgroundMedia,
  backgroundOverlay,
  onClick,
  id,
  caseStudyLink,
  brandImage,
  brandName,
  tags,
  count = 0,
  handlePauseResume = () => {},
  isPaused = false,
}) {
  const router = useRouter();
  const handleClick = () => {
    if (active) {
      if (parseStoryblokLink(caseStudyLink)) {
        router.push(parseStoryblokLink(caseStudyLink));
      }
    } else {
      onClick(id);
    }
  };

  return (
    <>
      <StoryItemInactivePreview active={active} brandName={brandName} brandImage={brandImage} />
      <StoryItemControls
        active={active}
        brandImage={brandImage}
        brandName={brandName}
        handlePauseResume={handlePauseResume}
        count={count}
        tags={tags}
        isPaused={isPaused}
      />
      <DarkenOverlay animate={{ opacity: !active && !isHovered ? 1 : 0 }} initial={{ opacity: !active ? 1 : 0 }} />
      <DarkenMedia />
      <div onClick={handleClick} style={{ width: "100%", height: "100%" }}>
        <BackgroundMediaHandler
          active={active}
          backgroundMedia={backgroundMedia}
          backgroundOverlay={backgroundOverlay}
        />
      </div>
    </>
  );
}

const BackgroundMediaHandler = ({ backgroundMedia, backgroundOverlay, active }) => {
  if (backgroundMedia.component === "imageBackground") {
    return (
      <NextLazyImage
        src={backgroundMedia.image.filename}
        alt={backgroundMedia.image.alt || "Background media image"}
        priority
        quality={50}
        width={640}
        height={480}
        style={{ objectFit: "cover", width: "100%", height: "100%", backgroundPosition: "center center" }}
      />
    );
  }
  if (backgroundMedia.component === "videoBackground") {
    return (
      <BackgroundVideo
        posterSrc={backgroundMedia?.poster?.filename}
        posterAlt={backgroundMedia?.poster?.alt || "Background media poster"}
        videoSrc={backgroundMedia?.video?.filename}
        videoAlt={backgroundMedia?.video?.alt || "Background media video"}
        pause={!active}
        blurVideo={!active}
        playerStyles={{
          objectFit: "cover",
          width: "100%",
          height: "480px",
          backgroundPosition: "center center",
        }}
        containerStyles={{ objectFit: "cover", width: "auto", height: "480px", backgroundPosition: "center center" }}
      />
    );
  }
};
