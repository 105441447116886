import SectorsHero from "@/components/SectorsHero";
import BreadcrumbJsonLd from "@/components/Shared/JsonLD/BreadcrumbJsonLd";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import { generateSeoBreadcrumbs } from "@/utils/generateSeoBreadcrumbs";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import styled from "styled-components";

const SectorsRootPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
`;

export default function StoryblokSectorsRootPage({ blok }) {
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_ROOT_DOMAIN || "https://www.plantplan.co.uk/";
  return (
    <SectorsRootPageContainer>
      {blok.breadcrumbs && blok.breadcrumbs.length > 0 && (
        <BreadcrumbJsonLd
          items={generateSeoBreadcrumbs({
            breadcrumbs: blok.breadcrumbs,
            baseUrl,
            currentPath: router.asPath,
            pageTitle: blok.title,
          })}
          baseUrl={baseUrl}
        />
      )}
      <NextSeo additionalMetaTags={[{ name: "keywords", content: blok?.keywords?.value?.join(", ") }]} />
      <SectorsHero title={blok.heroTitle} description={blok.heroDescription} />
      {blok.body && blok.body.length > 0 && <BlokRenderer blok={blok} key={blok?.slug} />}
    </SectorsRootPageContainer>
  );
}
