import styled from "styled-components";

const EmptyPageMessageOuterContainer = styled.div`
  background-color: #fff;
`;

const EmptyPageMessageInnerContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 14px;
  opacity: 0.7;
  margin-top: 10px;
  max-width: 400px;
  text-align: center;
`;

function EmptyPageMessage() {
  return (
    <EmptyPageMessageOuterContainer>
      <EmptyPageMessageInnerContainer>
        <Title>Build something incredible!</Title>
        <Description>
          This is an empty page. <b>Head to the &ldquo;body&ldquo; section of the Storyblok Editor</b> and add a block
          to get started.
        </Description>
      </EmptyPageMessageInnerContainer>
    </EmptyPageMessageOuterContainer>
  );
}

export default EmptyPageMessage;
