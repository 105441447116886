import BlogCollectionGrid from "@/components/Blog/BlogCollectionGrid";
import BlogCollectionHero from "@/components/Blog/BlogCollectionHero";
import BlogRootHero from "@/components/BlogRootHero/BlogRootHero";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import { render } from "storyblok-rich-text-react-renderer";
import styled from "styled-components";

const BlogCollectionContainer = styled.div`
  padding: 0;
`;

const StoryblokBlogCollection = ({ blok }: { blok: any }) => {
  return (
    <BlogCollectionContainer>
      <BlogCollectionHero
        title={blok.title}
        description={blok.description}
        breadcrumbs={[
          { title: "Blog", url: "/blog" },
          { title: "Collections", url: "/blog/d/collections", isCollections: true },
          { title: blok.title },
        ]}
        canShare={false}
        shareLink={""}
      />
      <BlogCollectionGrid collection={blok} />
    </BlogCollectionContainer>
  );
};

export default StoryblokBlogCollection;
