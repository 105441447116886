import React from "react";
import DualBentoBox from "@/components/DualBentoBox/DualBentoBox";
import { render } from "storyblok-rich-text-react-renderer";

export default function index({ blok }) {
  return (
    <DualBentoBox
      sectionTitle={blok.sectionTitle}
      bentoOneTitle={render(blok.bentoOneTitle)}
      bentoOneBodyText={render(blok.bentoOneBodyText)}
      bentoOneCta={blok.bentoOneCta}
      bentoOneIcon={blok.bentoOneIcon}
      bentoOneImage={blok.bentoOneImage}
      bentoTwoTitle={render(blok.bentoTwoTitle)}
      bentoTwoBodyText={render(blok.bentoTwoBodyText)}
      bentoTwoIcon={blok.bentoTwoIcon}
      bentoTwoCta={blok.bentoTwoCta}
      theme={blok.theme}
    />
  );
}
