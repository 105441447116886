import ContactUsFormAction from "@/components/ContactUsHero/ContactUsFormAction";
import ContactUsHeroAction from "@/components/ContactUsHero/ContactUsHeroAction";
import ContentWrapper from "@/components/Core/ContentWrapper";
import SectionPill from "@/components/Shared/SectionPill";
import mq from "@/utils/mq";
import styled, { css } from "styled-components";

const ContactUsHeroWrapper = styled.header`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8rem 0;
  @media (max-width: 900px) {
    padding: 62px 0;
    flex-direction: column;
    gap: 3rem;
  }
  ${mq.mobile(css`
    padding: 7rem 0 3rem 0;
  `)};
`;

const TitleSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  top: 128px;
  @media (max-width: 900px) {
    position: relative;
    top: 0;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.h1`
  margin: 0;
  color: var(--PP-Text-Primary, #10181c);
  text-align: left;
  font-family: var(--font-inter);
  max-width: 460px;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  text-wrap: pretty;
  @media (max-width: 900px) {
    text-align: center;
    margin: 0;
    color: var(--PP-Text-Primary, #10181c);
    text-align: center;
    font-family: var(--font-inter);
    max-width: 548px;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    text-wrap: pretty;
  }
  ${mq.mobile(css`
    max-width: unset;
    font-size: 28px;
    line-height: 36px;
  `)};
`;

const Description = styled.h2`
  color: var(--PP-Text-Secondary, #677076);
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  max-width: 440px;
  text-wrap: pretty;
  @media (max-width: 900px) {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    max-width: 600px;
    text-wrap: pretty;
  }
  ${mq.mobile(css`
    font-size: 16px;
    line-height: 28px;
  `)};
`;

const ActionsContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export default function ContactUsHero({ pillText, title, description, actions, hubspotFormId }) {
  return (
    <ContentWrapper>
      <ContactUsHeroWrapper>
        <TitleSection>
          <SectionPill label={pillText} />
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TitleSection>
        <ActionsContainer>
          {actions?.map((action) => (
            <ContactUsHeroAction key={action.label} label={action.label} text={action.text} link={action.link} />
          ))}
          <ContactUsFormAction hubspotFormId={hubspotFormId} />
        </ActionsContainer>
      </ContactUsHeroWrapper>
    </ContentWrapper>
  );
}
