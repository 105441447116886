import styled, { css } from "styled-components";
import React from "react";

import SearchIcon from "@/assets/svg/search.svg";
import FilterIcon from "@/assets/svg/filter.svg";
import TagIcon from "@/assets/svg/tag.svg";
import mq from "@/utils/mq";
import DropdownButton from "@/components/Shared/DropdownButton";

const ResourceCentreControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 34px;

  ${mq.mobile(css`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  `)};
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const SearchWrapper = styled.div`
  display: flex;
  max-width: 320px;
  width: 100%;
  padding: 8px 11px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #dbdcdd;
  background: #fcfcfc;
  height: 38px;
  ${mq.mobile(css`
    max-width: unset;
  `)};
`;

const SearchIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  color: #717680;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  color: var(--PP-Text-Primary);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: var(--font-inter);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  border: 0px transparent;
  background: transparent;
  ::placeholder {
    color: #717680;
  }
  ${mq.mobile(css`
    font-size: 16px;
  `)};
`;

const FilterDropdownButtonTrigger = styled.div`
  border-radius: 8px;
  border: 1px solid #dbdcdd;
  background: #fcfcfc;
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

const DropdownButtonLabel = styled.div`
  color: var(--PP-Text-Primary);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: var(--font-inter);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const DropdownButtonIcon = styled.div`
  width: 14px;
  height: 14px;
`;

const TagsContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #dbdcdd;
  background: #fcfcfc;
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

interface FileType {
  id: number;
  name: string;
  value: string;
}

interface FileTypeResponse {
  datasource_entries: FileType[];
  cv: number;
}

interface ResourceCentreControlsProps {
  resourceTypes: any[];
  onFileTypeChange?: (fileTypes: string[]) => void;
  onTagChange?: (tags: string[]) => void;
  onSearchChange?: (search: string) => void;
}

export default function ResourceCentreControls({
  resourceTypes,
  onFileTypeChange,
  onTagChange,
  onSearchChange,
}: ResourceCentreControlsProps) {
  const [fileTypes, setFileTypes] = React.useState<FileType[]>([]);
  const [isLoadingFileTypes, setIsLoadingFileTypes] = React.useState(true);
  const [selectedFileTypes, setSelectedFileTypes] = React.useState<string[]>(["all"]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>(["all"]);
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedSort, setSelectedSort] = React.useState<string>("newest");
  const fileTypesAPI = `https://api.storyblok.com/v2/cdn/datasource_entries?datasource=resource-centre-filetype&token=${process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}`;

  React.useEffect(() => {
    const fetchFileTypes = async () => {
      try {
        const response = await fetch(fileTypesAPI);
        const data: FileTypeResponse = await response.json();
        setFileTypes(data.datasource_entries);
      } catch (error) {
        console.error("Error fetching file types:", error);
      } finally {
        setIsLoadingFileTypes(false);
      }
    };

    fetchFileTypes();
  }, []);

  const handleFileTypeChange = (type: string) => {
    let newTypes: string[];
    if (type === "all") {
      newTypes = ["all"];
    } else {
      const withoutAll = selectedFileTypes.filter((t) => t !== "all");
      if (withoutAll.includes(type)) {
        newTypes = withoutAll.filter((t) => t !== type);
      } else {
        newTypes = [...withoutAll, type];
      }
      if (newTypes.length === 0) newTypes = ["all"];
    }
    setSelectedFileTypes(newTypes);
    onFileTypeChange?.(newTypes);
  };

  const handleTagChange = (tag: string) => {
    let newTags: string[];
    if (tag === "all") {
      newTags = ["all"];
    } else {
      const withoutAll = selectedTags.filter((t) => t !== "all");
      if (withoutAll.includes(tag)) {
        newTags = withoutAll.filter((t) => t !== tag);
      } else {
        newTags = [...withoutAll, tag];
      }
      if (newTags.length === 0) newTags = ["all"];
    }
    setSelectedTags(newTags);
    onTagChange?.(newTags);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    onSearchChange?.(value);
  };

  const fileTypeMenuSections = [
    {
      items: [
        { id: "all", label: "All file types" },
        ...fileTypes?.map((type) => ({
          id: type.value.toLowerCase(),
          label: type.name,
          selected: selectedFileTypes.includes(type.value.toLowerCase()),
        })),
      ],
      showSeparator: false,
    },
  ];

  const tagMenuSections = [
    {
      items: [
        {
          id: "all",
          label: "All tags",
          selected: selectedTags.includes("all"),
        },
      ],
      showSeparator: true,
    },
    {
      items: [

        ...(resourceTypes
          ? resourceTypes.map((type) => ({
              id: type.slug,
              label: type.content.title,
              selected: selectedTags.includes(type.slug),
            }))
          : []),
      ],
    },
  ];

  const getFileTypeLabel = (typeIds: string[]): string => {
    if (typeIds.includes("all")) return "All file types";
    if (typeIds.length === 0) return "All file types";
    if (typeIds.length === 1) {
      const fileType = fileTypes.find((type) => type.value.toLowerCase() === typeIds[0]);
      return fileType?.name + " files" || "All file types";
    }
    return `${typeIds.length} file types selected`;
  };

  const getTagLabel = (tagIds: string[]): string => {
    if (tagIds.includes("all")) return "All tags";
    if (tagIds.length === 0) return "All tags";
    if (tagIds.length === 1) {
      const tag = resourceTypes.find((type) => type.slug === tagIds[0]);
      return tag?.content.title || "All tags";
    }
    return `${tagIds.length} tags selected`;
  };

  return (
    <ResourceCentreControlsWrapper>
      <FiltersWrapper>
        <DropdownButton
          label={getFileTypeLabel(selectedFileTypes)}
          icon={<FilterIcon />}
          sections={fileTypeMenuSections}
          onChange={handleFileTypeChange}
          disabled={isLoadingFileTypes}
          selectedIds={selectedFileTypes}
          multiSelect
        />
        <DropdownButton
          label={getTagLabel(selectedTags)}
          icon={<TagIcon />}
          sections={tagMenuSections}
          onChange={handleTagChange}
          selectedIds={selectedTags}
          multiSelect
        />
      </FiltersWrapper>
      <SearchWrapper>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <SearchInput placeholder='Search' value={searchValue} onChange={handleSearchChange} />
      </SearchWrapper>
    </ResourceCentreControlsWrapper>
  );
}
