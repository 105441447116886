import styled, { css } from "styled-components";
import { motion } from "motion/react";
import VideoPlayButton from "@/components/VideoLibrary/ui/VideoPlayButton";
import VideoMeta from "@/components/VideoLibrary/ui/VideoMeta";
import mq from "@/utils/mq";
import VideoJsonLd from "@/components/Shared/JsonLD/VideoJsonLd";
import { useRouter } from "next/router";
import { formatDuration, formatSecondsForOG } from "@/utils/formatDuration";
import { NextSeo } from "next-seo";

const VideoPreviewContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 64px;
  @media (max-width: 1100px) {
    padding-left: 0px;
  }
  ${mq.mobile(css`
    padding-left: 0;
  `)}
`;

const VideoInfoContainer = styled.div`
  max-width: 408px;
`;

const VideoPreviewContent = styled(motion.div)`
  z-index: 5;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const VideoTitle = styled(motion.h1)`
  color: #f7f8f8;
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
  margin: 0;
  margin-bottom: 16px;
  letter-spacing: -0.015em;
  ${mq.mobile(css`
    font-size: 21px;
    line-height: 28px;
    letter-spacing: -0.37px;
    margin-bottom: 8px;
  `)}
`;

const VideoDescription = styled(motion.p)`
  color: #8a8f98;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  margin: 0;
  margin-bottom: 24px;
  letter-spacing: -0.16px;
  text-wrap: balance;
  ${mq.mobile(css`
    margin-bottom: 8px;
  `)}
`;

const VideoPlayButtonWrapper = styled(motion.div)`
  ${mq.mobile(css`
    display: none;
  `)};
`;

// Animation variants
const videoItemVariants = {
  hidden: { opacity: 0, y: 10, filter: "blur(6px)" },
  visible: {
    opacity: 1,
    y: 0,
    filter: "blur(0px)",
    transition: {
      type: "spring",
      stiffness: 120,
      damping: 14,
      mass: 0.8,
    },
  },
};

export default function VideoPreview({
  contentRef,
  animationKey,
  isNavigatingAway,
  isReady,
  video,
  openPlayer,
  isPlayerOpen,
}) {
  const router = useRouter();
  return (
    <VideoPreviewContentContainer>
      <NextSeo
        title={video.title}
        description={video.description}
        openGraph={{
          title: video.title,
          description: video.description,
          images: [{ url: video.thumbnail.filename, alt: video.title }],
          type: "video.episode",
          video: {
            duration: formatSecondsForOG(video.lengthMinutes, video.lengthSeconds),
            releaseDate: video.publishDate,
          },
        }}
      />
      <VideoJsonLd
        name={video.title}
        description={video.description}
        thumbnailUrl={video.thumbnail.filename}
        uploadDate={video.publishDate}
        duration={formatDuration(video.lengthMinutes, video.lengthSeconds)}
        contentUrl={process.env.NEXT_PUBLIC_ROOT_DOMAIN + router.asPath}
      />
      <VideoInfoContainer>
        <VideoPreviewContent
          as={motion.div}
          ref={contentRef}
          key={animationKey}
          initial={{ opacity: 0, filter: "blur(6px)" }}
          animate={{
            opacity: isNavigatingAway ? 0 : isReady ? 1 : 0,
            filter: isNavigatingAway ? "blur(20px)" : isReady ? "blur(0px)" : "blur(6px)",
          }}
          transition={{ duration: 0.3, ease: "easeOut" }}>
          <VideoTitle
            as={motion.h1}
            initial={{ opacity: 0, y: 10, filter: "blur(6px)" }}
            animate={{
              opacity: isReady ? 1 : 0,
              y: isReady ? 0 : 10,
              filter: isReady ? "blur(0px)" : "blur(6px)",
            }}
            transition={{
              type: "spring",
              stiffness: 120,
              damping: 14,
              mass: 0.8,
              delay: isReady ? 0.06 : 0,
            }}>
            {video.title}
          </VideoTitle>

          <VideoDescription
            as={motion.p}
            initial={{ opacity: 0, y: 10, filter: "blur(6px)" }}
            animate={{
              opacity: isReady ? 1 : 0,
              y: isReady ? 0 : 10,
              filter: isReady ? "blur(0px)" : "blur(6px)",
            }}
            transition={{
              type: "spring",
              stiffness: 120,
              damping: 14,
              mass: 0.8,
              delay: isReady ? 0.12 : 0,
            }}>
            {video.description}
          </VideoDescription>

          <VideoPlayButtonWrapper
            initial={{ opacity: 0, y: 10, filter: "blur(6px)" }}
            animate={{
              opacity: isReady ? 1 : 0,
              y: isReady ? 0 : 10,
              filter: isReady ? "blur(0px)" : "blur(6px)",
            }}
            transition={{
              type: "spring",
              stiffness: 120,
              damping: 14,
              mass: 0.8,
              delay: isReady ? 0.18 : 0,
            }}>
            <VideoPlayButton onClick={openPlayer} />
          </VideoPlayButtonWrapper>

          <motion.div
            initial={{ opacity: 0, y: 10, filter: "blur(6px)" }}
            animate={{
              opacity: isReady ? 1 : 0,
              y: isReady ? 0 : 10,
              filter: isReady ? "blur(0px)" : "blur(6px)",
            }}
            transition={{
              type: "spring",
              stiffness: 120,
              damping: 14,
              mass: 0.8,
              delay: isReady ? 0.24 : 0,
            }}>
            <VideoMeta video={video} isReady={isReady} openPlayer={openPlayer} isPlayerOpen={isPlayerOpen} />
          </motion.div>
        </VideoPreviewContent>
      </VideoInfoContainer>
    </VideoPreviewContentContainer>
  );
}
