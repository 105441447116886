import ContentWrapper from "@/components/Core/ContentWrapper";
import SectionContentWrapper from "@/components/Shared/SectionContentWrapper";
import SectionTitle from "@/components/Shared/SectionTitle";
import TeamGridItem from "@/components/TeamGrid/TeamGridItem";
import { SPRING_CONFIG } from "@/constants";
import mq from "@/utils/mq";
import { motion } from "motion/react";
import { useState } from "react";
import styled from "styled-components";
import { css } from "styled-components";

const TeamGridItems = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 18px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 375px) {
    grid-template-columns: 1fr;
  }
`;

const Title = styled.h2`
  margin: 0;
  color: var(--PP-Text-Primary, #10181c);
  font-family: var(--font-pp-neue);
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  max-width: 536px;
  margin-top: 0;
  margin-bottom: 24px;
  ${mq.mobile(css`
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 32px;
  `)};
`;

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      duration: 0,
      delayChildren: 0.09,
      staggerChildren: 0.05,
    },
  },
  hidden: {
    opacity: 1,
    transition: {
      duration: 0,
      when: "beforeChildren",
      staggerChildren: 0.06,
    },
  },
  updating: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      duration: 0,
      delayChildren: 0,
      staggerChildren: 0.06,
    },
  },
};

const item = {
  visible: {
    opacity: [0, 1],
    y: [100, 0],
    transition: { ...SPRING_CONFIG, stiffness: 550, duration: 0.2 },
  },
  hidden: {
    opacity: 0,
    y: 100,
    transition: { ...SPRING_CONFIG, stiffness: 550, duration: 0.2 },
  },
  updating: {
    opacity: 0,
    y: -25,
    transition: { ...SPRING_CONFIG, stiffness: 550, duration: 0.2 },
  },
};

export default function TeamGrid({ topLayerTitle, topLayerIcon, teamCategories, theme, mainTitle }) {
  const [activeTab, setActiveTab] = useState(0);
  const [updatingFilter, setUpdatingFilter] = useState(false);

  const handleTabChange = (tab) => {
    setUpdatingFilter(true);
    setActiveTab(tab);
    setTimeout(() => setUpdatingFilter(false), 600);
  };

  return (
    <SectionContentWrapper
      theme={theme}
      sectionTitle={{
        title: topLayerTitle,
        icon: topLayerIcon,
        theme: theme,
        tabs: teamCategories?.map((category) => ({
          label: category.title,
        })),
        activeTab: activeTab,
        spacing: "tabMode",
        onTabChange: (tab) => handleTabChange(tab),
      }}
      tabTitleMode>
      <Title>{mainTitle}</Title>
      <TeamGridItems initial={"visible"} animate={updatingFilter ? "updating" : "visible"} variants={list}>
        {teamCategories[activeTab]?.people?.map((teamMember, index) => (
          <motion.span variants={item} initial={false} key={teamMember.uuid}>
            <TeamGridItem key={teamMember.uuid} {...teamMember} />
          </motion.span>
        ))}
      </TeamGridItems>
    </SectionContentWrapper>
  );
}
