import CaseStudiesRootHero from "@/components/CaseStudiesRootHero";
import FilteredCaseStudies from "@/components/FilteredCaseStudies";
import { useAllSectors } from "@/hooks/useAllSectors";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import mq from "@/utils/mq";
import { useRouter } from "next/router";
import React, { useEffect, useState, useCallback, useRef } from "react";
import styled, { css } from "styled-components";
import { useQueryParam, ArrayParam, withDefault } from "use-query-params";

const CaseStudiesRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mq.mobile(css`
    gap: 2rem;
  `)};
`;

const StoryblokCaseStudiesRoot = ({ blok }) => {
  const router = useRouter();
  const { data: allSectors, isLoading, error } = useAllSectors();

  // Use useQueryParam to manage the 'sectors' URL parameter
  const [sectorParams, setSectorParams] = useQueryParam("sectors", withDefault(ArrayParam, []));

  // Use state to manage active sectors (full objects)
  const [activeSectors, setActiveSectors] = useState<any[]>([]);

  // Use a ref to track if we've initialized from URL params
  const initializedRef = useRef(false);

  // Initialize activeSectors from URL params only once when allSectors are loaded
  useEffect(() => {
    if (allSectors && sectorParams.length > 0 && !initializedRef.current) {
      const initialActiveSectors = sectorParams
        .map((slug) => allSectors.find((sector) => sector.slug === slug))
        .filter(Boolean);
      setActiveSectors(initialActiveSectors);
      initializedRef.current = true;
    }
  }, [allSectors, sectorParams]);

  // Update URL params when activeSectors changes
  useEffect(() => {
    if (initializedRef.current) {
      const activeSectorSlugs = activeSectors.map((sector) => sector.slug);
      if (JSON.stringify(activeSectorSlugs) !== JSON.stringify(sectorParams)) {
        setSectorParams(activeSectorSlugs.length > 0 ? activeSectorSlugs : undefined, "replaceIn");
      }
    }
  }, [activeSectors, sectorParams, setSectorParams]);

  const handleSectorToggle = useCallback((sector: any) => {
    setActiveSectors((prev) => {
      const sectorIndex = prev.findIndex((s) => s.slug === sector.slug);
      if (sectorIndex > -1) {
        return prev.filter((_, index) => index !== sectorIndex);
      } else {
        return [...prev, sector];
      }
    });
  }, []);

  return (
    <CaseStudiesRootContainer>
      <CaseStudiesRootHero
        title={blok.heroTitle}
        description={blok.heroDescription}
        activeSectors={activeSectors}
        onSectorToggle={handleSectorToggle}
        setActiveSectors={setActiveSectors}
      />

      {activeSectors.length > 0 ? (
        <FilteredCaseStudies activeSectors={activeSectors} />
      ) : (
        <BlokRenderer blok={blok} key={blok?.slug} />
      )}
    </CaseStudiesRootContainer>
  );
};

export default StoryblokCaseStudiesRoot;
