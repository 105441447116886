import { MotionValue, motion, useSpring, useTransform } from "motion/react";
import { useEffect, useState } from "react";
import styled from "styled-components";

const fontSize = 14;
const padding = 15;
const height = fontSize + padding;

const StyledContainer = styled.div<{ $fontSize: number }>`
  display: flex;
  gap: 0px;
  overflow: hidden;
  border-radius: 0.25rem;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  padding: 0;
  user-select: none;
`;

const StyledDigitContainer = styled.div<{ $height: number }>`
  position: relative;
  width: 1ch;
  font-variant-numeric: tabular-nums;
  height: ${(props) => props.$height}px;
`;

const StyledNumberSpan = styled(motion.span)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function AnimatedCounter({ value }: { value: number }) {
  return (
    <StyledContainer $fontSize={fontSize}>
      <Digit place={10} value={value} />
      <Digit place={1} value={value} />
    </StyledContainer>
  );
}

function Digit({ place, value }: { place: number; value: number }) {
  let valueRoundedToPlace = Math.floor(value / place);
  let animatedValue = useSpring(valueRoundedToPlace, {
    stiffness: 200,
    damping: 30,
    mass: 1.5,
    duration: 0.3,
    velocity: 0.2,
  });

  useEffect(() => {
    animatedValue.set(valueRoundedToPlace);
  }, [animatedValue, valueRoundedToPlace]);

  return (
    <StyledDigitContainer $height={height}>
      {[...Array(10).keys()].map((i) => (
        <Number key={i} mv={animatedValue} number={i} />
      ))}
    </StyledDigitContainer>
  );
}

function Number({ mv, number }: { mv: MotionValue; number: number }) {
  let y = useTransform(mv, (latest) => {
    let placeValue = latest % 10;
    let offset = (10 + number - placeValue) % 10;

    let memo = offset * height;

    if (offset > 5) {
      memo -= 10 * height;
    }

    return memo;
  });

  return <StyledNumberSpan style={{ y }}>{number}</StyledNumberSpan>;
}
