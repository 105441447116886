import Breadcrumbs from "@/components/Breadcrumbs";
import mq from "@/utils/mq";
import styled from "styled-components";
import { css } from "styled-components";
import SectorsGrid from "./SectorsGrid";
import AquaBackground from "@/assets/svg/aquaBackground.svg";

const ContentContainer = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  gap: 18px;
  padding-top: 64px;
  padding-bottom: 64px;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 48px;
  margin-top: 46px;
  ${mq.mobile(css`
    padding: 1rem 0 1rem 0;
    align-items: center;
    gap: 12px;
  `)};
`;

const Title = styled.h1`
  color: var(--PP-Text-Primary, #10181c);
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  max-width: 530px;
  margin: 0;
  padding: 0;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 32px;
    line-height: 44px;
    padding: 0 32px;
  `)};
`;

const Description = styled.h2`
  color: var(--PP-Text-Secondary, #677076);
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  padding: 0;
  max-width: 740px;
  font-weight: 400;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 16px;
    line-height: 28px;
    padding: 0 32px;
  `)};
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 1;
  svg {
    width: 100%;
  }
`;

export default function SectorsHero({ title, description }) {
  return (
    <ContentContainer>
      <ContentWrapper>
        <TitleSection>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TitleSection>
        <SectorsGrid />
      </ContentWrapper>
    </ContentContainer>
  );
}
