import React from "react";
import styled, { css } from "styled-components";
import ContentWrapper from "@/components/Core/ContentWrapper";
import JobPositionItem from "./JobPositionItem";
import { render } from "storyblok-rich-text-react-renderer";
import Link from "next/link";
import mq from "@/utils/mq";

export interface JobListingLocation {
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  type: string;
  town: string;
}

export interface Salary {
  value: string;
  currency: string;
  frequency: string;
}

export enum EmploymentType {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  CONTRACTOR = "CONTRACTOR",
  TEMPORARY = "TEMPORARY",
  INTERN = "INTERN",
}

export interface JobPositionItemProps {
  title: string;
  datePosted: Date;
  description: string;
  validThrough: Date;
  employmentType?: EmploymentType;
  location?: JobListingLocation[];
  salary?: Salary[];
  workingHours?: string;
  jobDescription?: string;
  hubspotFormId?: string;
  icon?: string;
  content?: any;
}

export interface JobPositionsListProps {
  jobs: { job: JobPositionItemProps; full_slug: string }[];
}

const OuterWrapper = styled.div`
  width: 100%;
`;

const InnerWrapper = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 64px 0;
  ${mq.mobile(css`
    padding: 26px 0;
  `)};
`;

const ListContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1180px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 870px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Title = styled.h2`
  font-family: var(--font-inter);
  font-weight: 400;
  letter-spacing: -0.00666rem;
  font-size: 1rem;
  color: #52525c;
  line-height: 1.8;
  color: #09090b !important;
  letter-spacing: -0.00666rem;
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-weight: 500;
  margin: 0;
  margin-bottom: 16px;
`;

export default function JobPositionsList({ jobs }: JobPositionsListProps) {
  return (
    <ContentWrapper backgroundColor='#F9FAFB'>
      <OuterWrapper>
        <InnerWrapper>
          <ListContainer>
            {jobs?.map((jobData, index) => <JobPositionItem job={jobData.job} full_slug={jobData.full_slug} />)}
          </ListContainer>
        </InnerWrapper>
      </OuterWrapper>
    </ContentWrapper>
  );
}
