import React, { useRef } from "react";
import { render, NODE_PARAGRAPH } from "storyblok-rich-text-react-renderer";
import styled from "styled-components";
import GSAPUnderlineAnimation from "../Shared/GSAPUnderlineAnimation";

const FactoidCardContainer = styled.div`
  width: 400px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  aspect-ratio: 2/3;
  padding: 1rem;
  background-color: var(--Light300);
  border-radius: 1rem;
  margin-right: 2rem;
  &:last-child {
    margin-right: 0;
  }
`;

const Title = styled.h3``;

const Body = styled.div``;

interface FactoidCardProps {
  item: {
    title: string;
    body: any;
  };
  index: number;
  totalCards: number;
  isActive: boolean;
}

export default function FactoidCard({ item, index, totalCards, isActive }: FactoidCardProps) {
  const cardRef = useRef<HTMLDivElement>(null);

  const wrapUnderlineElements = (content: React.ReactNode): React.ReactNode => {
    if (typeof content === "string") {
      return content;
    }

    if (Array.isArray(content)) {
      return content?.map((child, index) => wrapUnderlineElements(child));
    }

    if (React.isValidElement(content)) {
      const props = content.props as { className?: string; children?: React.ReactNode };

      if (props.className && props.className.includes("underline")) {
        return (
          <span key={content.key} style={{ position: "relative", display: "inline-block" }}>
            {content}
            <GSAPUnderlineAnimation isActive={isActive} />
          </span>
        );
      }

      if (props.children) {
        const newChildren = wrapUnderlineElements(props.children);
        return React.cloneElement<any>(content, { children: newChildren });
      }
    }

    return content;
  };

  const renderedBody = render(item.body, {
    nodeResolvers: {
      [NODE_PARAGRAPH]: (children) => <p>{wrapUnderlineElements(children)}</p>,
    },
  });

  return (
    <FactoidCardContainer ref={cardRef}>
      <Title>{item.title}</Title>
      <Body>{renderedBody}</Body>
    </FactoidCardContainer>
  );
}
