import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "motion/react";
import mq from "@/utils/mq";
import ContentWrapper from "@/components/Core/ContentWrapper";
import { useAllSbSectors } from "@/hooks/useAllSbSectors";
import IconRenderer from "@/components/Shared/IconRenderer";
import NextLazyImage from "@/components/Core/NextLazyImage";
import Button from "@/components/Buttons/Button";
import Link from "next/link";
import SectorCard from "@/components/SectorsHero/SectorCard";
import LoadingSectorCard from "@/components/SectorsHero/LoadingSectorCard";
import { css } from "styled-components";

const OuterGridContainer = styled(motion.div)`
  position: relative;
  overflow: hidden;
`;

const GridContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 14px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 6rem;
  ${mq.mobile(css`
    padding-bottom: 0;
  `)};
`;

const GridItem = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 0px 28px 13px rgba(0, 0, 0, 0.06) inset;
  border-radius: 8px;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    background: linear-gradient(0deg, rgba(74, 123, 85, 0.3) 0%, rgba(74, 123, 85, 0) 62.53%),
      linear-gradient(180deg, rgba(74, 123, 85, 0.3) 0%, rgba(74, 123, 85, 0) 20.29%),
      linear-gradient(0deg, rgba(15, 16, 15, 0.65) 0%, rgba(15, 16, 15, 0.65) 100%);
  }
`;

const IconContainer = styled.img`
  position: relative;
  z-index: 2;
`;

const Title = styled.h3`
  color: var(--PP-Text-Primary, #10181c);
  margin-top: 8px;
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(1px);
`;

const IconWrapper = styled.div`
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToggleButton = styled.button`
  position: absolute;
  bottom: 16px; /* Always position towards the bottom */
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
`;

const GradientOverlay = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(182deg, rgba(249, 250, 251, 0) 15.37%, #f9fafb 94.17%);
  z-index: 5;
`;

const OverlayContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  user-select: none;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  user-select: none;
  pointer-events: auto;
`;

export default function SectorsGrid() {
  const { data: sectors, isLoading, error } = useAllSbSectors();
  const [expanded, setExpanded] = useState(false);

  return (
    <ContentWrapper backgroundColor='transparent'>
      <GridContainer>
        {!isLoading && sectors && sectors?.map((sector) => <SectorCard sector={sector} key={sector.uuid} />)}

        {(isLoading || !sectors) && Array.from({ length: 18 })?.map((_, index) => <LoadingSectorCard key={index} />)}
        {/*<OverlayContainer>
            <GradientOverlay initial={{ opacity: 1 }} animate={{ opacity: expanded ? 0 : 1 }} />
            <ButtonContainer>
              <Button
                action={() => setExpanded(!expanded)}
                label={expanded ? "Show less" : "Show more"}
                style='secondary'
              />
            </ButtonContainer>
          </OverlayContainer>*/}
      </GridContainer>
    </ContentWrapper>
  );
}
