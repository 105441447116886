import BlogCollectionPreviewCard from "@/components/BlogRootRework/BlogCollectionPreview/BlogCollectionPreviewCard";
import useEmblaCarousel, { type UseEmblaCarouselType } from "embla-carousel-react";
import React, { useCallback, useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import CarouselArrow from "@/assets/svg/carouselArrow.svg";
import { motion } from "motion/react";
import AnimatedCounter from "@/components/Shared/AnimatedCounter";
import { EmblaCarouselType, EmblaEventType } from "embla-carousel";
import mq from "@/utils/mq";

export type CarouselApi = UseEmblaCarouselType[1];

const EmblaCarousel = styled.div`
  overflow: hidden;
  position: relative;
  ${mq.mobile(css`
    display: flex;
    flex-direction: column-reverse;
  `)};
`;

const EmblaContainer = styled.div`
  display: flex;

  margin-left: 24px;
  //margin-right: 32px;
  margin-bottom: 32px;
  ${mq.mobile(css`
    margin-left: 8px;
    margin-bottom: 16px;
  `)};
`;

const EmblaSlide = styled.div`
  flex: 0 0 100%;
  height: 400px;
  max-width: 300px;
  margin-right: 24px;
  ${mq.mobile(css`
    margin-right: 8px;
    max-width: 60vw;
    height: unset;
    aspect-ratio: 1/1.3;
  `)};
`;

const ProgrssButtonOuter = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  inset: 0;
  user-select: none;
  pointer-events: none;
`;

const ProgressButtonWrapper = styled.div<{ $visible: boolean }>`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #d2d2d7a3;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  pointer-events: auto;
  &.embla__prev {
    transform: rotate(180deg);
  }
  &.embla__next {
  }
  svg {
    opacity: 0.5;
    width: 44px;
    height: 44px;
  }
  ${(props) =>
    !props.$visible &&
    css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
      transition: all 0.3s ease-in-out;
    `}
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 32px;
  width: 100%;
  padding-right: 36px;
  gap: 16px;
  ${mq.mobile(css`
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 16px;
  `)};
`;

const ProgressContainer = styled.div`
  max-width: 300px;
  width: 100%;
  height: 2px;
  background-color: #d6d8dc;
`;

const ProgressBar = styled(motion.div)`
  height: 2px;
  background-color: #10181c;
`;

const ControlButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
`;

const ControlButton = styled.div<{ $flipped?: boolean }>`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #677076;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: ${({ $flipped }) => ($flipped ? "rotate(180deg)" : "rotate(0deg)")};
  &:hover {
    color: #10181c;
    transition: all 0.3s ease-in-out;
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;

const SlideIndicatorLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #677076;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  & span {
    color: #10181c;
  }
`;

const TWEEN_FACTOR_BASE = 0.1;

export default function BlogCollectionPreviewCarousel({ stories }) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: "start", skipSnaps: true, duration: 20 });
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const tweenFactor = useRef(0);
  const tweenNodes = useRef<HTMLElement[]>([]);

  useEffect(() => {
    if (emblaApi) {
      //console.log(emblaApi.slideNodes()); // Access API
    }
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(
    (api: CarouselApi) => {
      if (!api) {
        return;
      }
      setActiveSlide(api.selectedScrollSnap());
      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());

      // Calculate completed percentage
      const totalSlides = stories.length;
      if (totalSlides <= 1) {
        setCompletedPercentage(100);
        return;
      }
      const percentage = Math.round((api.selectedScrollSnap() / (totalSlides - 1)) * 100);
      setCompletedPercentage(percentage);
    },
    [stories],
  ); // Add stories to dependency array

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);

    return () => {
      emblaApi?.off("select", onSelect);
    };
  }, [emblaApi, onSelect]);

  const setTweenNodes = useCallback((emblaApi: EmblaCarouselType) => {
    tweenNodes.current = emblaApi.slideNodes()?.map((slideNode) => {
      return slideNode.querySelector(".parallax-layer") as HTMLElement;
    });
  }, []);

  const setTweenFactor = useCallback((emblaApi: EmblaCarouselType) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length;
  }, []);

  const tweenParallax = useCallback((emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();
    const slidesInView = emblaApi.slidesInView();
    const isScrollEvent = eventName === "scroll";

    emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress;
      const slidesInSnap = engine.slideRegistry[snapIndex];

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target();
            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target);
              if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
              if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
            }
          });
        }

        const translate = diffToTarget * (-1 * tweenFactor.current) * 100;
        const tweenNode = tweenNodes.current[slideIndex];
        if (tweenNode) {
          tweenNode.style.transform = `translateX(${translate}%)`;
        }
      });
    });
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    setTweenNodes(emblaApi);
    setTweenFactor(emblaApi);
    tweenParallax(emblaApi);

    emblaApi
      .on("reInit", setTweenNodes)
      .on("reInit", setTweenFactor)
      .on("reInit", tweenParallax)
      .on("scroll", tweenParallax)
      .on("slideFocus", tweenParallax);

    return () => {
      emblaApi
        ?.off("reInit", setTweenNodes)
        ?.off("reInit", setTweenFactor)
        ?.off("reInit", tweenParallax)
        ?.off("scroll", tweenParallax)
        ?.off("slideFocus", tweenParallax);
    };
  }, [emblaApi, tweenParallax, setTweenNodes, setTweenFactor]);

  return (
    <EmblaCarousel ref={emblaRef} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <EmblaContainer>
        {stories &&
          stories?.map((story, index) => (
            <EmblaSlide>
              <BlogCollectionPreviewCard key={index} story={story} />
            </EmblaSlide>
          ))}
      </EmblaContainer>
      <ControlsContainer>
        <ProgressContainer>
          <ProgressBar
            initial={{ width: "0%" }}
            animate={{ width: `${completedPercentage}%` }}
            transition={{ duration: 0.5, ease: [0.77, 0, 0.175, 1] }}
          />
        </ProgressContainer>
        <SlideIndicatorLabel>
          <AnimatedCounter value={activeSlide + 1} /> /{stories?.length}
        </SlideIndicatorLabel>
        <ControlButtons>
          <ControlButton $flipped={true} className='embla__prev' onClick={scrollPrev}>
            <CarouselArrow />
          </ControlButton>
          <ControlButton className='embla__next' onClick={scrollNext}>
            <CarouselArrow />
          </ControlButton>
        </ControlButtons>
      </ControlsContainer>
    </EmblaCarousel>
  );
}

const ProgressButton = ({ action, className, visible }: { action: any; className: any; visible: boolean }) => {
  return (
    <ProgressButtonWrapper className={className} onClick={action} $visible={visible}>
      <CarouselArrow />
    </ProgressButtonWrapper>
  );
};
