import styled from "styled-components";

import NextLazyImage from "@/components/Core/NextLazyImage";
import CtaRenderer from "@/components/Shared/CtaRenderer";
import ArrowToTopRight from "@/assets/svg/arrowToTopRight.svg";
import { css } from "styled-components";
import mq from "@/utils/mq";
import IconRenderer from "@/components/Shared/IconRenderer";

const BentoOneContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px;
  border-radius: 8px;
  border: 1px solid #dddfe1;
  @media (max-width: 1200px) {
    max-height: 475px;
  }
  ${mq.mobile(css`
    flex-direction: column;
    gap: 8px;
    padding: 4px;
  `)};
`;

const TopLayer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: space-between;
  flex-direction: column;
  height: 100%;
  ${mq.mobile(css`
    height: unset;
  `)};
`;

const ContentContainer = styled.div`
  padding: 28px;
  padding-right: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 360px;
  @media (max-width: 1200px) {
    padding: 20px;
    padding-right: 40px;
    min-width: 260px;
  }
  ${mq.mobile(css`
    height: unset;
    gap: 16px;
    padding: 12px 12px 16px 12px;
  `)};
`;

const Decorator = styled.div<{ $customIcon: boolean }>`
  color: #fff;
  border-radius: 60px;
  background: var(--PrimaryAccent);
  display: flex;
  width: 42px;
  min-width: 42px;
  min-height: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 22px;
  svg {
    width: ${({ $customIcon }) => ($customIcon ? "24px" : "15px")};
    height: ${({ $customIcon }) => ($customIcon ? "24px" : "15px")};
  }
  ${mq.mobile(css<{ $customIcon: boolean }>`
    --size: 32px;
    min-width: var(--size);
    min-height: var(--size);
    height: var(--size);
    width: var(--size);
    margin-bottom: 16px;
    svg {
      width: 11px;
      height: 11px;
      width: ${({ $customIcon }) => ($customIcon ? "18px" : "11px")};
      height: ${({ $customIcon }) => ($customIcon ? "18px" : "11px")};
    }
  `)};
`;

const Title = styled.div`
  color: var(--PP-Text-Primary);
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 16px;
  p {
    margin: 0;
  }
  @media (max-width: 1200px) {
    font-size: 30px;
    line-height: 34px;
  }
  ${mq.mobile(css`
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 8px;
  `)};
`;

const Description = styled.div`
  color: #677076;
  font-size: 16px;
  line-height: 24px;
  max-width: 90%;
  letter-spacing: 0.4px;
  max-width: 95%;
  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Cta = styled.div``;

const ImageContainer = styled.div`
  min-width: 366px;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  @media (max-width: 1200px) {
    max-height: 100%;
    overflow: hidden;
    min-width: 300px;
  }
  ${mq.mobile(css`
    min-width: 100%;
    border-radius: 6px;
  `)};
`;

export default function BentoOne({ title, description, icon, cta, image }) {
  return (
    <BentoOneContainer>
      <ContentContainer>
        <TopLayer>
          <Decorator $customIcon={icon && icon.length > 0}>
            {icon && icon.length > 0 ? <IconRenderer icon={icon} color={"#fff"} /> : <ArrowToTopRight />}
          </Decorator>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TopLayer>

        <CtaRenderer ctas={cta} />
      </ContentContainer>
      <ImageContainer>
        <NextLazyImage src={image.filename} alt={image.alt} fill />
      </ImageContainer>
    </BentoOneContainer>
  );
}
