import styled from "styled-components";

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e3e2e153;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  z-index: 10;
`;

const ProgressBarInner = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #e3e2e1;
  border-radius: 2px;
  z-index: 11;
`;

const ProgressBar = ({ completedPercent = 0 }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarInner style={{ width: `${completedPercent}%` }} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
