import BlogPostCollectionMoreItems from "@/components/Blog/BlogPostCollectionMoreItems";
import BlogContentWrapper from "@/components/BlogRework/BlogContentWrapper";
import BlogKeyTakeAways from "@/components/BlogRework/BlogKeyTakeAways";
import BlogPostHero from "@/components/BlogRework/BlogPostHero";
import BlogPostTableOfContents from "@/components/BlogRework/BlogPostTableOfContents";
import BreadcrumbJsonLd from "@/components/Shared/JsonLD/BreadcrumbJsonLd";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import { SbImage } from "@/lib/storyblok/storyblok";
import mq from "@/utils/mq";
import { NextSeo } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";
import styled, { css } from "styled-components";

const BlogPostContainer = styled.div`
  --blog-max-width: 720px;
`;

const BlogPostContent = styled.div`
  padding: 62px 0;
  max-width: 720px;
  margin: 0 auto;
  ${mq.mobile(css`
    padding: 2rem 0;
  `)};
`;

const BlogPostContentGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8rem;
  flex-direction: row;
  @media (max-width: 1000px) {
    gap: 2rem;
  }
  ${mq.mobile(css`
    flex-direction: column;
  `)};
`;

const BlogPostTableOfContentsWrapper = styled.div`
  z-index: 99;
  position: initial;
  padding-right: 0px;
  padding-left: 4rem;
  width: 30%;
  ${mq.mobile(css`
    width: 100%;
    padding-left: 0;
    display: none;
  `)};
`;

const BlogPostContentWrapper = styled.div`
  width: 56%;
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

interface StoryblokAuthor {
  author: {
    name: string;
    content: {
      name: string;
      role: string;
      bio: string;
      avatar: SbImage;
      awards?: {
        value: string[];
        plugin: string;
      };
      sameAs?: Array<{
        _uid: string;
        link: string;
        component: string;
        _editable?: string;
      }>;
      alumniOf?: Array<{
        url: string;
        _uid: string;
        name: string;
        type: string;
        component: string;
        _editable?: string;
      }>;
      knowsAbout?: {
        value: string[];
        plugin: string;
      };
      credentials?: Array<{
        _uid: string;
        type: string;
        component: string;
        recognizedBy: Array<{
          url: string;
          _uid: string;
          name: string;
          component: string;
          _editable?: string;
        }>;
        credentialCategory: string;
        _editable?: string;
      }>;
    };
  };
}

const BlogJsonLd = ({ data, fullUrl, baseUrl }: { data: any; blok: any; fullUrl: string; baseUrl: string }) => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: data.content.ogTitle,
    url: fullUrl,
    datePublished: data.publishDate,
    dateCreated: data.publishDate,
    dateModified: data.modifiedDate,
    inLanguage: "en-GB",
    isFamilyFriendly: true,
    copyrightYear: new Date().getFullYear().toString(),
    copyrightHolder: "Plant Plan",
    mainEntityOfPage: true,
    description: data.content.ogDescription,
    articleBody: data.content.articleBody,
    isAccessibleForFree: true,
    potentialAction: [
      {
        "@type": "ReadAction",
        target: [fullUrl],
      },
    ],
    author: [
      ...data.authors?.map((author: StoryblokAuthor) => {
        // Create base person object with required name
        const personObject: Record<string, any> = {
          "@type": "Person",
          name: author?.author?.name || "",
        };

        // Add description/bio if available
        if (author?.author?.content?.bio) {
          personObject.description = author.author.content.bio;
        }

        // Add job title/role if available
        if (author?.author?.content?.role) {
          personObject.jobTitle = author.author.content.role;
        }

        // Add image if available
        if (author?.author?.content?.avatar?.filename) {
          personObject.image = author.author.content.avatar.filename;
        }

        // Add sameAs links (social profiles) if available
        if (author?.author?.content?.sameAs && author.author.content.sameAs.length > 0) {
          personObject.sameAs = author.author.content.sameAs.filter((item) => item.link)?.map((item) => item.link);
        }

        // Add alumniOf (education) if available
        if (author?.author?.content?.alumniOf && author.author.content.alumniOf.length > 0) {
          personObject.alumniOf = author.author.content.alumniOf
            .filter((item) => item.name && item.url)
            .map((item) => ({
              "@type": item.type || "CollegeOrUniversity",
              name: item.name,
              url: item.url,
            }));
        }

        // Add knowsAbout (areas of expertise) if available
        if (author?.author?.content?.knowsAbout?.value && author.author.content.knowsAbout.value.length > 0) {
          personObject.knowsAbout = author.author.content.knowsAbout.value;
        }

        // Add awards if available
        if (author?.author?.content?.awards?.value && author.author.content.awards.value.length > 0) {
          personObject.award = author.author.content.awards.value;
        }

        // Add credentials if available
        if (author?.author?.content?.credentials && author.author.content.credentials.length > 0) {
          personObject.hasCredential = author.author.content.credentials
            .filter((credential) => credential.credentialCategory)
            .map((credential) => {
              const credentialObject: Record<string, any> = {
                "@type": credential.type || "EducationalOccupationalCredential",
                credentialCategory: credential.credentialCategory,
              };

              // Add recognizedBy if available
              if (credential.recognizedBy && credential.recognizedBy.length > 0) {
                credentialObject.recognizedBy = credential.recognizedBy
                  .filter((org) => org.name && org.url)
                  .map((org) => ({
                    "@type": "Organization",
                    name: org.name,
                    url: org.url,
                  }));
              }

              return credentialObject;
            });
        }

        return personObject;
      }),
    ],
    publisher: {
      "@type": "Organization",
      name: "Plant Plan",
      url: baseUrl,
    },
    keywords: [
      ...(data?.keywords?.value || []).map((keyword: any) => keyword),
      "Sustainability",
      "Gardening",
      "Interior Design",
      "Office Decor",
      "Plant Care",
    ],
    genre: ["Sustainability", "Gardening", "Interior Design", "Office Decor", "Plant Care"],
  };

  return (
    <Head>
      <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
    </Head>
  );
};

const StoryblokBlogPost = ({ blok }: { blok: any }) => {
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_ROOT_DOMAIN || "https://www.plantplan.co.uk/";
  const fullUrl = `${baseUrl}${router.asPath}`;
  return (
    <BlogContentWrapper>
      <Head>
        <meta property='og:type' content='article' />
      </Head>
      <NextSeo
        title={blok.ogTitle || blok.title}
        description={blok.ogDescription}
        openGraph={{
          type: "article",
          url: fullUrl,
          title: blok.ogTitle || blok.title,
          description: blok.ogDescription,
          article: {
            publishedTime: blok.publishDate,
            authors: blok.authors?.map((author: StoryblokAuthor) => author?.author?.name) || [],
            section: blok.category || "Blog",
          },
          images: blok.ogImage
            ? [
                {
                  url: blok.ogImage.filename,
                  alt: blok.title,
                },
              ]
            : [],
        }}
        additionalMetaTags={[
          {
            name: "keywords",
            content: blok.keywords?.value?.join(", ") || "",
          },
        ]}
        canonical={fullUrl}
      />
      <BlogJsonLd data={blok} blok={blok} fullUrl={fullUrl} baseUrl={baseUrl} />
      <BreadcrumbJsonLd
        baseUrl={baseUrl}
        items={[
          { name: "Home", item: baseUrl },
          { name: "Blog", item: `${baseUrl}/blog` },
          { name: blok.title, item: fullUrl },
        ]}
      />

      <BlogPostHero
        title={blok.title}
        description={blok.description}
        category={blok.category}
        tags={blok.tags}
        publishDate={blok.publishDate}
        authors={blok.authors}
        leadingImage={blok.leadingImage}
        keyTakeAways={blok.keyTakeAways}
        primaryCollection={blok.primaryCollection}
        advertiseAsPartOfCollection={blok.advertiseAsPartOfCollection}
      />

      {blok.useAdvancedBlog && blok.advancedBlog && blok.advancedBlog?.length > 0 ? (
        <BlogPostContentGrid>
          <BlogPostTableOfContentsWrapper>
            {blok.enableTableOfContents && (
              <BlogPostTableOfContents
                content={blok.advancedBlog.map((item: any) => {
                  return {
                    title: item.title,
                    _uid: item._uid,
                    hideFromTableOfContents: item.hideFromTableOfContents,
                    customLabel: item.customLabel,
                  };
                })}
              />
            )}
          </BlogPostTableOfContentsWrapper>
          <BlogPostContentWrapper>
            {blok.keyTakeAways && blok.keyTakeAways.length > 0 && (
              <BlogKeyTakeAways title={blok.keyTakeAways[0].title} items={blok.keyTakeAways[0].items} />
            )}
            <BlokRenderer blok={{ body: blok.advancedBlog }} key={blok?._uid} noEmptyAlert={true} />
          </BlogPostContentWrapper>
        </BlogPostContentGrid>
      ) : (
        <BlogPostContent>
          <BlokRenderer blok={{ body: blok.content }} key={blok?._uid} />
        </BlogPostContent>
      )}
      {blok.appendedContent && blok.appendedContent.length > 0 && (
        <BlokRenderer blok={{ body: blok.appendedContent }} key={blok?._uid} />
      )}
      {blok.displayAdditionalCollectionContent && blok.primaryCollection && blok.primaryCollection && (
        <BlogPostCollectionMoreItems collection={blok.primaryCollection} />
      )}
    </BlogContentWrapper>
  );
};

export default StoryblokBlogPost;
