import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { usePathname } from "next/navigation";
import { useVideoPreloading } from "../hooks/useVideoPreloading";
import { useVideoNavigation } from "../hooks/useVideoNavigation";
import { Video } from "../types";

interface VideoPreviewBackgroundProps {
  video: Video;
  autoPlay?: boolean;
  playing?: boolean;
  blurAmount?: number;
}

const BackgroundVideoContainer = styled.div`
  width: 100%;
  height: 100lvh;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  mask-image: linear-gradient(
    to bottom,
    #000 calc(100% - 160px),
    rgba(0, 0, 0, 0.2) calc(100% - (160px / 4)),
    transparent 100%
  );
  * {
    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
`;

const CoverLeft = styled.div`
  position: absolute;
  inset: 0 auto 0 0;
  width: 50%;
  background: linear-gradient(to right, #08090a, transparent);
  z-index: 2;
`;

const CoverRight = styled.div`
  position: absolute;
  inset: 0 0 0 auto;
  width: 50%;
  background: linear-gradient(to left, #08090a, transparent);
  z-index: 2;
`;

const VideoWrapper = styled.div<{ $active: boolean; $opacity: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => props.$opacity};
  transition: opacity 0.8s ease-in-out;
  z-index: ${(props) => (props.$active ? 1 : 0)};
`;

const VideoBackground = styled.video<{ $blurAmount: number }>`
  object-fit: cover;
  height: 100%;
  width: 100%;
  filter: blur(${(props) => props.$blurAmount}px) brightness(0.7);
  transition: filter 0.5s ease-in-out;
  object-position: top center;
`;

/**
 * Video background component that handles preloading and transitions
 */
export default function VideoPreviewBackground({
  video,
  autoPlay = true,
  playing = true,
  blurAmount = 6,
}: VideoPreviewBackgroundProps) {
  const pathname = usePathname();
  const { isNavigating, registerNavigationCallback } = useVideoNavigation();
  const { preloadVideoAssets } = useVideoPreloading();

  const [currentBlur, setCurrentBlur] = useState(blurAmount);
  const [currentOpacity, setCurrentOpacity] = useState(1);
  const videoRef = useRef<HTMLVideoElement>(null);
  const isInitialRender = useRef(true);

  // Preload current video assets on mount
  useEffect(() => {
    if (video?.preview?.filename && video?.poster?.filename) {
      preloadVideoAssets(getCorsSafeUrl(video.preview.filename), getCorsSafeUrl(video.poster.filename)).catch((error) =>
        console.error("Failed to preload video assets:", error),
      );
    }
  }, [video?.preview?.filename, video?.poster?.filename, preloadVideoAssets]);

  // Register navigation callback
  useEffect(() => {
    const unregister = registerNavigationCallback((targetPath) => {
      if (targetPath !== pathname) {
        // Apply blur effect when navigating away
        setCurrentBlur(40);
      }
    });

    return unregister;
  }, [pathname, registerNavigationCallback]);

  // Handle initial render and navigation state changes
  useEffect(() => {
    // On initial render
    if (isInitialRender.current) {
      isInitialRender.current = false;

      // If we're coming from a navigation, start with blur
      if (isNavigating) {
        setCurrentBlur(40);
      }
    }

    // Track navigation state changes
    if (isNavigating) {
      // When navigation starts, apply blur
      setCurrentBlur(40);
    } else {
      // When navigation completes, reset blur with a delay
      const timer = setTimeout(() => {
        setCurrentBlur(blurAmount);
      }, 50);

      return () => clearTimeout(timer);
    }
  }, [isNavigating, blurAmount]);

  // Handle play/pause
  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const playVideo = async () => {
      if (playing && videoElement.paused) {
        try {
          await videoElement.play();
        } catch (err) {
          console.error("Error playing video:", err);
        }
      } else if (!playing && !videoElement.paused) {
        videoElement.pause();
      }
    };

    if (videoElement.readyState >= 2) {
      playVideo();
    } else {
      const handleCanPlay = () => {
        playVideo();
        videoElement.removeEventListener("canplay", handleCanPlay);
      };
      videoElement.addEventListener("canplay", handleCanPlay);

      return () => {
        videoElement.removeEventListener("canplay", handleCanPlay);
      };
    }
  }, [playing]);

  return (
    <BackgroundVideoContainer>
      <CoverLeft />
      <CoverRight />

      {/* Video element */}
      <VideoWrapper $active={true} $opacity={currentOpacity}>
        <VideoBackground
          ref={videoRef}
          autoPlay={autoPlay}
          muted={true}
          loop={true}
          playsInline={true}
          src={getCorsSafeUrl(video.preview.filename)}
          poster={getCorsSafeUrl(video.poster.filename)}
          $blurAmount={currentBlur}
          aria-busy='false'
          style={{ background: "#090B0B" }}
        />
      </VideoWrapper>
    </BackgroundVideoContainer>
  );
}

// Utility function to handle Storyblok asset URLs
export function getCorsSafeUrl(asset: any): string {
  if (!asset) return "";

  // Handle Storyblok asset objects (which have a filename property)
  if (typeof asset === "object" && asset.filename) {
    const url = asset.filename;
    if (url.includes("https://a.storyblok.com")) {
      return url.replace("https://a.storyblok.com", "https://s3.amazonaws.com/a.storyblok.com");
    }
    return url;
  }

  // Handle string URLs directly
  if (typeof asset === "string" && asset.includes("https://a.storyblok.com")) {
    return asset.replace("https://a.storyblok.com", "https://s3.amazonaws.com/a.storyblok.com");
  }

  // Return the original value if it's not a Storyblok asset URL
  return asset;
}
