import HubspotEmbeddedForm from "@/components/HubspotEmbeddedForm";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";
import { Element } from "react-scroll";
import BlogRichText from "@/components/Blog/BlogRichText/BlogRichText";
import mq from "@/utils/mq";

const JobListingBodyContainer = styled.div`
  width: 100%;
`;

const JobListingBodyOverview = styled.div`
  width: 100%;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 130%;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;
  ${mq.mobile(css`
    gap: 16px;
  `)};
`;

const JobListingBodyContent = styled.div`
  line-height: 180%;
  min-height: 45rem;
  display: flex;
  flex-direction: column;
  gap: 46px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const FormWrapper = styled.div`
  margin-top: 0.65rem;
`;

export default function JobListingBody({ title, description, hubspotFormEmbed, content }) {
  return (
    <JobListingBodyContainer>
      <JobListingBodyContent>
        <SectionWrapper>
          {content &&
            content.length > 0 &&
            content?.map((item) => (
              <Element name={item._uid} className='element'>
                <JobListingBodyOverview id={item._uid}>
                  <SectionTitle>{item.title}</SectionTitle>
                  <BlogRichText content={item.body} />
                </JobListingBodyOverview>
              </Element>
            ))}
        </SectionWrapper>

        <SectionWrapper>
          <Element name={"apply"} id={"apply"} className='element'>
            <SectionTitle>Apply for this job</SectionTitle>
            <FormWrapper>
              <HubspotEmbeddedForm hubspotFormID={hubspotFormEmbed} />
            </FormWrapper>
          </Element>
        </SectionWrapper>
      </JobListingBodyContent>
    </JobListingBodyContainer>
  );
}
