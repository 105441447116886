import Head from "next/head";
import React from "react";
import { renderToString } from "react-dom/server";
import { render } from "storyblok-rich-text-react-renderer";

interface JobLocation {
  type?: string;
  town?: string;
  addressLineOne?: string;
  postCode?: string;
  country?: string;
}

interface JobSalary {
  type?: string;
  minValue?: string;
  maxValue?: string;
}

interface JobListingJsonLdProps {
  job: {
    title: string;
    description: string;
    datePosted: string;
    validThrough: string;
    employmentType?: string;
    location?: JobLocation[];
    salary?: JobSalary[];
    workingHours?: string;
    jobDescription?: any;
  };
  baseUrl: string;
  fullUrl: string;
}

const JobListingJsonLd: React.FC<JobListingJsonLdProps> = ({ job, baseUrl, fullUrl }) => {
  const getEmploymentType = (type?: string) => {
    if (!type) return undefined;
    return `http://schema.org/${type.charAt(0).toUpperCase() + type.slice(1).replace("_", "")}`;
  };

  const getLocationType = (type?: string) => {
    switch (type) {
      case "TELECOMMUTE":
        return "TELECOMMUTE";
      case "HYBRID":
        return "HYBRID";
      default:
        return "ONSITE";
    }
  };

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    title: job.title,
    description: renderToString(render(job.description)),
    datePosted: job.datePosted,
    validThrough: job.validThrough,
    employmentType: getEmploymentType(job.employmentType),
    jobLocationType: getLocationType(job.location?.[0]?.type),
    applicantLocationRequirements: job.location?.[0]?.town
      ? { "@type": "City", name: job.location[0].town }
      : undefined,
    salaryCurrency: "GBP",
    baseSalary: job.salary?.[0]
      ? {
          "@type": "MonetaryAmount",
          value: {
            "@type": "QuantitativeValue",
            minValue: parseFloat(job.salary[0].minValue),
            maxValue: parseFloat(job.salary[0].maxValue),
            unitText: job.salary[0].type?.toUpperCase(),
          },
        }
      : undefined,
    hiringOrganization: {
      "@type": "Organization",
      name: "Plant Plan",
      url: baseUrl,
      logo: `${baseUrl}/logo.png`,
    },
    jobLocation: job.location?.[0]
      ? {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            streetAddress: job.location[0].addressLineOne,
            addressLocality: job.location[0].town,
            postalCode: job.location[0].postCode,
            addressCountry: job.location[0].country,
          },
        }
      : undefined,
    responsibilities: renderToString(render(job.jobDescription)),
    workHours: job.workingHours,
  };

  return (
    <Head>
      <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
    </Head>
  );
};

export default JobListingJsonLd;
