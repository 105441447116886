import LogoMarquee from "./LogoMarquee";

const LogoMarqueeWithProps = ({ blok }) => {
  return (
    <LogoMarquee
      logos={blok.logos}
      theme={blok.theme}
      speed={blok.speed}
      enableGradientFade={blok.enableGradientFade}
      autoFill={blok.autoFill}
      direction={blok.direction}
      delay={blok.delay}
      pauseOnHover={blok.pauseOnHover}
      pauseOnClick={blok.pauseOnClick}
      gradientFadeWidth={blok.gradientFadeWidth}
    />
  );
};

export default LogoMarqueeWithProps;
