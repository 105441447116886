import mq from "@/utils/mq";
import styled, { css } from "styled-components";

const BlogContentWrapperContainer = styled.div`
  width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 92.5rem;
  padding-inline: 1rem 0;
  ${mq.mobile(css`
    padding-inline: 20px;
  `)};
`;

export default function BlogContentWrapper({ children }: { children: React.ReactNode }) {
  return <BlogContentWrapperContainer>{children}</BlogContentWrapperContainer>;
}
