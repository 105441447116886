import NextLazyImage from "@/components/Core/NextLazyImage";
import mq from "@/utils/mq";
import Link from "next/link";
import styled, { css } from "styled-components";

const AvatarsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  ${mq.mobile(css`
    flex-wrap: wrap;
  `)};
`;

const Avatars = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Avatar = styled.div`
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 1px solid #fff;
  overflow: hidden;
`;

const AuthorNames = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-size: 1em;
  line-height: 1.45;
  color: var(--PP-Text-Secondary);
  text-decoration-line: none;
  ${mq.mobile(css`
    flex-wrap: wrap;
  `)};
`;

const AuthorLink = styled(Link)`
  color: var(--PP-Text-Primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  text-decoration-line: underline;
`;

const AuthorName = styled.div`
  font-size: 1em;
  line-height: 1.45;
  color: var(--PP-Text-Secondary);
  text-decoration-line: none;
  white-space: nowrap;
`;

export default function BlogPostAuthors({ authors }) {
  return (
    <AvatarsContainer>
      <AuthorAvatarProvider authors={authors} />
      <AuthorNamesProvider authors={authors} />
    </AvatarsContainer>
  );
}

const calcAppend = (i, length) => {
  if (i === length - 1) {
    return "";
  }
  if (i !== length - 1) {
    if (i === length - 2) {
      return " & ";
    } else {
      return ", ";
    }
  }
};

const AuthorNamesProvider = ({ authors }) => {
  return (
    <AuthorNames>
      {"By "}
      {authors?.map((author, i) => {
        if (author.component === "blogAuthor") {
          return <StoryblokAuthorName key={i} author={author.author} append={calcAppend(i, authors.length)} />;
        }
        if (author.component === "customAuthor") {
          return <CustomAuthorName key={i} author={author} append={calcAppend(i, authors.length)} />;
        }
      })}
    </AuthorNames>
  );
};

const StoryblokAuthorName = ({ author, append }) => {
  return (
    <AuthorName>
      {author?.content?.name}
      {append}
    </AuthorName>
  );
};

const CustomAuthorName = ({ author, append }) => {
  return (
    <AuthorName>
      {author?.name}
      {append}
    </AuthorName>
  );
};

const AuthorAvatarProvider = ({ authors }) => {
  return (
    <Avatars>
      {authors?.map((author, i) => {
        if (author.component === "blogAuthor") {
          return <StoryblokAuthorAvatar key={i} author={author.author} count={i + 1} total={authors.length} />;
        }
        if (author.component === "customAuthor") {
          return <CustomAuthorAvatar key={i} author={author} count={i + 1} total={authors.length} />;
        }
      })}
    </Avatars>
  );
};

const StoryblokAuthorAvatar = ({ author, count, total }) => {
  return (
    <Avatar style={{ zIndex: total - count, marginLeft: count === 1 ? 0 : -12 }}>
      <NextLazyImage src={author?.content?.avatar?.filename} alt={author?.content?.avatar?.alt} fill />
    </Avatar>
  );
};

const CustomAuthorAvatar = ({ author, count, total }) => {
  return (
    <Avatar style={{ zIndex: total - count, marginLeft: count === 1 ? 0 : -12 }}>
      <NextLazyImage src={author?.avatar?.filename} alt={author?.avatar?.alt} fill />
    </Avatar>
  );
};
