import PageHero from "./PageHero";

const PageHeroWithProps = ({ blok }) => {
  return (
    <PageHero
      title={blok.title}
      description={blok.description}
      media={blok.media}
      pillText={blok.pillText}
      theme={blok.theme}
    />
  );
};

export default PageHeroWithProps;
