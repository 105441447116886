import NextLazyImage from "@/components/Core/NextLazyImage";
import { MediaPlayer, Poster, MediaProvider } from "@vidstack/react";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const BackgroundVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: #090907;
  * {
    background: #090907;
    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
      background: #090907;
    }
    [data-media-player] {
      --player-width: auto;
      --media-width: auto;
    }
  }
`;

const VideoPosterContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #090907;
`;

const StyledPoster = styled(Poster)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  z-index: 1;
  background: #090907;
`;

export default function BackgroundVideo({
  posterSrc,
  posterAlt,
  videoSrc,
  videoAlt,
  playerStyles,
  pause = false,
  blurVideo = false,
  containerStyles,
}: {
  posterSrc: string;
  posterAlt: string;
  videoSrc: string;
  videoAlt: string;
  pause?: boolean;
  blurVideo?: boolean;
  playerStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
}) {
  const videoPlayer = useRef(undefined);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showPoster, setShowPoster] = useState(true);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isPlaying) {
      timer = setTimeout(() => {
        setShowPoster(false);
      }, 500);
    } else {
      setShowPoster(true);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isPlaying]);

  return (
    <BackgroundVideoContainer style={{ ...containerStyles }}>
      <MediaPlayer
        title={videoAlt}
        ref={videoPlayer}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 2,
          display: "block",
          position: "relative",
          ...playerStyles,
        }}
        onPlaying={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onEnded={() => setIsPlaying(false)}
        controls={false}
        loop
        autoPlay
        playsInline={true}
        muted={true}
        src={videoSrc}>
        <AnimatePresence>
          {showPoster && (
            <VideoPosterContainer
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}>
              <StyledPoster asChild className='media-poster'>
                <NextLazyImage
                  src={posterSrc}
                  alt={posterAlt}
                  fill
                  containerStyles={{ zIndex: 1, position: "absolute" }}
                />
              </StyledPoster>
            </VideoPosterContainer>
          )}
        </AnimatePresence>
        <MediaProvider style={{ zIndex: 2 }} />
      </MediaPlayer>
    </BackgroundVideoContainer>
  );
}
