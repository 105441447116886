import React from "react";
import styled from "styled-components";

const GradientOverlay = styled.div<{
  $gradientColorOne: string;
  $gradientColorTwo: string;
  $gradientDirection: string;
  $opacity: number;
}>`
  background: linear-gradient(
    ${(props) => props.$gradientDirection},
    ${(props) => props.$gradientColorOne} 0%,
    ${(props) => props.$gradientColorTwo} 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: ${(props) => props.$opacity};
`;

export default function MediaOverlayGradient({ gradientColorOne, gradientColorTwo, gradientDirection, opacity }) {
  return (
    <GradientOverlay
      $gradientColorOne={gradientColorOne}
      $gradientColorTwo={gradientColorTwo}
      $gradientDirection={gradientDirection}
      $opacity={opacity}
    />
  );
}
