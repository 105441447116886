import { useQuery } from "@tanstack/react-query";
import StoryblokClient from "storyblok-js-client";

interface BlogTag {
  uuid: string;
  name: string;
  slug: string;
}

interface StoryblokResponse {
  stories: BlogTag[];
}

const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
});

const fetchAllTags = async (): Promise<BlogTag[]> => {
  try {
    const response = await Storyblok.get("cdn/stories/", {
      version: process.env.NEXT_PUBLIC_PRODUCTION_SITE === "true" ? "published" : "draft",
      filter_query: {
        component: {
          in: "blogPostTag",
        },
      },
      per_page: 100,
    });

    return response.data.stories.map((story) => ({
      uuid: story.uuid,
      name: story.name,
      slug: story.slug,
    }));
  } catch (error) {
    console.error("Error fetching blog tags:", error);
    throw error;
  }
};

export const useAllBlogTags = () => {
  return useQuery<BlogTag[], Error>({
    queryKey: ["allBlogTags"],
    queryFn: fetchAllTags,
  });
};
