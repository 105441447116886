import { useState, useEffect } from "react";
import ContentWrapper from "@/components/Core/ContentWrapper";
import { useAllCaseStudies } from "@/hooks/useAllSbCaseStudes";
import mq from "@/utils/mq";
import styled, { css } from "styled-components";
import CaseStudyCard from "@/components/FilteredCaseStudies/CaseStudyCard";
import InfiniteScroll from "react-infinite-scroll-component";

const CaseStudiesOuterContainer = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  margin-bottom: 42px;
`;

const CaseStudiesContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1180px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const CaseStudiesContentContainer = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
  ${mq.mobile(css`
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  `)};
`;

const LoadingMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  padding: 2rem 0;
`;

export default function AllCaseStudiesGrid() {
  const { data, isLoading } = useAllCaseStudies();
  const [displayedCaseStudies, setDisplayedCaseStudies] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const caseStudiesPerPage = 9;

  useEffect(() => {
    if (data) {
      setDisplayedCaseStudies(data.slice(0, caseStudiesPerPage));
      setHasMore(data.length > caseStudiesPerPage);
    }
  }, [data]);

  const fetchMoreData = () => {
    if (displayedCaseStudies.length >= data.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setDisplayedCaseStudies(data.slice(0, displayedCaseStudies.length + caseStudiesPerPage));
    }, 500);
  };

  if (isLoading) {
    return <LoadingMessage>Loading...</LoadingMessage>;
  }

  return (
    <ContentWrapper>
      <InfiniteScroll
        style={{ width: "100%" }}
        dataLength={displayedCaseStudies.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<LoadingMessage>Loading...</LoadingMessage>}>
        <CaseStudiesOuterContainer>
          <CaseStudiesContainer>
            {displayedCaseStudies?.map((caseStudy, i) => <CaseStudyCard key={i} caseStudy={caseStudy} />)}
          </CaseStudiesContainer>
        </CaseStudiesOuterContainer>
      </InfiniteScroll>
    </ContentWrapper>
  );
}
