import styled from "styled-components";

import CaseStudyHero from "@/components/CaseStudy/CaseStudyHero";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";

const CaseStudyContent = styled.div`
  margin: 62px 0;
`;

const StoryblokCaseStudy = ({ blok }) => {
  return (
    <div>
      <CaseStudyHero
        customerIcon={blok.customerIcon}
        customerName={blok.customerName}
        title={blok.title}
        description={blok.description}
        featuredImage={blok.featuredImage}
      />
      <CaseStudyContent>
        <BlokRenderer blok={blok} key={blok?._uid} />
      </CaseStudyContent>
    </div>
  );
};

export default StoryblokCaseStudy;
