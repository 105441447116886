import NextLazyImage from "@/components/Core/NextLazyImage";
import { render } from "storyblok-rich-text-react-renderer";
import styled from "styled-components";

const CardSliderItemWithTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 300px;
  @media (max-width: 350px) {
    max-width: 250px;
  }
`;

const SliderAsset = styled.div`
  width: 100%;
  position: relative;
  aspect-ratio: 1/1.3;
  border-radius: 8px;
  overflow: hidden;
`;

const SliderText = styled.div``;

const Title = styled.h3`
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  margin: 0;
  color: var(--PP-Text-Primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const Description = styled.div`
  margin: 0;
  overflow: hidden;
  color: #677076;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  a {
    text-decoration: none;
    color: var(--PrimaryAccent);
  }
`;

export default function CardSliderItemWithText({ item }) {
  return (
    <CardSliderItemWithTextWrapper>
      <SliderAsset>
        <NextLazyImage src={item.image.filename} alt={item.title} fill />
      </SliderAsset>
      <SliderText>
        <Title>{item.title}</Title>
        <Description>{render(item.description)}</Description>
      </SliderText>
    </CardSliderItemWithTextWrapper>
  );
}
