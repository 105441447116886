import BackgroundVideo from "@/components/Shared/BackgroundVideo";
import React from "react";

export default function BackgroundMedia({ component }) {
  if (!component || component?.length < 1 || !component[0].component) return null;

  if (component[0].component === "videoBackground") {
    return (
      <BackgroundVideo
        posterAlt={component[0].poster.alt}
        posterSrc={component[0].poster.filename}
        videoAlt={component[0].video.alt}
        videoSrc={component[0].video.filename}
      />
    );
  } else {
    return <div>BackgroundMedia</div>;
  }
}
