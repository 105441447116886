import { render } from "storyblok-rich-text-react-renderer";
import Quote from "./Quote";

const QuoteWithProps = ({ blok }) => {
  return (
    <Quote
      quote={render(blok.quote)}
      quoteeName={blok.quoteeName}
      quoteeRole={blok.quoteeRole}
      quoteeCompany={blok.quoteeCompany}
      quoteeAvatar={blok.quoteeAvatar}
      enablePaddingTop={blok.enablePaddingTop}
      enablePaddingBottom={blok.enablePaddingBottom}
      theme={blok.theme}
    />
  );
};

export default QuoteWithProps;
