import BlogCollectionPreviewCard from "@/components/BlogRootRework/BlogCollectionPreview/BlogCollectionPreviewCard";
import BlogCollectionPreviewCarousel from "@/components/BlogRootRework/BlogCollectionPreview/BlogCollectionPreviewCarousel";
import mq from "@/utils/mq";
import styled from "styled-components";
import { css } from "styled-components";
import CollectionIcon from "@/assets/svg/collections.svg";
import LabelCta from "@/components/Buttons/LabelCta";

const OuterContainer = styled.div`
  width: 100%;
  @media (max-width: 1200px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  ${mq.mobile(css`
    padding: 0 16px;
  `)};
`;

const BlogCollectionPreviewContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1180px;

  border: 1px solid #e1e3e4;
  background: #fff;
  border-radius: 12px;
  //overflow: hidden;
`;

const BlogCollectionPreviewUpperLayer = styled.div`
  padding: 32px;
  ${mq.mobile(css`
    padding: 24px 16px;
    padding-bottom: 8px;
  `)};
`;

const BlogCollectionPreviewTopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(223 227 230/1);
  padding-bottom: 16px;
`;

const BlogCollectionPreviewLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

const BlogCollectionPreviewLabelIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5c5866;
  ${mq.mobile(css`
    width: 16px;
    height: 16px;
  `)};
  svg {
    width: 100%;
    height: 100%;
  }
`;

const BlogCollectionPreviewLabelText = styled.p`
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-pp-neue);
  color: var(--PP-Section-Title, #4b5563);
  line-height: 13px;
  user-select: none;
  ${mq.mobile(css`
    font-size: 12px;
    line-height: 12px;
  `)};
`;

const BlogCollectionPreviewCTA = styled.p``;

const BlogCollectionPreviewTitleSection = styled.div`
  width: 100%;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  position: relative;
  gap: 0rem 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-items: start;
  align-items: start;
  margin-top: 22px;
  user-select: none;
  @media (min-width: 768px) {
    gap: 0 4em;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const BlogCollectionPreviewTitle = styled.h2`
  color: #10181c;
  font-family: var(--font-family-inter);
  font-size: 32px;
  text-wrap: pretty;
  font-weight: 400;
  letter-spacing: -0.96px;
  line-height: 40px;
  margin-top: -2px;
  user-select: none;
  ${mq.mobile(css`
    font-size: 24px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 4px;
  `)};
`;

const BlogCollectionPreviewDescription = styled.p`
  text-wrap: pretty;
  color: #677076;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  max-width: 500px;
  width: 100%;
  color: var(--PP-Text-Secondary, #677076);
  font-style: normal;
  font-weight: 400;
  text-wrap: pretty;
  user-select: none;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 14px;
    line-height: 24px;
  `)};
`;

export default function BlogCollectionPreview({ collection, hideLinkToCollectionPage }) {
  return (
    <OuterContainer>
      <BlogCollectionPreviewContainer>
        <BlogCollectionPreviewUpperLayer>
          <BlogCollectionPreviewTopRow>
            <BlogCollectionPreviewLabel>
              <BlogCollectionPreviewLabelIcon>
                <CollectionIcon />
              </BlogCollectionPreviewLabelIcon>
              <BlogCollectionPreviewLabelText>Featured collection</BlogCollectionPreviewLabelText>
            </BlogCollectionPreviewLabel>
            <BlogCollectionPreviewCTA>
              <LabelCta label='View collection' color='#4a7c55' rawLink={collection.full_slug} includeArrow />
            </BlogCollectionPreviewCTA>
          </BlogCollectionPreviewTopRow>
          <BlogCollectionPreviewTitleSection>
            <BlogCollectionPreviewTitle>{collection.content.title}</BlogCollectionPreviewTitle>
            <BlogCollectionPreviewDescription>{collection.content.description}</BlogCollectionPreviewDescription>
          </BlogCollectionPreviewTitleSection>
        </BlogCollectionPreviewUpperLayer>
        <BlogCollectionPreviewCarousel stories={[...collection.content.stories]} />
      </BlogCollectionPreviewContainer>
    </OuterContainer>
  );
}
