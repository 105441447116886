import React from "react";
import styled from "styled-components";

import ContentWrapper from "@/components/Core/ContentWrapper";
import CollectionStoryCard from "@/components/BlogCollectionSlider/CollectionStoryCard";
import { useAllBlogCollections } from "@/hooks/useAllBlogCollections";

const StoriesOuterContainer = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

const StoriesContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1180px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: red;
  font-size: 18px;
  margin-top: 20px;
`;

export default function BlogAllCollectionsGrid() {
  const { data: collections, isLoading, error } = useAllBlogCollections();

  if (isLoading) {
    return (
      <ContentWrapper>
        <LoadingMessage>Loading blog collections...</LoadingMessage>
      </ContentWrapper>
    );
  }

  if (error) {
    return (
      <ContentWrapper>
        <ErrorMessage>Error loading blog collections: {error.message}</ErrorMessage>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <StoriesOuterContainer>
        <StoriesContainer>
          {collections?.map((story, i) => (
            <CollectionStoryCard key={story.id || i} story={story} customCtaLabel={"See more"} />
          ))}
        </StoriesContainer>
      </StoriesOuterContainer>
    </ContentWrapper>
  );
}
