import NextLazyImage from "@/components/Core/NextLazyImage";
import mq from "@/utils/mq";
import styled, { css } from "styled-components";

const LeadingImageWrapper = styled.div`
  width: 110%;
  margin-top: 4rem;
  margin-left: -5%;
  position: relative;

  height: auto;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 1550px) {
    width: 100%;
    margin-left: 0;
  }
  ${mq.mobile(css`
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
  `)};
`;

export default function BlogPostLeadingImage({ image }: { image: any }) {
  return (
    <LeadingImageWrapper>
      <NextLazyImage src={image.filename} alt={image.alt} loading='eager' />
    </LeadingImageWrapper>
  );
}
