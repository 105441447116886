import React from "react";
import styled from "styled-components";

import StoryItemControls from "@/components/CaseStudyStoriesSlider/CaseStudyStoryItem/StoryItemControls";
import StoryItemPopoverHandler from "@/components/CaseStudyStoriesSlider/CaseStudyStoryItem/StoryItemPopoverHandler";
import StoryItemMediaHandler from "@/components/CaseStudyStoriesSlider/CaseStudyStoryItem/StoryItemMediaHandler";
import { useRouter } from "next/router";

const StoryMediaContainer = styled.div`
  width: 100%;
  height: 480px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  user-select: none;
`;

export default function StoryItemMediaProvider({
  active,
  isHovered,
  backgroundMedia,
  backgroundOverlay,
  brandImage,
  brandName,
  tags,
  handlePauseResume,
  count,
  isPaused,
  enablePopoverVideo,
  id,
  popoverVideoFile,
  popoverMediaActive,
  setPopoverMediaActive,
  onClick,
  caseStudyLink,
  mediaContainerRef,
}) {
  return (
    <StoryMediaContainer ref={mediaContainerRef}>
      <StoryItemPopoverHandler
        active={active}
        enablePopoverVideo={enablePopoverVideo}
        id={id}
        popoverVideoFile={popoverVideoFile}
        popoverMediaActive={popoverMediaActive}
        setPopoverMediaActive={setPopoverMediaActive}
      />
      <StoryItemMediaHandler
        active={active}
        isHovered={isHovered}
        backgroundMedia={backgroundMedia}
        backgroundOverlay={backgroundOverlay}
        brandImage={brandImage}
        brandName={brandName}
        tags={tags}
        onClick={onClick}
        id={id}
        caseStudyLink={caseStudyLink}
      />
      <StoryItemControls
        active={active}
        brandImage={brandImage}
        brandName={brandName}
        tags={tags}
        handlePauseResume={handlePauseResume}
        count={count}
        isPaused={isPaused}
      />
    </StoryMediaContainer>
  );
}
