import Image from "next/image";
import { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";

interface LazyImageProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  quality?: number;
  priority?: boolean;
  fill?: boolean;
  style?: React.CSSProperties;
  parallax?: boolean;
  sizes?: string;
  parallaxSpeed?: number;
  layout?: "fixed" | "constrained" | "fullWidth";
  blurHash?: string; // New prop for pre-generated blurHash
  aspectRatio?: number;
  blur?: boolean;
  allowOverflow?: boolean;
  loading?: "lazy" | "eager";
}

interface IProps {
  position?: string;
  fit?: string;
  blur?: boolean;
}

const fadeIn = keyframes`
from {
    filter: blur(20px);
  }
  to {
    filter: blur(0px);
  }

`;

const ImageContainer = styled.div<IProps>`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    !props.blur &&
    css`
      animation: ${fadeIn} 0.2s ease-in-out;
    `}
  ${(props) =>
    props.blur &&
    css`
      filter: blur(20px);
    `}
`;

const StyledImage = styled(Image)`
  height: 100%;
  overflow: hidden;
`;

export default function NextLazyImage({
  src,
  alt,
  width,
  height,
  sizes,
  quality = 70,
  priority = false,
  fill = false,
  parallax = false,
  loading = "lazy",
  parallaxSpeed = 0.1,
  layout,
  style,
  blurHash,
  aspectRatio,
  allowOverflow = false,
  blur = true,
  ...props
}: LazyImageProps) {
  const [shouldBlur, setShouldBlur] = useState(true);
  const [imageSrc, setImageSrc] = useState(src);
  const [placeholder, setPlaceholder] = useState(`/_next/image?url=${src}&w=16&q=1`);

  const [loaded, setLoaded] = useState(false);
  let sbWidth = Number(src.split("/")[5].split("x")[0]);
  let sbHeight = Number(src.split("/")[5].split("x")[1]);

  const isStoryblokImage = (src: string) => {
    try {
      if (!src) return false;
      return src.includes("https://a.storyblok.com");
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (src && isStoryblokImage(src)) {
      if (width && height) {
        setImageSrc(`${src}/m/${width}x${height}/filters:quality(${quality})`);
        setPlaceholder(`${src}/m/filters:quality(1)blur(100)`);
        if (sbWidth && sbHeight) {
          setImageSrc(`${src}/m/${sbWidth}x${sbHeight}/filters:quality(${quality})`);
          setPlaceholder(`${src}/m/filters:quality(1)blur(100)`);
        } else {
          setImageSrc(`${src}/m/filters:quality(${quality})`);
          setPlaceholder(`${src}/m/filters:quality(1)blur(100)`);
        }
      }
    }
  }, [src, width, height, quality]);

  if (!src) return null;

  const loader = ({ src }) => `${src}`;

  return (
    <ImageContainer
      style={allowOverflow ? { overflow: "visible" } : { overflow: "hidden" }}
      className={shouldBlur ? "blur" : "unblur"}
      blur={shouldBlur}>
      <StyledImage
        src={imageSrc}
        alt={alt}
        sizes={sizes}
        width={width || sbWidth}
        height={height || sbHeight}
        layout={layout ? layout : fill ? "fullWidth" : "constrained"}
        onLoad={() => setShouldBlur(false)}
        onLoadingComplete={() => setShouldBlur(false)}
        quality={quality}
        placeholder='blur'
        loading={priority ? "eager" : loading}
        blurDataURL={placeholder}
        priority={priority}
        style={{ objectFit: "cover", objectPosition: "center", ...style }}
        {...props}
      />
    </ImageContainer>
  );
}
