import styled, { css } from "styled-components";
import mq from "@/utils/mq";

const JobListMetaItemWrapper = styled.div<{ $small?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #f3f3f4;
  background: var(--Light300);
  gap: 8px;
  padding: 4px 14px;
  width: fit-content;
  background: #fff;
  border-radius: 0.375rem;
  ${({ $small }) =>
    $small &&
    css`
      padding: 2px 8px;
      gap: 6px;
    `}
`;

const IconContainer = styled.div<{ $small?: boolean }>`
  width: 14px;
  height: 14px;
  color: #10181c9f;
  ${({ $small }) =>
    $small &&
    css`
      width: 14px;
      height: 14px;
    `}
  svg {
    width: 100%;
    height: 100%;
  }
`;

const JobListingMetaValue = styled.div<{ $small?: boolean }>`
  color: #10181c9f;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  span {
    color: var(--PP-Text-Primary);
  }
  ${({ $small }) =>
    $small &&
    css`
      font-size: 10px;
      line-height: 18px;
    `}
  ${mq.mobile(css`
    white-space: normal;
  `)};
`;

export const EmploymentTypeParser = (employmentType) => {
  switch (employmentType) {
    case "FULL_TIME":
      return "Full-Time";
    case "PART_TIME":
      return "Part-Time";
    case "CONTRACTOR":
      return "Contractor";
    case "TEMPORARY":
      return "Temporary";
    case "INTERN":
      return "Intern";
    case "PER_DIEM":
      return "Per Diem";
    case "OTHER":
      return "Other";
    default:
      return employmentType;
  }
};

export const EmploymentLocationParser = ({ type, town }: { type?: string; town?: string }) => {
  if (!type) return "N/A";

  if (type === "IN_PERSON") {
    if (town) {
      return `${town}`;
    }
    return "In Person";
  }
  if (type === "TELECOMMUTE") {
    return "Remote";
  }
  if (type === "HYBRID") {
    if (town) {
      return `${town}, Hybrid`;
    }
    return "Hybrid";
  }
  return "N/A"; // final fallback
};

export const SalaryParser = ({ minValue, maxValue, type }) => {
  const parsedMinValue = parseFloat(minValue);
  const parsedMaxValue = parseFloat(maxValue);

  if (isNaN(parsedMinValue) || isNaN(parsedMaxValue)) {
    return "Invalid salary data";
  }

  if (type === "YEAR" || type === "MONTH") {
    const formatWholeNumber = (num: number) => num.toLocaleString("en-GB", { maximumFractionDigits: 0 });
    return `£${formatWholeNumber(parsedMinValue)} - £${formatWholeNumber(parsedMaxValue)} per ${type.toLowerCase()}`;
  }

  // Format with 2 decimal places and commas
  const formatDecimalNumber = (num: number) =>
    num.toLocaleString("en-GB", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  return `£${formatDecimalNumber(parsedMinValue)} - £${formatDecimalNumber(parsedMaxValue)} per ${type.toLowerCase()}`;
};

export const JobListingMetaItem = ({
  label,
  value,
  icon,
  small,
}: {
  label: string;
  value: string;
  icon: React.ReactNode;
  small?: boolean;
}) => {
  return (
    <JobListMetaItemWrapper $small={small}>
      <IconContainer $small={small}>{icon}</IconContainer>
      <JobListingMetaValue $small={small}>{value}</JobListingMetaValue>
    </JobListMetaItemWrapper>
  );
};
