import IconRenderer from "@/components/Shared/IconRenderer";
import SkeletonShimmer from "@/components/Shared/SkeletonShimmer";
import styled, { css } from "styled-components";

import Close from "@/assets/svg/closeIcon.svg";
import { motion } from "motion/react";

const SectorPillContainer = styled(motion.div)<{ $isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 4px 14px 4px 12px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #f3f4f6;
  height: 31px;
  color: var(--PP-Section-Title, #4b5563);
  cursor: pointer;
  ${(props) =>
    props.$isSelected &&
    css`
      background-color: var(--PrimaryAccent);
      color: #fff;
    `}
`;

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
`;

const Label = styled.div`
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  user-select: none;
`;

const SkeletonContainer = styled.div`
  height: 24px;
`;

const RemoveIconOuterContainer = styled(motion.div)`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -2px;
`;

const RemoveIcon = styled(motion.div)`
  width: 16px;
  min-width: 16px;
  height: 16px;
  color: #fff;
  cursor: pointer;
`;

export default function SectorPill({
  label,
  icon,
  id,
  isLoading,
  width,
  isSelected,
  onClick,
}: {
  label?: string;
  icon?: any;
  id?: any;
  isLoading?: boolean;
  width?: number;
  isSelected?: boolean;
  onClick?: any;
}) {
  if (isLoading) {
    return (
      <SectorPillContainer>
        <SkeletonContainer style={{ width: width + "px" }}>
          <SkeletonShimmer baseColor={"#9ca3af32"} highlightColor={"#9ca3af55"} />
        </SkeletonContainer>
      </SectorPillContainer>
    );
  }

  return (
    <SectorPillContainer
      $isSelected={isSelected}
      onClick={onClick}
      animate={
        isSelected
          ? {
              padding: "4px 5px 4px 7px",
              gap: "5px",
            }
          : { padding: "4px 14px 4px 12px", gap: "6px" }
      }>
      <IconContainer>
        <IconRenderer icon={icon} color={!isSelected ? "#677076" : "#fff"} />
      </IconContainer>
      <Label>{label}</Label>

      <RemoveIconOuterContainer
        initial={isSelected ? { width: "16px" } : { width: 0 }}
        animate={isSelected ? { width: "16px" } : { width: 0 }}>
        <RemoveIcon
          initial={isSelected ? { rotate: 90 } : { rotate: 0 }}
          animate={isSelected ? { rotate: 90 } : { rotate: 0 }}>
          <Close />
        </RemoveIcon>
      </RemoveIconOuterContainer>
    </SectorPillContainer>
  );
}
