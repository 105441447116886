import styled, { css } from "styled-components";
import DropdownButton from "@/components/Shared/DropdownButton";
import SearchIcon from "@/assets/svg/search.svg";
import FilterIcon from "@/assets/svg/filter.svg";
import TagIcon from "@/assets/svg/tag.svg";
import mq from "@/utils/mq";

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0rem;
  position: sticky;
  top: 64px;
  z-index: 100;
  background: #f9fafb;

  padding: 8px 0 16px 0;
  border-radius: 0px;
  max-width: 1180px;
  margin: 0 auto;
  ${mq.mobile(css`
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    position: relative;
    top: 0;
  `)};
`;

const FiltersWrapper = styled.div`
  display: flex;

  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const SearchWrapper = styled.div`
  display: flex;
  max-width: 320px;
  width: 100%;
  padding: 8px 11px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #dbdcdd;
  background: #fcfcfc;
  height: 38px;
`;

const SearchIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  color: #717680;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  color: var(--PP-Text-Primary);
  font-family: var(--font-inter);
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  border: 0px transparent;
  background: transparent;
  ::placeholder {
    color: #717680;
  }
`;

interface BlogPostsControlsProps {
  categories: any[];
  tags: any[];
  selectedCategories: string[];
  selectedTags: string[];
  searchQuery: string;
  onCategoryChange: (category: string) => void;
  onTagChange: (tag: string) => void;
  onSearchChange: (search: string) => void;
}

export default function BlogPostsControls({
  categories,
  tags,
  selectedCategories,
  selectedTags,
  searchQuery,
  onCategoryChange,
  onTagChange,
  onSearchChange,
}: BlogPostsControlsProps) {
  const categoryMenuSections = [
    {
      items: [
        { id: "all", label: "All categories" },
        ...categories?.map((category) => ({
          id: category.slug,
          label: category.name,
          selected: selectedCategories.includes(category.slug),
        })),
      ],
      showSeparator: false,
    },
  ];

  const tagMenuSections = [
    {
      items: [
        { id: "all", label: "All tags" },
        ...tags?.map((tag) => ({
          id: tag.uuid,
          label: tag.name,
          selected: selectedTags.includes(tag.uuid),
        })),
      ],
      showSeparator: false,
    },
  ];

  return (
    <ControlsWrapper>
      <FiltersWrapper>
        <DropdownButton
          label={
            selectedCategories.includes("all") ? "All categories" : `${selectedCategories.length} categories selected`
          }
          icon={<FilterIcon />}
          sections={categoryMenuSections}
          onChange={onCategoryChange}
          selectedIds={selectedCategories}
          multiSelect
        />
        <DropdownButton
          label={selectedTags.includes("all") ? "All tags" : `${selectedTags.length} tags selected`}
          icon={<TagIcon />}
          sections={tagMenuSections}
          onChange={onTagChange}
          selectedIds={selectedTags}
          multiSelect
        />
      </FiltersWrapper>
      <SearchWrapper>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <SearchInput placeholder='Search posts' value={searchQuery} onChange={(e) => onSearchChange(e.target.value)} />
      </SearchWrapper>
    </ControlsWrapper>
  );
}
