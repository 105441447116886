import EmbeddedVideo from "./EmbeddedVideo";

const EmbeddedVideoWithProps = ({ blok }) => {
  return (
    <EmbeddedVideo
      videoLink={blok.videoLink}
      poster={blok.poster}
      theme={blok.theme}
      title={blok.title}
      description={blok.description}
      lengthMinutes={blok.lengthMinutes}
      lengthSeconds={blok.lengthSeconds}
      uploadDate={blok.uploadDate}
    />
  );
};

export default EmbeddedVideoWithProps;
