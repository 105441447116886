import NextLazyImage from "@/components/Core/NextLazyImage";
import styled from "styled-components";
import { render } from "storyblok-rich-text-react-renderer";

import PlusIcon from "@/assets/svg/plus.svg";
import { motion } from "motion/react";
import { useState } from "react";

const TeamGridItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const TeamGridItemContainer = styled.div`
  width: 100%;
`;

const UserImageContainer = styled.div`
  width: 100%;

  aspect-ratio: 3 / 4;
  border-radius: 8px;
  overflow: hidden;
`;

const UserInfoContainer = styled.div`
  margin-top: 16px;
`;

const TopLayer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const NameRoleContainer = styled.div``;

const Name = styled.div`
  color: var(--PP-Text-Primary, #10181c);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

const Role = styled.div`
  color: var(--PP-Text-Secondary, #677076);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.4px;
`;

const ToggleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  background: #2f3e3c;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
  }
`;

const AdditionalInfoContainer = styled(motion.div)`
  height: 100%;
  overflow: hidden;
`;

const Bio = styled.div`
  margin-top: 8px;
  color: var(--PP-Text-Secondary, #677076);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.4px;
`;

const SocialLinks = styled.div``;

export default function TeamGridItem({ name, role, bio, image, instagramLink, linkedInLink }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <TeamGridItemWrapper>
      <TeamGridItemContainer>
        <UserImageContainer onClick={toggleExpanded}>
          <NextLazyImage key={image.filename} src={image.filename} alt={name} layout='fullWidth' aspectRatio={3 / 4} />
        </UserImageContainer>
        <UserInfoContainer>
          <TopLayer>
            <NameRoleContainer>
              <Name>{name}</Name>
              <Role>{role}</Role>
            </NameRoleContainer>
            <ToggleButton onClick={toggleExpanded}>
              <motion.div animate={isExpanded ? { rotate: 225 } : { rotate: 0 }} initial={{ rotate: 0 }}>
                <PlusIcon />
              </motion.div>
            </ToggleButton>
          </TopLayer>
          <AdditionalInfoContainer animate={isExpanded ? { height: "fit-content" } : { height: 0 }}>
            <Bio>{render(bio)}</Bio>
            <SocialLinks></SocialLinks>
          </AdditionalInfoContainer>
        </UserInfoContainer>
      </TeamGridItemContainer>
    </TeamGridItemWrapper>
  );
}
