import styled, { css } from "styled-components";
import { render } from "storyblok-rich-text-react-renderer";
import IconRenderer from "@/components/Shared/IconRenderer";
import mq from "@/utils/mq";
import { motion } from "motion/react";
import { ThemeOption, themeProviderWithInheritance } from "@/utils/ThemeProvider";
import CtaRenderer from "@/components/Shared/CtaRenderer";
import { ScrollContainer, useScrollContainer } from "react-indiana-drag-scroll";
import { useState, useRef } from "react";
import { useFloating, useInteractions, useClick, useDismiss, useListNavigation, offset } from "@floating-ui/react";

const SectionTitleContainer = styled.div<{ $spacing?: "standard" | "expanded" | "tight" | "tabMode" }>`
  width: 100%;
  max-width: 1440px;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-items: center;
  align-items: center;
  padding-bottom: 3rem;
  ${(props) =>
    props.$spacing === "expanded" &&
    css`
      gap: 1.5rem;
    `}
  ${(props) =>
    props.$spacing === "tight" &&
    css`
      gap: 0.5rem;
    `}
  
    gap: 22px;
  align-items: flex-start;
  ${(props) =>
    props.$spacing === "expanded" &&
    css`
      gap: 32px;
    `}
  ${(props) =>
    props.$spacing === "tight" &&
    css`
      gap: 16px;
    `}
    
    ${mq.mobile(css`
    max-width: 100vw;
    overflow: hidden;
    padding-bottom: 1.25rem;
  `)};
  ${(props) =>
    props.$spacing === "tabMode" &&
    css`
      overflow: visible;
      ${mq.mobile(css`
        overflow: visible;
      `)};
    `}
`;

const STUpperLayer = styled.div<{ $spacing?: "standard" | "expanded" | "tight" | "tabMode" }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(223 227 230/1);
  padding-bottom: 8px;
  ${(props) =>
    props.$spacing === "expanded" &&
    css`
      padding-bottom: 12px;
    `}
  ${(props) =>
    props.$spacing === "tight" &&
    css`
      padding-bottom: 6px;
    `}
  
    ${mq.mobile(css`
    flex-wrap: wrap;
    gap: 8px;
  `)};
`;

const STUpperLayerLabelSection = styled.div<{ $spacing?: "standard" | "expanded" | "tight" | "tabMode" }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

const LabelIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5c5866;
  ${mq.mobile(css`
    width: 16px;
    height: 16px;
  `)};
`;

const LabelText = styled.div`
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-pp-neue);
  color: var(--PP-Section-Title, #4b5563);
  line-height: 13px;
  ${mq.mobile(css`
    font-size: 12px;
    line-height: 12px;
  `)};
`;

const STUpperLayerCallToActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  ${mq.mobile(css`
    padding: 1px;
    overflow: visible;
  `)};
`;

const STContentContainer = styled.div`
  width: 100%;
  max-width: var(--max-content);
  display: grid;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  position: relative;
  gap: 0rem 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-items: start;
  align-items: start;
  @media (min-width: 768px) {
    gap: 0 4em;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const STTitle = styled.div`
  color: var(--PP-Text-Primary);
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin-top: -2px;
  ${mq.mobile(css`
    font-size: 24px;
    line-height: 32px;
  `)};
`;

const STBody = styled.div`
  color: #677076;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  max-width: 500px;
  justify-self: end;
  ${mq.mobile(css`
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
    max-width: 100%;
    justify-self: start;
  `)};
`;

const TabsContainer = styled.div`
  display: flex;
  //justify-content: flex-end;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  position: relative;
  &::before,
  &::after {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    width: 20px;
    pointer-events: none;
    z-index: 1;
  }
  &::before {
    left: 0;
    background: linear-gradient(to right, var(--background-color, #ffffff) 0%, rgba(255, 255, 255, 0) 100%);
  }
  &::after {
    right: 0;
    background: linear-gradient(to left, var(--background-color, #ffffff) 0%, rgba(255, 255, 255, 0) 100%);
  }
  align-items: center;
  gap: 10px;
  max-width: 300px;

  flex: none;
  ${mq.mobile(css`
    margin-top: 32px;
    justify-content: flex-start;
  `)};
`;

const StTab = styled.div<{ $active: boolean }>`
  color: #a1a1aa;
  font-family: var(--font-inter);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  padding: 0 8px;
  white-space: nowrap;

  overflow-y: visible;
  ${(props) =>
    props.$active &&
    css`
      color: #52525b;
    `}
`;

const ActiveIndicator = styled(motion.div)`
  width: 100%;
  height: 1px;
  background: #52525b;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -13px;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 13px;
  font-weight: 500;
  color: #52525b;
  cursor: pointer;
`;

const DropdownIcon = styled.span`
  margin-left: 8px;
  font-size: 10px;
`;

const DropdownMenu = styled.div`
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  z-index: 1000;
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const DropdownItem = styled.button`
  width: 100%;
  padding: 8px 16px;
  background: none;
  border: none;
  text-align: left;
  font-size: 13px;
  color: #52525b;
  cursor: pointer;

  &:hover {
    background-color: #f3f4f6;
  }
`;

export default function SectionTitle({
  sectionTitle,
  tabTitleMode,
  parentTheme,
}: {
  sectionTitle: any;
  tabTitleMode?: boolean;
  parentTheme?: ThemeOption;
}) {
  if (tabTitleMode) return <SectionTabTitle sectionTitle={sectionTitle} />;
  if (!sectionTitle || !sectionTitle.length) return null;
  const {
    sectionPretextIcon,
    sectionPretextLabel,
    sectionCtas,
    sectionTitleText,
    sectionBodyText,
    spacing,
    theme,
    inheritTheme,
  } = sectionTitle[0];
  return (
    <SectionTitleContainer
      $spacing={spacing}
      style={{ backgroundColor: themeProviderWithInheritance({ theme, parentTheme, inheritTheme }).backgroundColor }}>
      <STUpperLayer $spacing={spacing}>
        <STUpperLayerLabelSection $spacing={spacing}>
          {sectionPretextIcon && sectionPretextIcon.length > 0 && (
            <LabelIcon>
              <IconRenderer icon={sectionPretextIcon} />
            </LabelIcon>
          )}
          <LabelText>{sectionPretextLabel}</LabelText>
        </STUpperLayerLabelSection>
        <STUpperLayerCallToActions>
          <CtaRenderer ctas={sectionCtas} />
        </STUpperLayerCallToActions>
      </STUpperLayer>
      <STContentContainer>
        <STTitle>{render(sectionTitleText)}</STTitle>
        <STBody>{render(sectionBodyText)}</STBody>
      </STContentContainer>
    </SectionTitleContainer>
  );
}

const SectionTabTitle = ({ sectionTitle, parentTheme }: { sectionTitle: any; parentTheme?: ThemeOption }) => {
  const { icon, title, tabs, activeTab, onTabChange, inheritTheme, theme, spacing } = sectionTitle;

  // Add state for dropdown visibility
  const [isOpen, setIsOpen] = useState(false);

  // Initialize floating UI
  const { x, y, refs, strategy, context } = useFloating({
    placement: "bottom-end",
    open: isOpen,

    onOpenChange: setIsOpen,
    middleware: [offset(5)],
  });

  const listRef = useRef<Array<HTMLButtonElement | null>>([]);

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([click, dismiss]);
  if (!sectionTitle) return null;
  return (
    <SectionTitleContainer
      $spacing={spacing}
      style={{
        backgroundColor: themeProviderWithInheritance({
          theme,
          parentTheme,
          inheritTheme,
        }).backgroundColor,
      }}>
      <STUpperLayer $spacing={spacing}>
        <STUpperLayerLabelSection $spacing={spacing}>
          <LabelIcon>
            <IconRenderer icon={icon} />
          </LabelIcon>
          <LabelText>{title}</LabelText>
        </STUpperLayerLabelSection>
        <STUpperLayerCallToActions>
          <div>
            <DropdownButton ref={refs.setReference} {...getReferenceProps()}>
              {tabs[activeTab]?.label}
              <DropdownIcon>▼</DropdownIcon>
            </DropdownButton>
            {isOpen && (
              <DropdownMenu
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
                ref={refs.setFloating}
                {...getFloatingProps()}>
                {tabs?.map((tab, index) => (
                  <DropdownItem
                    key={tab.id}
                    ref={(node) => {
                      listRef.current[index] = node;
                    }}
                    onClick={() => {
                      onTabChange(index);
                      setIsOpen(false);
                      // Remove setSelectedIndex to rely solely on activeTab prop
                      // setSelectedIndex(index);
                    }}
                    type='button' // Add type to prevent unintended behavior
                    {...getItemProps()}>
                    {tab.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            )}
          </div>
        </STUpperLayerCallToActions>
      </STUpperLayer>
    </SectionTitleContainer>
  );
};
