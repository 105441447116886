import ContentWrapper from "@/components/Core/ContentWrapper";
import SectionTitle from "@/components/Shared/SectionTitle";
import mq from "@/utils/mq";
import { ThemeOption } from "@/utils/ThemeProvider";
import styled from "styled-components";
import { css } from "styled-components";

const SectionContent = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
  ${mq.mobile(css`
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  `)};
`;

const SectionTitleWrapper = styled.div<{ $mobilePaddingTitle?: boolean }>`
  width: 100%;
  ${({ $mobilePaddingTitle }) =>
    $mobilePaddingTitle &&
    css`
      ${mq.mobile(css`
        padding: 0 22px;
      `)};
    `}
`;

const Content = styled.div``;

export default function SectionContentWrapper({
  sectionTitle,
  backgroundColor,
  children,
  theme,
  noMobilePadding,
  tabTitleMode,
  mobilePaddingTitle,
}: {
  sectionTitle: any;
  backgroundColor?: string;
  children?: React.ReactNode;
  theme?: ThemeOption;
  noMobilePadding?: boolean;
  tabTitleMode?: boolean;
  mobilePaddingTitle?: boolean;
}) {
  return (
    <ContentWrapper backgroundColor={backgroundColor} theme={theme} noMobilePadding={noMobilePadding}>
      <SectionContent>
        <SectionTitleWrapper $mobilePaddingTitle={mobilePaddingTitle}>
          <SectionTitle sectionTitle={sectionTitle} tabTitleMode={tabTitleMode} parentTheme={theme} />
        </SectionTitleWrapper>
        <Content>{children}</Content>
      </SectionContent>
    </ContentWrapper>
  );
}
