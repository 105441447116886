import Button from "@/components/Buttons/Button";
import ContentWrapper from "@/components/Core/ContentWrapper";
import IconRenderer from "@/components/Shared/IconRenderer";
import mq from "@/utils/mq";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";

import JobListingDaysIcon from "@/assets/svg/jobListingDays.svg";
import JobListingHoursIcon from "@/assets/svg/jobListingHours.svg";
import JobListingLocationIcon from "@/assets/svg/jobListingLocation.svg";
import JobListingPostedIcon from "@/assets/svg/jobListingPosted.svg";
import JobListingSalaryIcon from "@/assets/svg/jobListingSalary.svg";
import JobListingEmploymentTypeIcon from "@/assets/svg/jobListingType.svg";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import JobListingBreadcrumbIcon from "@/assets/svg/jobListings.svg";
import { JobListingMetaItem } from "@/components/JobListing/Shared";

const HeroOuterWrapper = styled.div`
  background-color: #f9fafb;
  width: 100%;
`;

const HeroInnerWrapper = styled.div`
  width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 92.5rem;
  padding-inline: 1rem 0;

  ${mq.mobile(css`
    padding-inline: 20px;
  `)};
`;

const HeroContainer = styled.div`
  padding: 4rem;
  background-color: #f9fafb;
  padding-top: 1rem;
  margin-top: 2rem;
  padding-inline-start: 4rem;
  padding-inline-end: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  @media (max-width: 1000px) {
    padding-top: 68px;
  }
  ${mq.mobile(css`
    padding-inline: 0;
    padding-top: 68px;
    padding-bottom: 26px;
  `)};
`;

const JobListingMeta = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 640px;
`;

const Title = styled.h1`
  color: #10181c;
  font-family: var(--font-family-inter);
  font-size: 42px;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: -0.96px;
  //.max-width: 840px;
  text-wrap: pretty;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 28px;
    line-height: 36px;
  `)};
`;

const Description = styled.p`
  color: #677076;
  font-size: 18px;
  line-height: 24px;
  max-width: 640px;
  margin: 0;
  ${mq.mobile(css`
    font-size: 16px;
  `)};
`;

export default function JobListingHero({
  title,
  description,
  employmentType,
  location,
  salary,
  workingHours,
  datePosted,
}) {
  return (
    <HeroOuterWrapper>
      <HeroInnerWrapper>
        <HeroContainer>
          <Breadcrumbs
            breadcrumbs={[
              { title: "Vacancies", url: "/vacancies", isJobListing: true },
              { title: "Listing", url: "#" },
            ]}
          />
          <Title>{title}</Title>
          <Description>{description}</Description>
          <JobListingMeta>
            <JobListingMetaItem
              label='Employment Type'
              icon={<JobListingEmploymentTypeIcon />}
              value={employmentType}
            />
            <JobListingMetaItem label='Location' icon={<JobListingLocationIcon />} value={location} />
            <JobListingMetaItem label='Salary' icon={<JobListingSalaryIcon />} value={salary} />
            <JobListingMetaItem label='Working Hours' icon={<JobListingHoursIcon />} value={workingHours} />
          </JobListingMeta>
        </HeroContainer>
      </HeroInnerWrapper>
    </HeroOuterWrapper>
  );
}
