import styled from "styled-components";
import { useRouter } from "next/router";
import ArrowToTopRight from "@/assets/svg/arrowToTopRight.svg";

const LabelLink = styled.button<{ $color?: string }>`
  color: ${(props) => props.$color};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  cursor: pointer;
  gap: 10px;
  padding: 0;
`;

const Label = styled.span`
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1.25rem;
  font-weight: 500;
`;

const ArrowContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: var(--PrimaryAccent);
  border-radius: 50%;
  svg {
    color: inherit;
    width: 9px;
  }
`;

export default function UpwardArrowCta({
  label,
  link,
  color,
  rawLink,
  rawLinkTarget,
  includeArrow,
  iconPosition = "hidden",
  icon,
  action,
}: {
  label: string;
  link?: any;
  color: string;
  rawLink?: any;
  includeArrow?: boolean;
  rawLinkTarget?: any;
  iconPosition?: "hidden" | "left" | "right";
  action?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactNode;
}) {
  const router = useRouter();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (action) {
      action(e);
    }
    if (rawLink) {
      if (rawLinkTarget === "_blank") {
        window.open(rawLink, "_blank");
        return;
      }
      router.push(rawLink);
    }
    if (link) {
      if (link.target === "_blank") {
        window.open(link.url, "_blank");
        return;
      }
      router.push(link.url);
    }
  };

  return (
    <LabelLink onClick={handleClick} $color={color}>
      <Label>{label}</Label>
      <ArrowContainer>
        <ArrowToTopRight />
      </ArrowContainer>
    </LabelLink>
  );
}
