import { apiPlugin } from "@storyblok/react";
import { useQuery } from "@tanstack/react-query";
import StoryblokClient from "storyblok-js-client";

// Define the type for a case study story
interface CaseStudyStory {
  id: string;
  name: string;
  // Add other relevant fields
}

// Define the type for the Storyblok API response
interface StoryblokResponse {
  stories: CaseStudyStory[];
  // Add other relevant fields from the response
}

// Initialize the Storyblok client
const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
});

// Function to fetch all case studies
const fetchAllCaseStudies = async (): Promise<CaseStudyStory[]> => {
  try {
    const response = await Storyblok.get("cdn/stories/", {
      version: process.env.NEXT_PUBLIC_PRODUCTION_SITE === "true" ? "published" : "draft",
      filter_query: {
        component: {
          in: "caseStudy",
        },
      },
    });

    const typedResponse = response.data as StoryblokResponse;
    return typedResponse.stories;
  } catch (error) {
    console.error("Error fetching case studies:", error);
    throw error;
  }
};

// Custom hook to use React Query for fetching all case studies
export const useAllCaseStudies = () => {
  return useQuery<CaseStudyStory[], Error>({
    queryKey: ["allCaseStudies"],
    queryFn: fetchAllCaseStudies,
  });
};
