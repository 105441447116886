import mq from "@/utils/mq";
import styled from "styled-components";
import React from "react";
import { css } from "styled-components";
import ContentWrapper from "@/components/Core/ContentWrapper";
import LibraryIcon from "@/assets/svg/library.svg";
import { render } from "storyblok-rich-text-react-renderer";

const HeroContainer = styled.div`
  background-color: #f3f4f6;
  width: 100%;
  padding: 84px 0px 46px 0px;

  ${mq.tablet(css`
    padding-top: 96px;
    padding-bottom: 26px;
  `)};
`;

const HeroWrapper = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
`;

const PillContainer = styled.div`
  border-radius: 30px;
  border: 1px solid #e8e8e8;
  background: #f7f8f9;
  display: flex;
  padding: 4px 18px;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-bottom: 12px;
  ${mq.tablet(css`
    margin-bottom: 18px;
  `)};
  ${mq.mobile(css`
    gap: 8px;
    padding: 2px 14px;
  `)};
`;

const PillText = styled.span`
  color: #09090b;
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  ${mq.mobile(css`
    font-size: 12px;
  `)};
`;

const PillIcon = styled.div`
  width: 14px;
  height: 14px;
  color: #09090b;
  ${mq.mobile(css`
    width: 11px;
    height: 11px;
  `)};
`;

const HeroTitle = styled.h1`
  color: #10181c;
  font-family: var(--font-family-inter);
  font-size: 42px;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: -0.96px;
  max-width: 640px;
  text-wrap: pretty;
  margin-bottom: 12px;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 28px;
    line-height: 36px;
  `)};
`;

const HeroDescription = styled.div`
  text-wrap: pretty;
  color: #677076;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  max-width: 768px;
  width: 100%;
  color: var(--PP-Text-Secondary, #677076);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  max-width: 822px;
  text-wrap: pretty;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 16px;
    line-height: 28px;
  `)};
`;

interface GenericHeroProps {
  title: string;
  bodyText: string;
  pillText: string;
  pillIcon?: React.ReactNode;
}

export default function GenericHero({ title, bodyText, pillText, pillIcon = <LibraryIcon /> }: GenericHeroProps) {
  return (
    <ContentWrapper backgroundColor='#F3F4F6'>
      <HeroContainer>
        <HeroWrapper>
          <PillContainer>
            <PillIcon>{pillIcon}</PillIcon>
            <PillText>{pillText}</PillText>
          </PillContainer>
          <HeroTitle>{title}</HeroTitle>
          <HeroDescription>{render(bodyText)}</HeroDescription>
        </HeroWrapper>
      </HeroContainer>
    </ContentWrapper>
  );
}
