import { useQuery } from "@tanstack/react-query";
import StoryblokClient from "storyblok-js-client";
import {
  JobPositionItemProps,
  EmploymentType,
  JobListingLocation,
  Salary,
} from "@/components/JobPositionsList/JobPositionsList"; // Import types

// Define the type for the Storyblok API response
interface StoryblokResponse {
  stories: StoryblokJobListing[];
  // Add other relevant fields from the response
}

// Define the type for Storyblok Job Listing
//This needs to align 1:1 with the JobPositionItemProps interface, PLUS full_slug
interface StoryblokJobListing {
  id: string;
  name: string;
  full_slug: string; //  Include full_slug
  content: {
    title: string;
    description: string;
    datePosted: string; // Storyblok dates are strings
    validThrough: string; // Storyblok dates are strings
    employmentType?: EmploymentType; // Make optional
    location?: JobListingLocation[]; //Make optional
    salary?: Salary[]; //Make optional
    workingHours?: string; // Make optional
    jobDescription?: string; // Make optional
    hubspotFormId?: string; // Make optional
    content?: any; // Make optional
    icon?: string; // Make optional
    component: string;
    _uid: string;
  };
}

// Add a new interface for the data returned by the hook
interface JobVacancyData {
  job: JobPositionItemProps;
  full_slug: string;
}

// Initialize the Storyblok client
const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
});

// Function to fetch all job listings
const fetchAllJobVacancies = async (): Promise<JobVacancyData[]> => {
  // Return JobVacancyData[]
  try {
    const response = await Storyblok.get("cdn/stories/", {
      version: process.env.NEXT_PUBLIC_PRODUCTION_SITE === "true" ? "published" : "draft",
      filter_query: {
        component: {
          in: "jobListing",
        },
      },
      per_page: 100, // Adjust as needed.
    });

    const typedResponse = response.data as StoryblokResponse;
    // Map StoryblokJobListing to { job: JobPositionItemProps, full_slug }
    return typedResponse.stories.map(
      (story): JobVacancyData => ({
        // Return JobVacancyData
        job: {
          title: story.content.title,
          description: story.content.description,
          datePosted: new Date(story.content.datePosted), // Convert to Date
          validThrough: new Date(story.content.validThrough), // Convert to Date
          employmentType: story.content.employmentType,
          location: story.content.location,
          salary: story.content.salary,
          workingHours: story.content.workingHours,
          jobDescription: story.content.jobDescription,
          hubspotFormId: story.content.hubspotFormId,
          icon: story.content.icon,
          content: story.content.content,
        },
        full_slug: story.full_slug, // Include full_slug
      }),
    );
  } catch (error) {
    console.error("Error fetching job vacancies:", error);
    throw error;
  }
};

// Custom hook to use React Query for fetching all job vacancies
export const useAllJobVacancies = () => {
  return useQuery<JobVacancyData[], Error>({
    // Return JobVacancyData[]
    queryKey: ["allJobVacancies"],
    queryFn: fetchAllJobVacancies,
  });
};
