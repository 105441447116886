import styled, { css } from "styled-components";
import { useMemo } from "react";

import LabelCta from "@/components/Buttons/LabelCta";
import ContentWrapper from "@/components/Core/ContentWrapper";
import CollectionStoryCard from "@/components/BlogCollectionSlider/CollectionStoryCard";
import mq from "@/utils/mq";
import BlogItemCard from "@/components/BlogRework/BlogItemCard";

const CollectionMoreItemsOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  padding-inline-start: 4rem;
  padding-inline-end: 4rem;
  ${mq.mobile(css`
    padding-inline-start: 0rem;
    padding-inline-end: 0rem;
  `)};
`;

const CollectionMoreItemsContainer = styled.div`
  padding: 84px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TopLayer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  ${mq.mobile(css`
    flex-wrap: wrap;
    gap: 16px;
  `)};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  margin: 0;
  color: #677076;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  span {
    color: var(--PP-Text-Primary);
  }
`;

const Subtitle = styled.p`
  color: #677076;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  @media (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
    align-items: flex-start;
  }
  ${mq.mobile(css`
    display: flex;
    justify-content: center;
  `)};
`;

const BlogPostCollectionMoreItems = ({ collection }) => {
  useMemo(() => {
    const filteredStories = collection?.content?.stories.filter(
      (story) => typeof story === "object" && "full_slug" in story,
    );
    collection.content.stories = filteredStories;
  }, [collection]);
  return (
    <CollectionMoreItemsOuterContainer>
      <CollectionMoreItemsContainer>
        <TopLayer>
          <TitleContainer>
            <Title>
              More from <span>{collection?.content?.title}</span>
            </Title>
            <Subtitle>Read more from this collection.</Subtitle>
          </TitleContainer>
          <LabelCta color='#497B55' label='See Collection' rawLink={"/" + collection?.full_slug} includeArrow />
        </TopLayer>
        <ContentContainer>
          {collection?.content?.stories[0] && <BlogItemCard story={collection.content.stories[0]} />}
          {collection?.content?.stories?.length > 1 && collection?.content?.stories[1] && (
            <BlogItemCard story={collection.content.stories[1]} />
          )}
          {collection?.content?.stories?.length > 2 && collection?.content?.stories[2] && (
            <BlogItemCard story={collection.content.stories[2]} />
          )}
        </ContentContainer>
      </CollectionMoreItemsContainer>
    </CollectionMoreItemsOuterContainer>
  );
};

export default BlogPostCollectionMoreItems;
