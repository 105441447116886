import BackgroundMedia from "@/components/Shared/BackgroundMedia";
import CtaRenderer from "@/components/Shared/CtaRenderer";
import MediaOverlay from "@/components/Shared/MediaOverlay";
import mq from "@/utils/mq";
import { themeProvider } from "@/utils/ThemeProvider";
import styled from "styled-components";
import { css } from "styled-components";

const HomepageHeroContainer = styled.div`
  padding: 0 60px;
  padding-top: 8px;
  @media (max-width: 1200px) {
    padding: 0 16px;
    padding-top: 24px;
  }
  @media (max-width: 1000px) {
    padding-top: 72px;
  }
  ${mq.mobile(css`
    padding: 0 8px;
    padding-top: 72px;
  `)};
`;

const HomepageHeroInnerContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 58px;
  border-radius: 22px;
  min-height: 700px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  @media (max-width: 1200px) {
    border-radius: 16px;
    padding: 36px;
  }
  ${mq.mobile(css`
    padding: 24px;
    border-radius: 0.75rem;
    max-height: 78svh;
    min-height: unset;
    height: 100vh;
    padding: 20px;
  `)};
`;

const HomepageHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 5;
  //gap: 16px;
  ${mq.mobile(css`
    gap: 8px;
  `)};
`;

const HomepageHeroTitle = styled.div`
  color: var(--Light100);
  font-family: var(--font-the-seasons);

  font-size: 56px;
  line-height: 62px;
  font-weight: 500;
  max-width: 600px;
  margin-bottom: 8px;
  h1 {
    font-size: 56px;
    line-height: 62px;
    font-weight: 500;
  }
  ${mq.mobile(css`
    font-size: 36px;
    line-height: 42px;
    max-width: 90%;
    margin-bottom: 0;
  `)};
`;

const HomepageHeroBodyText = styled.div`
  color: var(--Light300);
  font-family: var(--font-inter);
  font-weight: 450;
  font-size: 21px;
  line-height: 28px;
  max-width: 400px;
  margin-bottom: 28px;
  ${mq.mobile(css`
    font-size: 14px;
    line-height: 24px;
    max-width: 90%;
    margin-bottom: 8px;
  `)};
`;

const HomepageHeroCta = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  ${mq.mobile(css`
    flex-direction: column;
    margin-top: 8px;
  `)};
`;

const BackgroundMediaContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
`;

const HomepageHero = ({ title, bodyText, ctas, backgroundMedia, mediaOverlay, theme }) => {
  return (
    <HomepageHeroContainer style={{ backgroundColor: themeProvider(theme).backgroundColor }}>
      <HomepageHeroInnerContainer style={{ backgroundColor: themeProvider(theme).backgroundColor }}>
        <HomepageHeroContent>
          <HomepageHeroTitle>{title}</HomepageHeroTitle>
          <HomepageHeroBodyText>{bodyText}</HomepageHeroBodyText>
          <HomepageHeroCta>
            <CtaRenderer ctas={ctas} />
          </HomepageHeroCta>
        </HomepageHeroContent>
        <BackgroundMediaContainer>
          <MediaOverlay component={mediaOverlay} />
          <BackgroundMedia component={backgroundMedia} />
        </BackgroundMediaContainer>
      </HomepageHeroInnerContainer>
    </HomepageHeroContainer>
  );
};

export default HomepageHero;
