import BackgroundVideo from "@/components/Shared/BackgroundVideo";
import MediaOverlayGradient from "@/components/Shared/MediaOverlayGradient";
import React from "react";

export default function MediaOverlay({ component }) {
  if (!component || component?.length < 1 || !component[0].component) return null;

  if (component[0].component === "gradient") {
    return (
      <MediaOverlayGradient
        gradientColorOne={component[0].colorOne.color}
        gradientColorTwo={component[0].colorTwo.color}
        gradientDirection={component[0].direction}
        opacity={component[0].opacity.value}
      />
    );
  } else {
    return <div>BackgroundMedia</div>;
  }
}
