import { inter, ppNeueMontreal, theSeasons } from "@/utils/generateFonts";
import mq from "@/utils/mq";
import { css, createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --font-inter: ${inter.style.fontFamily};
    --font-pp-neue: ${ppNeueMontreal.style.fontFamily};
    --font-the-seasons: ${theSeasons.style.fontFamily};
  }

  ${mq.mobile(css`
    :root {
      --section-content-top-padding: 1rem;
      --section-content-bottom-padding: 3rem;
    }
  `)};

  html {
    font-family: var(--font-inter), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: var(--Light200);
  }
`;
