import styled, { css } from "styled-components";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Chevron from "@/assets/svg/chevronRight.svg";
import mq from "@/utils/mq";
import { useState } from "react";
import NextLazyImage from "@/components/Core/NextLazyImage";
import SectionContentWrapper from "@/components/Shared/SectionContentWrapper";

const FullWidthGallerySliderContainer = styled.div`
  overflow: hidden;
`;

const GallerySliderContainer = styled.div<{ $hasSectionTitle: boolean }>`
  position: relative;
  width: 100%;
  overflow: visible;

  ${({ $hasSectionTitle }) =>
    $hasSectionTitle &&
    css`
      padding-top: 3rem;
    `};
`;

const SwiperWrapper = styled.div`
  .swiper {
    overflow: visible;
  }
  .swiper-wrapper {
    align-items: center;
  }
`;

const GalleryItem = styled.div<{ $isActive: boolean }>`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  aspect-ratio: 16/10;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  ${mq.mobile(css`
    width: 100%;
    height: unset;
    margin: 0;
  `)};
`;

const NavigationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 42px;
  ${mq.mobile(css`
    margin-top: 24px;
  `)};
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  ${mq.mobile(css`
    gap: 6px;
  `)};
`;

const NavigationButton = styled.button`
  display: flex;
  width: 36px;
  min-width: 36px;
  min-height: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #e8e8eb;
  cursor: pointer;
  border: 0px;
  flex-shrink: 0;
  position: relative;
  color: var(PP-Text-Primary);
  ${mq.mobile(css`
    --size: 26px;
    width: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    height: var(--size);
    border-radius: 100%;
    padding: 0;
    border: 1px solid #e8e8eb;
  `)};
`;

const NavButtonIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(PP-Text-Primary);
  svg {
    width: 100%;
    color: var(PP-Text-Primary);
    height: 100%;
    min-width: 20px;
    min-height: 20px;
  }
  ${mq.mobile(css`
    svg {
      min-width: 14px;
      min-height: 14px;
    }
  `)};
`;

const ProgressBar = styled.div`
  position: relative;
  flex-grow: 1;
  height: 4px;
  background: #e8eaed;
  .swiper-pagination-progressbar-fill {
    background: var(--PrimaryAccent);
    height: 100%;
  }
`;

const ControlsWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  ${mq.mobile(css`
    padding: 0;
  `)};
`;

const FullWidthGallerySlider = ({
  images,
  theme,
  autoPlay,
  autoPlaySpeed,
  displayProgressBar,
  loop,
  slideAlignment,
  spaceBetweenSlides,
  displayControls,
  sectionTitle,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <FullWidthGallerySliderContainer>
      <SectionContentWrapper sectionTitle={sectionTitle} theme={theme}>
        <GallerySliderContainer $hasSectionTitle={sectionTitle && sectionTitle[0] ? true : false}>
          <SwiperWrapper>
            <Swiper
              spaceBetween={spaceBetweenSlides}
              slidesPerView='auto'
              centeredSlides={slideAlignment === "centred"}
              loop={loop}
              autoplay={
                autoPlay
                  ? {
                      delay: autoPlaySpeed * 1000, // Convert milliseconds to seconds
                      disableOnInteraction: false,
                    }
                  : false
              }
              modules={[
                ...(displayControls ? [Navigation] : []),
                ...(displayProgressBar ? [Pagination] : []),
                Scrollbar,
                A11y,
                Autoplay,
              ]}
              navigation={
                displayControls
                  ? {
                      prevEl: ".swiper-button-prev",
                      nextEl: ".swiper-button-next",
                    }
                  : false
              }
              pagination={
                displayProgressBar
                  ? {
                      el: ".swiper-pagination",
                      type: "progressbar",
                    }
                  : false
              }
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}>
              {images &&
                images?.length > 0 &&
                images?.map((image, i) => (
                  <SwiperSlide key={i} style={{ width: "100%", maxWidth: "1440px" }}>
                    <GalleryItem $isActive={i === activeIndex}>
                      <NextLazyImage
                        src={image.filename}
                        alt={image.alt || "Gallery slider image"}
                        layout='fullWidth'
                        style={{ objectFit: "cover" }}
                        quality={70}
                        width={1440}
                        height={840}
                      />
                    </GalleryItem>
                  </SwiperSlide>
                ))}
            </Swiper>
          </SwiperWrapper>
        </GallerySliderContainer>
        {(displayControls || displayProgressBar) && (
          <ControlsWrapper>
            <NavigationControls>
              {displayProgressBar && <ProgressBar className='swiper-pagination' />}
              {displayControls && (
                <NavigationButtons>
                  <NavigationButton className='swiper-button-prev'>
                    <NavButtonIcon style={{ transform: "rotate(180deg)" }}>
                      <Chevron color={"#10181c"} style={{ color: "#10181c" }} />
                    </NavButtonIcon>
                  </NavigationButton>
                  <NavigationButton className='swiper-button-next'>
                    <NavButtonIcon>
                      <Chevron color={"#10181c"} style={{ color: "#10181c" }} />
                    </NavButtonIcon>
                  </NavigationButton>
                </NavigationButtons>
              )}
            </NavigationControls>
          </ControlsWrapper>
        )}
      </SectionContentWrapper>
    </FullWidthGallerySliderContainer>
  );
};

export default FullWidthGallerySlider;
