import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import SectionPill from "@/components/Shared/SectionPill";
import dynamic from "next/dynamic";
import styled from "styled-components";
import FactoidCards from "@/components/SustainabilityHero/FactoidCards";

const UnderlineAnimation = dynamic(() => import("@/components/Shared/UnderlineAnimation"), { ssr: false });

const SustainabilityHeroContainer = styled.header`
  display: flex;
  flex-direction: column;
`;

const TitleSection = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  margin-inline: auto;
`;

const PreTitle = styled.div``;

const Title = styled.h1`
  font-size: 80px;
  font-weight: 600;
  line-height: 1;
  font-family: var(--font-inter);
  color: var(--PP-Text-Primary);
  text-align: center;
  text-wrap: pretty;
  margin-top: 2rem;
`;

const ParagraphSection = styled.div`
  max-width: 650px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 128px;
`;

const svgUnderline = `
<svg width="705" height="22" viewBox="0 0 705 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M30.9961 14.6468C36.7554 11.0157 37.1565 10.8484 82.6326 10.0462C130.856 9.19579 343.653 3.40842 674.132 11.9972" stroke="#1CD956" stroke-width="28.9051" stroke-linecap="round"/>
</svg>
`;

const encodedSvg = encodeURIComponent(svgUnderline);

const Paragraph = styled.div`
  text-align: center;
  text-wrap: pretty;
  font-size: 26px;
  line-height: 34px;
  font-family: var(--font-inter);
  font-weight: 600;
  color: var(--PP-Text-Primary);

  span {
    position: relative;
    display: inline-block;
  }
`;

const AnimatedSpan = styled.span`
  position: relative;
  display: inline-block;
`;

const SpacerDiv = styled.div`
  height: 0vh;
`;

export default function SustainabilityHero({ factoidCards }) {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <SustainabilityHeroContainer>
      <TitleSection>
        <SectionPill label={"Greenplan"} />
        <Title>A plan as green as our products.</Title>
      </TitleSection>

      <ParagraphSection>
        <Paragraph>
          It should go without saying, our love of nature is a pretty compelling reason to look after the planet.
          <AnimatedSpan>
            GreenPlan
            <UnderlineAnimation />
          </AnimatedSpan>{" "}
          is our vision for contribution.
        </Paragraph>
        <Paragraph>
          {
            "By focusing on renewable energy, recycled planters, and clean maintenance. we're working to bring our net emissions"
          }
          <AnimatedSpan>
            {"down to zero"}
            <UnderlineAnimation />
          </AnimatedSpan>
          .
        </Paragraph>
        <Paragraph>{"We're making great steps forward and sharing our journey here."}</Paragraph>
      </ParagraphSection>
      <FactoidCards factoidCards={factoidCards} />
      <SpacerDiv />
    </SustainabilityHeroContainer>
  );
}
