import React from "react";
import styled, { css } from "styled-components";
import ListIcon from "@/assets/svg/list.svg";
import mq from "@/utils/mq";

const KeyTakeAwaysOuterWrapper = styled.div`
  width: 110%;
  display: flex;
  justify-content: flex-start;
  outline: none;
  margin-bottom: 2.5rem;
  padding-right: 7rem;

  margin-left: -5%;
  @media (max-width: 1000px) {
    padding-right: 0;
    margin-left: 0;
    width: 100%;
  }
  ${mq.mobile(css`
    width: 100%;
    margin-left: 0;
    padding-right: 0;
  `)};
`;

const KeyTakeAwaysWrapper = styled.div`
  border: 1px solid #e2e2e2;
  background: #f6f6f6;
  border-radius: 10px;
  padding: 24px;
  position: relative;
  ${mq.mobile(css`
    padding: 16px;
  `)};
`;

const Title = styled.h2`
  color: #09090b;
  letter-spacing: -0.00666rem;
  font-family: var(--font-inter);

  line-height: 1.3333333333;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 0.65rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: 1.45rem;
  color: #09090b;
  span {
    color: #52525c;
  }
  b {
    font-weight: 600;
  }
`;
const Items = styled.ul`
  padding-left: 30px;
  margin-bottom: 0;
  margin-top: 0;
  box-sizing: border-box;
  list-style-type: disc;
`;

const Item = styled.li`
  text-wrap: balance;
  margin-bottom: 16px;
  font-family: var(--font-inter);
  font-weight: 400;
  letter-spacing: -0.00666rem;
  line-height: 1.8;
  p,
  span {
    color: #52525c;
  }
  b {
    color: #09090b;
  }
`;

const InfoIconWrapper = styled.div`
  position: absolute;
  display: flex;
  top: -24px;
  right: -16px;
  border-radius: 50%;
  padding: 6px;
  color: #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid #f9fafb;
  background: #497b55;
`;

const InfoIconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function BlogKeyTakeAways({ title, items }: { title: string; items: any[] }) {
  const leadingTextRenderer = (text: string) => {
    if (text[text.length - 1] === " ") return text;
    return text + " ";
  };

  return (
    <KeyTakeAwaysOuterWrapper>
      <KeyTakeAwaysWrapper>
        <InfoIconWrapper>
          <InfoIconContainer>
            <ListIcon />
          </InfoIconContainer>
        </InfoIconWrapper>
        <Title>{title}</Title>
        <Items>
          {items?.map((item) => (
            <Item key={item._uid}>
              <p>
                <b>{leadingTextRenderer(item.leadingText)}</b>
                {item.content}
              </p>
            </Item>
          ))}
        </Items>
      </KeyTakeAwaysWrapper>
    </KeyTakeAwaysOuterWrapper>
  );
}
