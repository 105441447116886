interface ValidateUrlResult {
  isValid: boolean;
  normalizedUrl: string;
  errors: string[];
}

export function validateAndNormalizeUrl(url: string): ValidateUrlResult {
  const errors: string[] = [];
  let normalizedUrl = url.trim();

  // Check if URL starts with http:// or https://
  if (!normalizedUrl.match(/^https?:\/\//)) {
    normalizedUrl = `https://${normalizedUrl}`;
  }

  try {
    const urlObj = new URL(normalizedUrl);

    // Check for double slashes in path (excluding protocol://)
    if (urlObj.pathname.includes("//")) {
      errors.push("URL contains double slashes in path");
    }

    // Check for missing trailing slash after TLD
    if (urlObj.pathname === "" && !normalizedUrl.endsWith("/")) {
      normalizedUrl = `${normalizedUrl}/`;
    }

    // Remove any duplicate slashes in the path
    const cleanPath = urlObj.pathname.replace(/\/+/g, "/");
    normalizedUrl = `${urlObj.origin}${cleanPath}${urlObj.search}${urlObj.hash}`;

    return {
      isValid: errors.length === 0,
      normalizedUrl,
      errors,
    };
  } catch (error) {
    return {
      isValid: false,
      normalizedUrl: url,
      errors: ["Invalid URL format"],
    };
  }
}
