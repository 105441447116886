import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";
import { motion } from "motion/react";
import PlusIcon from "@/assets/svg/plus.svg";
import { useState } from "react";

const FaqItemWrapper = styled.div`
  padding: 1.5rem 0;
  border-bottom: 1px solid #e7e8eb;
`;

const Question = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  outline: none;
  cursor: pointer;
  border: 0px solid transparent;
  text-align: left;
  background: transparent;
  padding: 0;
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const QuestionText = styled.span`
  font-size: 1.1rem;
  line-height: 1.25;
  letter-spacing: 0.00625em;
  padding-right: 1.5rem;
  flex: 1;
  color: #52525c;
`;

const QuestionButton = styled(motion.div)<{ $isExpanded: boolean }>`
  width: 28px;
  height: 28px;
  white-space: nowrap;
  border: none;
  color: #09090b;
  outline: none;
  box-shadow:
    0px 1px 2px 0px rgba(9, 9, 11, 0.12),
    0px 0px 0px 1px rgba(9, 9, 11, 0.08);
  background: #fff;
  border-radius: 0.375rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 100%;
    width: 100%;
  }
`;

const AnswerWrapper = styled(motion.div)`
  overflow: hidden;
`;

const Answer = styled.div`
  padding-top: 0.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding-bottom: 0.5rem;
  font-family: var(--font-inter);
  font-weight: 400;
  letter-spacing: -0.00666rem;
  font-size: 1rem;
  line-height: 1.8;
  max-width: 90%;
  padding-left: 0;
  * {
    color: #52525c !important;
  }
`;

export default function BlogFaqItem({ blok }: { blok: any }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <FaqItemWrapper>
      <Question onClick={toggleExpanded}>
        <QuestionText>{blok.question}</QuestionText>
        <QuestionButton
          $isExpanded={isExpanded}
          animate={isExpanded ? { background: "#4a7b55", color: "#fff" } : { background: "#fff", color: "#09090b" }}
          initial={{ rotate: 0 }}
          transition={{ duration: 0.6, ease: [0.87, 0, 0.13, 1] }}>
          <motion.div
            animate={isExpanded ? { rotate: 45 } : { rotate: 0 }}
            initial={{ rotate: 0 }}
            transition={{ duration: 0.6, ease: [0.87, 0, 0.13, 1] }}>
            <PlusIcon />
          </motion.div>
        </QuestionButton>
      </Question>
      <AnswerWrapper
        animate={isExpanded ? { height: "fit-content" } : { height: 0 }}
        transition={{ duration: 0.6, ease: [0.87, 0, 0.13, 1] }}>
        <Answer>{render(blok.answer)}</Answer>
      </AnswerWrapper>
    </FaqItemWrapper>
  );
}
