import React from "react";
import styled from "styled-components";
import { motion } from "motion/react";

import VideoPlayer from "@/components/Shared/VideoPlayer";
import Popover from "@/components/Shared/Popover";
import LabelCta from "@/components/Buttons/LabelCta";
import PlayIcon from "@/assets/svg/playIcon.svg";
import CloseIcon from "@/assets/svg/closeIcon.svg";

import GlassButton from "@/components/Buttons/GlassButton";

const PlayButtonContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 24px;
  z-index: 6;
`;

const PopoverVideoPlayerContainer = styled.div`
  width: 800px;
  max-width: 80%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const PopoverVideoIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -32px;
  z-index: 550;
  height: 18px;
`;

export default function StoryItemPopoverHandler({
  enablePopoverVideo,
  id,
  active,
  popoverVideoFile,
  popoverMediaActive,
  setPopoverMediaActive,
}) {
  const playButtonAnim = {
    hidden: {
      opacity: 0,
      x: 5,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.35,
      },
    },
  };

  return (
    <>
      {enablePopoverVideo && popoverVideoFile[0] && (
        <PopoverVideoProvider
          id={"caseStudyPopoverVideo-" + id}
          popoverActive={popoverMediaActive}
          setPopoverActive={setPopoverMediaActive}
          popoverVideoFile={popoverVideoFile[0]}
        />
      )}
      {enablePopoverVideo && (
        <PlayButtonContainer
          animate={active ? "visible" : "hidden"}
          initial={active ? "visible" : "hidden"}
          variants={playButtonAnim}>
          <GlassButton
            label='Play'
            style='ghost'
            iconPosition='left'
            icon={<PlayIcon />}
            action={() => setPopoverMediaActive(true)}
          />
        </PlayButtonContainer>
      )}
    </>
  );
}

const PopoverVideoProvider = ({ popoverVideoFile, popoverActive, setPopoverActive, id }) => {
  return (
    <Popover popoverActive={popoverActive} setPopoverActive={setPopoverActive} id={id}>
      <PopoverVideoPlayerContainer>
        <PopoverVideoIconContainer>
          <LabelCta
            color='#ffffffb6'
            label='Close'
            iconPosition='left'
            icon={<CloseIcon />}
            action={() => setPopoverActive(false)}
          />
        </PopoverVideoIconContainer>
        <VideoPlayer
          posterSrc={popoverVideoFile?.poster?.filename}
          posterAlt={popoverVideoFile?.poster?.alt}
          videoSrc={popoverVideoFile?.videoFile?.filename}
          videoAlt={popoverVideoFile?.videoFile?.alt}
          pause={!popoverActive}
        />
      </PopoverVideoPlayerContainer>
    </Popover>
  );
};
