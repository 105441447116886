// types/index.ts

/**
 * Interface for video objects
 */
export interface Video {
  title: string;
  description: string;
  publishDate: string;
  videoLink: string;
  lengthMinutes: number;
  lengthSeconds: number;
  thumbnail: Image;
  poster: Image;
  preview: VideoObject;
  category?: string;
  full_slug?: string;
}

/**
 * Interface for image assets
 */
export interface Image {
  filename: string;
  alt: string;
}

/**
 * Interface for video file assets
 */
export interface VideoObject {
  filename: string;
  alt: string;
}

/**
 * Interface for video category
 */
export interface VideoCategory {
  id: number | string;
  name: string;
  value: string | null;
  dimension_value?: string | null;
}

/**
 * Video player state type
 */
export interface VideoPlayerState {
  isOpen: boolean;
  playBackgroundVideo: boolean;
  shouldAutoPlay: boolean;
  filterCategory: string | null;
}
