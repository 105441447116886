import Head from "next/head";
import React from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { renderToString } from "react-dom/server";

interface FaqItem {
  question: string;
  answer: {
    type: "doc";
    content: any[];
  };
}

interface FaqJsonLdProps {
  data: FaqItem[];
}

const FaqJsonLd: React.FC<FaqJsonLdProps> = ({ data }) => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: data?.map((faqItem) => ({
      "@type": "Question",
      name: faqItem.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: renderToString(render(faqItem.answer)),
      },
    })),
  };

  return (
    <Head>
      <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
    </Head>
  );
};

export default FaqJsonLd;
