import ResourceCentreContent from "@/components/ResourceCentre/ResourceCentreContent";
import ResourceCentreHero from "@/components/ResourceCentre/ResourceCentreHero";
import SectorsHero from "@/components/SectorsHero";
import BreadcrumbJsonLd from "@/components/Shared/JsonLD/BreadcrumbJsonLd";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import { generateSeoBreadcrumbs } from "@/utils/generateSeoBreadcrumbs";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import styled from "styled-components";
import React from "react";

const ResourceCentreContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function StoryblokResourceCentre({ blok }) {
  const { heroTitle, heroDescription, resources, heroLabel, resourceTypes } = blok;
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_ROOT_DOMAIN || "https://www.plantplan.co.uk/";
  const [selectedFileTypes, setSelectedFileTypes] = React.useState<string[]>(["all"]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>(["all"]);
  const [searchQuery, setSearchQuery] = React.useState("");

  const filteredResources = React.useMemo(() => {
    return resources.filter((resource) => {
      // File type filter
      const matchesFileType =
        selectedFileTypes.includes("all") ||
        selectedFileTypes.some((type) => resource?.fileType?.toLowerCase() === type.toLowerCase());

      // Tag filter
      const matchesTag =
        selectedTags.includes("all") ||
        selectedTags.some(
          (selectedTag) => Array.isArray(resource?.tags) && resource.tags.some((tag) => tag?.slug === selectedTag),
        );

      // Search filter
      const matchesSearch = searchQuery === "" || resource?.title?.toLowerCase().includes(searchQuery.toLowerCase());

      return matchesFileType && matchesTag && matchesSearch;
    });
  }, [resources, selectedFileTypes, selectedTags, searchQuery]);

  return (
    <ResourceCentreContainer>
      {blok.breadcrumbs && blok.breadcrumbs.length > 0 && (
        <BreadcrumbJsonLd
          items={generateSeoBreadcrumbs({
            breadcrumbs: blok.breadcrumbs,
            baseUrl,
            currentPath: router.asPath,
            pageTitle: blok.title,
          })}
          baseUrl={baseUrl}
        />
      )}
      <NextSeo additionalMetaTags={[{ name: "keywords", content: blok?.keywords?.value?.join(", ") }]} />
      {/* <SectorsHero title={blok.heroTitle} description={blok.heroDescription} /> */}
      {/* {blok.body && blok.body.length > 0 && <BlokRenderer blok={blok} key={blok?.slug} />} */}
      <ResourceCentreHero
        title={heroTitle}
        description={heroDescription}
        pillText={heroLabel}
        resourceTypes={resourceTypes}
        onFileTypeChange={setSelectedFileTypes}
        onTagChange={setSelectedTags}
        onSearchChange={setSearchQuery}
      />
      <ResourceCentreContent resources={filteredResources} />
    </ResourceCentreContainer>
  );
}
