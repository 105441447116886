import components from "@/lib/storyblok/components";
import { apiPlugin } from "@storyblok/react";
import { useQuery } from "@tanstack/react-query";
import StoryblokClient from "storyblok-js-client";

// Define the type for a blog collection story
interface BlogCollectionStory {
  id: string;
  name: string;
  // Add other relevant fields
}

// Define the type for the Storyblok API response
interface StoryblokResponse {
  stories: BlogCollectionStory[];
  // Add other relevant fields from the response
}

// Initialize the Storyblok client
const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
});

// Function to fetch all blog collections
const fetchAllCollections = async (): Promise<BlogCollectionStory[]> => {
  try {
    const response = await Storyblok.get("cdn/stories/", {
      version: process.env.NEXT_PUBLIC_PRODUCTION_SITE === "true" ? "published" : "draft",
      filter_query: {
        component: {
          in: "blogCollection",
        },
      },
      per_page: 100,
    });

    const typedResponse = response.data as StoryblokResponse;
    return typedResponse.stories;
  } catch (error) {
    console.error("Error fetching blog collections:", error);
    throw error;
  }
};

// Custom hook to use React Query for fetching all collections
export const useAllBlogCollections = () => {
  return useQuery<BlogCollectionStory[], Error>({
    queryKey: ["allBlogCollections"],
    queryFn: fetchAllCollections,
  });
};
