import { DefaultTheme } from "styled-components";

interface ThemeColor {
  L100: string;
  D30: string;
  sage: {
    Base: any;
    100: any;
    200: any;
    300: any;
    400: any;
    600: any;
    700: any;
    800: any;
    900: any;
  };
  night: {
    100: any;
    200: any;
    300: any;
    400: any;
    base: any;
    600: any;
    700: any;
    800: any;
    900: any;
  };
  zest: {
    100: any;
    200: any;
    300: any;
    400: any;
    base: any;
    600: any;
    700: any;
    800: any;
    900: any;
  };
  greyscale: {
    100: any;
    200: any;
    300: any;
    400: any;
    base: any;
    600: any;
    700: any;
    800: any;
    900: any;
    black: any;
    white: any;
  };
}

interface ThemeTypography {
  displayHeading: {
    L0: any;
    L1: any;
    L2: any;
  };
  heading: {
    H1: any;
    H2: any;
    H3: any;
    H4: any;
  };
  headline: {
    L1: any;
    L2: any;
    H1: any;
    H2: any;
    H3: any;
    H4: any;
    L0: any;
  };
  body: {
    Lead1: any;
    Lead2: any;
    Body1: any;
    Body2: any;
    LinkBody: any;
    Caption1: any;
    Caption2: any;
    LinkCaption: any;
  };
}

interface Theme {
  color: ThemeColor;
  typography: ThemeTypography;
}

// Extend the DefaultTheme from styled-components
declare module "styled-components" {
  export interface DefaultTheme extends Theme {}
}

const theme: DefaultTheme = {
  color: {
    L100: "#F4F6FF",
    D30: "#1A349C",
    sage: {
      Base: "#88AAA6",
      100: "#E7EEED",
      200: "#D0DDDB",
      300: "#B8CCCA",
      400: "#A0BBB8",
      600: "#6D8885",
      700: "#526664",
      800: "#374442",
      900: "#1B2221",
    },
    night: {
      100: "#D7D9EE",
      200: "#AFB2DD",
      300: "#868CCD",
      400: "#5E65BC",
      base: "#363FAB",
      600: "#2B3289",
      700: "#202667",
      800: "#161944",
      900: "#0B0D22",
    },
    zest: {
      100: "#FAEADA",
      200: "#F5D4B6",
      300: "#EFBF91",
      400: "#EAA96D",
      base: "#E59448",
      600: "#B7763A",
      700: "#89592",
      800: "#5C3B1D",
      900: "#5C3B1D",
    },
    greyscale: {
      100: "#ECEEEE",
      200: "#DADDDD",
      300: "#C7CCCB",
      400: "#B4BBBA",
      base: "#A1AAA9",
      600: "#818887",
      700: "#616665",
      800: "#414444",
      900: "#1B2221",
      black: "#000000",
      white: "#FFFFFF",
    },
  },
  typography: {
    displayHeading: {
      L0: {
        fontWeight: 500,
        fontSize: "4rem",
        lineHeight: "104%",
        letterSpacing: "-0.03rem",
        "@media (max-width: 1260px)": {
          fontSize: "3rem",
        },
        "@media (max-width: 1200px)": {
          fontSize: "3rem",
        },
      },
      L1: {
        fontSize: "44px",
        fontWeight: 300,
        lineHeight: "56px",
      },
      L2: {
        fontSize: "34px",
        fontWeight: 500,
        lineHeight: "40px",
        letterSpacing: "-0.5px",
      },
    },
    headline: {
      L0: {
        fontSize: "72px",
        fontWeight: 500,
        lineHeight: "84px",
      },
      L1: {
        fontSize: "44px",
        fontWeight: 300,
        lineHeight: "56px",
      },
      L2: {
        fontSize: "34px",
        fontWeight: 500,
        lineHeight: "40px",
        letterSpacing: "-0.5px",
      },
      H1: {
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "32px",
      },
      H2: {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
      },
      H3: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "24px",
      },
      H4: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "22px",
      },
    },
    heading: {
      H1: {
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "32px",
      },
      H2: {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
      },
      H3: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "24px",
      },
      H4: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "22px",
      },
    },
    body: {
      Lead1: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
      },
      Lead2: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "22px",
      },
      Body1: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
      },
      Body2: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
      },
      LinkBody: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        textDecorationLine: "underline",
      },
      Caption1: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16px",
      },
      Caption2: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "16px",
      },
      LinkCaption: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16px",
        textDecorationLine: "underline",
      },
    },
  },
};

export default theme;

export type { DefaultTheme };
