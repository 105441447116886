import styled, { css } from "styled-components";
import { BREAKPOINT_SM } from "@/constants";
import mq from "@/utils/mq";

const OuterContainer = styled.aside`
  width: 100%;
`;

const RichTextContainer = styled.div`
  max-width: calc(900px + 3rem);
  width: 100%;
  margin: 0 auto;
  color: var(--PP-Text-Primary, #10181c);
  font-family: var(--font-inter);
  word-break: break-word;
  padding-bottom: 2.5rem;
  display: flow-root;
  ${mq.mobile(css`
    padding-bottom: 1.5rem;
  `)};
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: ${BREAKPOINT_SM}px) {
    max-width: calc(900px + 4.5rem);
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  a {
    text-decoration: underline;
    font-weight: 500;
    //offset underline
    text-underline-offset: 2px;
    color: unset;
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding: 0;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  line-height: 32px;
`;

const TableOfContents = styled.ul`
  list-style: none;
  padding: 0;
  border-left: 1px solid var(--PP-Text-Secondary, #475569);
`;

const Item = styled.li`
  padding: 12px 16px;
  border-left: 1px solid #000;
`;

export default function BlogTableOfContents({ items }) {
  return (
    <OuterContainer>
      <RichTextContainer>
        <Title>Table of contents</Title>
        <TableOfContents>
          {items?.map((item, i) => (
            <Item key={item.id}>
              <a href={`#${item.id}`} key={item.id}>
                {item.customLabel && item.customLabel !== "" ? item.customLabel : item.title}
              </a>
            </Item>
          ))}
        </TableOfContents>
      </RichTextContainer>
    </OuterContainer>
  );
}
