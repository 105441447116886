import cssModule from "@vercel/turbopack-next/internal/font/local/cssmodule.module.css?{%22path%22:%22generateFonts.ts%22,%22import%22:%22%22,%22arguments%22:[{%22src%22:[{%22path%22:%22../assets/fonts/theseasons-reg.otf%22,%22weight%22:%22400%22,%22style%22:%22normal%22}],%22display%22:%22swap%22,%22variable%22:%22--font-the-seasons%22}],%22variableName%22:%22theSeasons%22}";
const fontData = {
    className: cssModule.className,
    style: {
        fontFamily: "'theSeasons', 'theSeasons Fallback'",
        
    },
};

if (cssModule.variable != null) {
    fontData.variable = cssModule.variable;
}

export default fontData;
