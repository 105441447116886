import styled from "styled-components";
import Button from "@/components/Buttons/Button";
import LabelCta from "@/components/Buttons/LabelCta";
import parseStoryblokLink from "@/utils/parseStoryblokLink";

const CtaCollection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

export default function CtaRenderer({ ctas }) {
  if (!ctas || ctas?.length < 1) return null;
  return <>{ctas?.map((cta, i) => <RenderCta key={i} cta={cta} />)}</>;
}

const RenderCta = ({ cta }) => {
  if (cta.component === "button") {
    return (
      <Button
        label={cta.label}
        link={parseStoryblokLink(cta.link)}
        target={cta.link.target}
        labelColor={cta?.color?.color}
        style={cta.style}
        iconPosition={cta.iconPosition}
        icon={cta?.icon && cta?.icon?.length > 0 && cta.icon}
        enableArrow={cta.enableArrow}
        contentWall={cta.contentWall}
      />
    );
  }
  if (cta.component === "ctaLabel") {
    return (
      <LabelCta
        label={cta.label}
        color={cta?.color?.color}
        theme={cta?.theme}
        link={cta.link}
        includeArrow={cta.includeArrow}
        iconPosition={cta.iconPosition}
        icon={cta?.icon && cta?.icon?.length > 0 && cta.icon}
        colorOverride={cta.colorOverride}
      />
    );
  }
};
