import NextLazyImage from "@/components/Core/NextLazyImage";
import { MediaPlayer, Poster, MediaProvider } from "@vidstack/react";
import { useState, useRef } from "react";
import styled from "styled-components";
import { createScanner } from "typescript";

const VideoPlayerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  * {
    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    [data-media-player] {
      --player-width: auto;
      --media-width: auto;
    }
  }
`;

export default function VideoPlayer({
  posterSrc,
  posterAlt,
  videoSrc,
  videoAlt,
  playerStyles,
  pause = false,
  blurVideo = false,
  containerStyles,
}: {
  posterSrc: string;
  posterAlt: string;
  videoSrc: string;
  videoAlt: string;
  pause?: boolean;
  blurVideo?: boolean;
  playerStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
}) {
  const videoPlayer = useRef(undefined);

  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <VideoPlayerContainer style={{ ...containerStyles }}>
      <MediaPlayer
        title={videoAlt}
        ref={videoPlayer}
        style={{ width: "100%", height: "100%", objectFit: "cover", ...playerStyles }}
        paused={pause}
        onPlaying={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onEnded={() => setIsPlaying(false)}
        controls={true}
        loop={false}
        autoPlay
        volume={1}
        src={videoSrc}>
        <MediaProvider />
        {!isPlaying && !pause && <Poster src={posterSrc} alt={posterAlt} />}
      </MediaPlayer>
    </VideoPlayerContainer>
  );
}
