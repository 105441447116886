import FullWidthGallerySlider from "./FullWidthGallerySlider";

const FullWidthGallerySliderWithProps = ({ blok }) => {
  return (
    <FullWidthGallerySlider
      sectionTitle={blok.sectionTitle}
      images={blok.images}
      theme={blok.theme}
      autoPlay={blok.autoPlay}
      autoPlaySpeed={blok.autoPlaySpeed}
      displayProgressBar={blok.displayProgressBar}
      displayControls={blok.displayControls}
      loop={blok.loop}
      slideAlignment={blok.slideAlignment}
      spaceBetweenSlides={blok.spaceBetweenSlides}
    />
  );
};

export default FullWidthGallerySliderWithProps;
