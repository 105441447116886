import IconRenderer from "@/components/Shared/IconRenderer";
import mq from "@/utils/mq";
import { useState } from "react";
import styled from "styled-components";
import { css } from "styled-components";

const ResultItemWrapper = styled.div<{ $highlighted: boolean; $isHovered: boolean }>`
  display: flex;
  padding: 12px 14px 12px 12px;
  margin: 4px 0;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.1s var(--ease-in-out-expo);
  cursor: pointer;
  user-select: none;
  max-width: 100%;
  ${(props) =>
    (props.$highlighted || props.$isHovered) &&
    css`
      transition: all 0.1s var(--ease-in-out-expo);
      background: #f5f5f5;
    `};
  ${mq.mobile(css`
    max-width: 100%;
    width: 100%;
  `)};
`;

const ResultMeta = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
`;

const ResultIcon = styled.div<{ $highlighted: boolean; $isHovered: boolean }>`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  transition: all 0.1s var(--ease-in-out-expo);
  ${(props) =>
    (props.$highlighted || props.$isHovered) &&
    css`
      transition: all 0.1s var(--ease-in-out-expo);
      background: var(--PrimaryAccent);
    `};
  svg {
    stroke-width: 1.25 !important;
  }
`;

const ResultMetaContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResultLabel = styled.div`
  color: #000;
  font-family: var(--font-inter);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: var(--PP-Text-Primary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.1px;
  margin-bottom: 2px;
`;

const ResultDescription = styled.div`
  color: var(--PP-Text-Secondary);
  font-family: var(--font-inter);
  font-size: 13px;
  letter-spacing: 0.1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const KeyboardIndicator = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 0.5px solid rgba(153, 153, 153, 0.35);
  background: #fafafa;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  font-size: 12px;
  line-height: 12px;
  font-style: normal;
  font-weight: 400;
  padding-top: 4px;
`;

const KeyboardLabel = styled.div``;

export default function PinpointResultItem({ item, highlightedIndex, index, selectedItem, onSelect, closeMenu }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <ResultItemWrapper
      $highlighted={highlightedIndex === index}
      $isHovered={isHovered}
      onClick={() => {
        onSelect(item);
        closeMenu();
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <ResultMeta>
        <ResultIcon $highlighted={highlightedIndex === index} $isHovered={isHovered}>
          <IconRenderer
            icon={item.content.icon}
            size={20}
            color={highlightedIndex === index || isHovered ? "#fff" : "#000"}
          />
        </ResultIcon>
        <ResultMetaContent>
          <ResultLabel>{item.content.menuTitle ? item.content.menuTitle : `${item.name}*`}</ResultLabel>
          <ResultDescription>
            {item.content.menuDescription ? item.content.menuDescription : "Missing Description"}
          </ResultDescription>
        </ResultMetaContent>
      </ResultMeta>
      {highlightedIndex === index && (
        <KeyboardIndicator>
          <KeyboardLabel>↵</KeyboardLabel>
        </KeyboardIndicator>
      )}
    </ResultItemWrapper>
  );
}
