import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Marquee from "react-fast-marquee";

import ContentWrapper from "@/components/Core/ContentWrapper";
import { themeProvider } from "@/utils/ThemeProvider";
import { BREAKPOINT_SM, BREAKPOINT_LG } from "@/constants";
import mq from "@/utils/mq";

const StyledMarquee = styled(Marquee)`
  position: relative;
  overflow: hidden;
  padding: 5rem 0;
  ${mq.mobile(css`
    padding: 2.5rem 0;
  `)};
`;

const Logo = styled.img`
  --height: 32px;
  height: var(--height);
  max-height: var(--height);
  object-fit: contain;
  overflow: hidden;
  color: #fff;

  margin: 0px 38px;
  svg {
    color: #677076;
  }
  ${mq.mobile(css`
    --height: 24px;
    margin: 0px 22px;
  `)};
`;

export default function LogoMarquee({
  logos,
  theme,
  speed,
  enableGradientFade,
  autoFill,
  direction,
  delay,
  pauseOnHover,
  pauseOnClick,
  gradientFadeWidth,
}) {
  const fullGradientWidth = gradientFadeWidth?.value * 10;
  const [currentGradientWidth, setCurrentGradientWidth] = useState(fullGradientWidth);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= BREAKPOINT_SM) {
        setCurrentGradientWidth(46); // One quarter for mobile
      } else if (window.innerWidth <= BREAKPOINT_LG) {
        setCurrentGradientWidth(fullGradientWidth / 2); // Half for tablet
      } else {
        setCurrentGradientWidth(fullGradientWidth); // Full width for larger screens
      }
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [fullGradientWidth]);

  return (
    <ContentWrapper theme={theme} noMobilePadding>
      <StyledMarquee
        autoFill={autoFill}
        speed={speed.value * 2}
        gradient={enableGradientFade}
        gradientWidth={currentGradientWidth}
        gradientColor={themeProvider(theme).backgroundColor}
        direction={direction}
        delay={delay}
        pauseOnHover={pauseOnHover}
        pauseOnClick={pauseOnClick}>
        {logos?.map((logo, i) => {
          return <Logo src={logo.filename} alt={logo.alt} key={i} />;
        })}
      </StyledMarquee>
    </ContentWrapper>
  );
}
