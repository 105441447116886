import { useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "motion/react";

import StoryItemDetailsHandler from "@/components/CaseStudyStoriesSlider/CaseStudyStoryItem/StoryItemDetailsHandler";
import StoryItemMediaProvider from "@/components/CaseStudyStoriesSlider/CaseStudyStoryItem/StoryItemMediaProvider";
import mq from "@/utils/mq";

const StoryContainer = styled(motion.div)<{ $storyCount: number }>`
  padding: 0px;
  width: 100%;
  overflow: hidden;
  flex-shrink: 1;
  cursor: pointer;
  max-width: 680px;
`;

export default function CaseStudyStoryItem({
  id,
  brandName,
  brandImage,
  tags,
  storyCount,
  backgroundMedia,
  backgroundOverlay,
  title,
  description,
  caseStudyLink,
  active,
  enablePopoverVideo,
  popoverVideoFile,
  onComplete,
  slideTimerIncrementSpeed,
  slowProgressionOnHover,
  onClick,
  activeStoryWidth,
  setActiveStoryWidth,
  hasStoryWidth,
  setHasStoryWidth,
}) {
  const [popoverMediaActive, setPopoverMediaActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [count, setCount] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const intervalRef = useRef(null);
  const mediaContainerRef = useRef(null);
  const handlePauseResume = (e) => {
    e.stopPropagation();
    setIsPaused(!isPaused);
  };

  //This is used to set the width of the container, both on initial load and on resize
  useEffect(() => {
    const updateStoryWidth = () => {
      if (mediaContainerRef.current && active) {
        setActiveStoryWidth(mediaContainerRef.current.offsetWidth);
        setHasStoryWidth(true);
      }
    };

    const handleResize = () => {
      if (active) {
        updateStoryWidth();
      }
    };

    if (!hasStoryWidth) {
      updateStoryWidth();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [active, hasStoryWidth, setActiveStoryWidth, setHasStoryWidth]);

  useEffect(() => {
    if (active && !hasCompleted && count >= 10) {
      clearInterval(intervalRef.current);
      setHasCompleted(true);
      onComplete();
    }
  }, [active, count, hasCompleted, onComplete]);

  useEffect(() => {
    if (active && !isPaused && !hasCompleted) {
      intervalRef.current = setInterval(
        () => {
          setCount((prevCount) => parseFloat((prevCount + slideTimerIncrementSpeed).toFixed(2)));
        },
        isHovered && slowProgressionOnHover ? 40 : 10,
      );
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [active, isPaused, isHovered, hasCompleted]);

  useEffect(() => {
    if (active) {
      setHasCompleted(false);
      setCount(0);
      setIsPaused(false);
    } else {
      clearInterval(intervalRef.current);
    }
  }, [active]);

  const EASE = [0.77, 0, 0.175, 1];
  const SPEED = 0.75;

  const storyContainerAnim = {
    hidden: {
      width: "20%",
      transition: {
        duration: SPEED - 0.175,
        type: "linear",
        ease: EASE,
      },
    },
    visible: {
      width: "100%",
      transition: {
        duration: SPEED,
        type: "linear",
        ease: EASE,
      },
    },
  };

  return (
    <StoryContainer
      animate={active ? "visible" : "hidden"}
      initial={active ? "visible" : "hidden"}
      variants={storyContainerAnim}
      $storyCount={storyCount}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <StoryItemMediaProvider
        active={active}
        onClick={onClick}
        caseStudyLink={caseStudyLink}
        isHovered={isHovered}
        tags={tags}
        backgroundMedia={backgroundMedia}
        backgroundOverlay={backgroundOverlay}
        brandImage={brandImage}
        brandName={brandName}
        handlePauseResume={handlePauseResume}
        count={count}
        isPaused={isPaused}
        enablePopoverVideo={enablePopoverVideo}
        id={id}
        popoverVideoFile={popoverVideoFile}
        popoverMediaActive={popoverMediaActive}
        setPopoverMediaActive={setPopoverMediaActive}
        mediaContainerRef={mediaContainerRef}
      />
      <StoryItemDetailsHandler
        active={active}
        title={title}
        caseStudyLink={caseStudyLink}
        description={description}
        activeStoryWidth={activeStoryWidth}
      />
    </StoryContainer>
  );
}
