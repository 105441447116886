import NextLazyImage from "@/components/Core/NextLazyImage";
import WikiCardItem from "@/components/WikiCard/WikiCardItem";
import { useSbWikiCard } from "@/hooks/useSbWikiCard";
import { safePolygon, useHover, useInteractions } from "@floating-ui/react";
import { offset } from "@floating-ui/react";
import { useFloating } from "@floating-ui/react";
import { useState } from "react";
import styled from "styled-components";
import ArrowRightUp from "@/assets/svg/wikiCardIcon.svg";

const WikiTrigger = styled.span`
  cursor: pointer;
  color: var(--PrimaryAccent) !important;
  font-weight: 600;
  span {
    color: var(--PrimaryAccent) !important;
    font-weight: 600;
    text-decoration: underline;
  }
`;

const IconContainer = styled.span`
  width: 16px;
  height: 16px;
  position: relative;
  color: #383a36;
  svg {
    width: 12px;
    height: 12px;
    display: inline-block;
  }
`;

export default function WikiCard({ cardId, link, target, disableLink, children }) {
  const { data, isLoading } = useSbWikiCard(cardId);
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(4)],
  });

  const hover = useHover(context, {
    handleClose: safePolygon(),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  if (isLoading) return <span>{children}</span>;

  if (data?.length > 1) {
    return <span>{children}</span>;
  }

  return (
    <>
      {isOpen && (
        <WikiCardItem refs={refs} floatingStyles={floatingStyles} getFloatingProps={getFloatingProps} data={data} />
      )}
      {disableLink ? (
        <WikiTrigger ref={refs.setReference} {...getReferenceProps()} style={{ position: "relative" }}>
          {children}
        </WikiTrigger>
      ) : (
        <a href={link} target={target} style={{ textDecoration: "none" }}>
          <WikiTrigger ref={refs.setReference} {...getReferenceProps()} style={{ position: "relative" }}>
            {children}
          </WikiTrigger>
        </a>
      )}
    </>
  );
}
