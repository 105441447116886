import PinpointResultItem from "@/components/Pinpoint/PinpointResultItem";
import { motion } from "motion/react";
import styled from "styled-components";

const ResultsContainer = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  max-width: 100vw;
`;

const ResultsWrapper = styled.div`
  padding: 0 8px 8px 8px;
`;

export default function PinpointResultsContainer({
  results,
  highlightedIndex,
  onSelect,
  getMenuProps,
  isOpen,
  closeMenu,

  floatingStyles,
  selectedItem,
}) {
  return (
    <ResultsContainer
      {...getMenuProps()}
      floatingStyles={floatingStyles}
      initial={{ height: 0 }}
      animate={{ height: isOpen ? "auto" : 0 }}>
      <ResultsWrapper>
        {isOpen &&
          results?.map((item, index) => (
            <PinpointResultItem
              item={item}
              highlightedIndex={highlightedIndex}
              index={index}
              selectedItem={selectedItem}
              key={`${item.id}${index}`}
              onSelect={onSelect}
              closeMenu={closeMenu}
            />
          ))}
      </ResultsWrapper>
    </ResultsContainer>
  );
}
