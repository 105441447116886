import ContentWrapper from "@/components/Core/ContentWrapper";
import BentoOne from "@/components/DualBentoBox/BentoOne";
import BentoTwo from "@/components/DualBentoBox/BentoTwo";
import SectionContentWrapper from "@/components/Shared/SectionContentWrapper";
import SectionTitle from "@/components/Shared/SectionTitle";
import mq from "@/utils/mq";
import React from "react";
import styled from "styled-components";
import { css } from "styled-components";

const BentoBoxes = styled.div`
  display: grid;
  grid-template-columns: 8fr 4.5fr;
  min-height: 30rem;
  gap: 10px;
  ${mq.tablet(css`
    grid-template-columns: 1fr;
  `)};
  ${mq.mobile(css`
    grid-row-gap: 4px;
    grid-column-gap: 0px;
  `)};
`;

export default function DualBentoBox({
  sectionTitle,
  bentoOneTitle,
  bentoOneBodyText,
  bentoOneCta,
  bentoOneImage,
  bentoTwoTitle,
  bentoTwoBodyText,
  bentoTwoCta,
  theme,
  bentoOneIcon,
  bentoTwoIcon,
}) {
  return (
    <SectionContentWrapper sectionTitle={sectionTitle} theme={theme}>
      <BentoBoxes>
        <BentoOne
          title={bentoOneTitle}
          description={bentoOneBodyText}
          icon={bentoOneIcon}
          cta={bentoOneCta}
          image={bentoOneImage}
        />
        <BentoTwo title={bentoTwoTitle} description={bentoTwoBodyText} icon={bentoTwoIcon} cta={bentoTwoCta} />
      </BentoBoxes>
    </SectionContentWrapper>
  );
}
