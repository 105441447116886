import BlogCollectionPill from "@/components/Blog/BlogCollectionPill";
import React from "react";
import styled from "styled-components";

const BlogPostTopLayerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

export default function BlogPostTopLayer({
  primaryCollection,
  advertiseAsPartOfCollection,
}: {
  primaryCollection: any;
  advertiseAsPartOfCollection: any;
}) {
  return (
    <BlogPostTopLayerContainer>
      {primaryCollection && advertiseAsPartOfCollection && <BlogCollectionPill collection={primaryCollection} />}
    </BlogPostTopLayerContainer>
  );
}
