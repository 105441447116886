import styled from "styled-components";
import { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import ContentWrapper from "@/components/Core/ContentWrapper";
import dynamic from "next/dynamic";
import FactoidCard from "@/components/SustainabilityHero/FactoidCard";

gsap.registerPlugin(ScrollTrigger);

const FactoidCardsContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: visible;
`;

const FactoidCardsWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 0rem;
`;

export default function FactoidCards({ factoidCards }) {
  const containerRef = useRef(null);
  const wrapperRef = useRef(null);
  const [totalScroll, setTotalScroll] = useState(0);
  const [activeCardIndex, setActiveCardIndex] = useState(-1);

  useGSAP(() => {
    if (!containerRef.current || !wrapperRef.current) return;

    const container = containerRef.current;
    const wrapper = wrapperRef.current;

    const calculateTotalScroll = () => {
      const wrapperWidth = wrapper.scrollWidth;
      const containerWidth = container.clientWidth;
      const contentWrapperPadding = parseInt(getComputedStyle(container.parentElement).paddingLeft) * 2;
      return wrapperWidth - containerWidth + contentWrapperPadding;
    };

    const updateScrollTrigger = () => {
      const newTotalScroll = calculateTotalScroll();
      setTotalScroll(newTotalScroll);

      ScrollTrigger.getAll().forEach((t) => t.kill());

      const tween = gsap.to(wrapper, {
        x: -newTotalScroll,

        ease: "none",
      });

      ScrollTrigger.create({
        trigger: container,
        start: "top top",
        end: () => `+=${newTotalScroll}`,
        pin: true,
        anticipatePin: 1,
        scrub: true,
        invalidateOnRefresh: true,
        animation: tween,
        onUpdate: (self) => {
          const newActiveCardIndex = Math.floor(self.progress * factoidCards.length);
          setActiveCardIndex(newActiveCardIndex);
        },
      });
    };

    updateScrollTrigger();

    const resizeObserver = new ResizeObserver(updateScrollTrigger);
    resizeObserver.observe(container);

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      resizeObserver.disconnect();
    };
  }, [factoidCards]); // Dependencies array

  return (
    <ContentWrapper>
      <FactoidCardsContainer ref={containerRef}>
        <FactoidCardsWrapper ref={wrapperRef}>
          {factoidCards?.map((factoidCard, index) => (
            <FactoidCard
              key={index}
              item={factoidCard}
              index={index}
              totalCards={factoidCards.length}
              isActive={index <= activeCardIndex}
            />
          ))}
        </FactoidCardsWrapper>
      </FactoidCardsContainer>
    </ContentWrapper>
  );
}
