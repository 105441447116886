import LabelCta from "@/components/Buttons/LabelCta";
import NextLazyImage from "@/components/Core/NextLazyImage";
import Link from "next/link";
import React from "react";
import styled from "styled-components";

const CaseStudyCardContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #e1e3e4;
  background: #fff;

  height: auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ThumbnailContainer = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  overflow: hidden;
`;

const MetaContainer = styled.div`
  padding: 16px;
  flex-grow: 1;
`;

const Title = styled.h3`
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  margin: 0;
  color: var(--PP-Text-Primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const Excerpt = styled.p`
  margin: 0;
  overflow: hidden;
  color: #677076;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

const ActionContainer = styled.div`
  padding: 12px 16px;
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: 1;
`;

export default function CaseStudyCard({ caseStudy, customCtaLabel }: { caseStudy: any; customCtaLabel?: string }) {
  return (
    <CaseStudyCardContainer>
      <Link href={caseStudy?.full_slug}>
        <ThumbnailContainer>
          <NextLazyImage src={caseStudy?.content?.thumbnail?.filename} alt={caseStudy?.content?.title} fill />
        </ThumbnailContainer>
      </Link>
      <ContentContainer>
        <MetaContainer>
          <Title>{caseStudy?.content?.title}</Title>
          <Excerpt>{caseStudy?.content?.description}</Excerpt>
        </MetaContainer>
        <ActionContainer>
          <LabelCta
            label={customCtaLabel ? customCtaLabel : "Read more"}
            color={"#007152"}
            rawLink={"/" + caseStudy?.full_slug}
            includeArrow
          />
        </ActionContainer>
      </ContentContainer>
    </CaseStudyCardContainer>
  );
}
