import NextBlogLazyImage from "@/components/Core/NextBlogLazyImage";
import { BREAKPOINT_SM } from "@/constants";
import mq from "@/utils/mq";
import styled, { css } from "styled-components";

const ImageContainer = styled.div`
  width: 105%;
  height: auto;
  margin: 2rem auto;
  margin-left: -2.5%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  img {
    border-radius: 8px;
  }
  ${mq.mobile(css`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `)};
`;

const Caption = styled.p`
  font-size: 1.1rem;
  text-align: left;
  margin-top: 1rem;
  font-family: var(--font-inter);
  font-weight: 400;
  letter-spacing: -0.00666rem;
  font-size: 1rem;
  color: #52525c;
  line-height: 1.8;
  margin-left: 2.5%;
  b {
    font-weight: 600;
  }
  p {
    color: #52525c !important;
  }
  p + p {
    margin-top: 0.625rem;
  }
`;

export default function BlogPostImage({ image, caption }) {
  return (
    <ImageContainer>
      <NextBlogLazyImage src={image?.filename} alt={image?.alt} fill />
      <Caption>{caption}</Caption>
    </ImageContainer>
  );
}
