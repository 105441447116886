import styled, { css } from "styled-components";
import { JobPositionItemProps } from "../JobPositionsList/JobPositionsList";
import { format } from "date-fns";
import Link from "next/link";
import {
  JobListingMetaItem,
  EmploymentTypeParser,
  EmploymentLocationParser,
  SalaryParser,
} from "@/components/JobListing/Shared";

import JobListingDaysIcon from "@/assets/svg/jobListingDays.svg";
import JobListingHoursIcon from "@/assets/svg/jobListingHours.svg";
import JobListingLocationIcon from "@/assets/svg/jobListingLocation.svg";
import JobListingPostedIcon from "@/assets/svg/jobListingPosted.svg";
import JobListingSalaryIcon from "@/assets/svg/jobListingSalary.svg";
import JobListingEmploymentTypeIcon from "@/assets/svg/jobListingType.svg";
import IconRenderer from "@/components/Shared/IconRenderer";
import mq from "@/utils/mq";

const JobItemContainer = styled(Link)`
  width: 100%;
  border: 1px solid #e1e3e4;
  background: #fff;
  border-radius: 12px;
  padding: 26px 32px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #f9fafb;
  }
  ${mq.mobile(css`
    padding: 26px 16px;
  `)};
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Title = styled.h3`
  color: #10181c;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  ${mq.mobile(css`
    font-size: 18px;
    line-height: 22px;
  `)};
`;

const Description = styled.p`
  color: #677076;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
`;

const Meta = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const IconContainer = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #10181c;
  svg {
    width: 100%;
    height: 100%;
    stroke-width: 0.75;
  }
`;

const MetaItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const MetaIcon = styled.div`
  width: 14px;
  height: 14px;
  color: #10181c;
  svg {
    width: 100%;
    height: 100%;
    stroke-width: 0.75;
  }
`;

const MetaLabel = styled.div`
  color: #677076;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

function JobPositionItem({ job, full_slug }: { job: JobPositionItemProps; full_slug: string }) {
  return (
    <JobItemContainer href={full_slug}>
      <TopRow>
        <IconContainer>
          <IconRenderer icon={job?.icon} size={26} color='#677076' />
        </IconContainer>
      </TopRow>
      <BottomRow>
        <Title>{job?.title}</Title>
        <Meta>
          <MetaItem>
            <MetaIcon>
              <JobListingLocationIcon />
            </MetaIcon>
            <MetaLabel>
              {EmploymentLocationParser({ type: job?.location[0].type, town: job?.location[0].town })}
            </MetaLabel>
          </MetaItem>
          <MetaItem>
            <MetaIcon>
              <JobListingEmploymentTypeIcon />
            </MetaIcon>
            <MetaLabel>{EmploymentTypeParser(job?.employmentType)}</MetaLabel>
          </MetaItem>
        </Meta>
        <Description>{job?.description}</Description>
      </BottomRow>
    </JobItemContainer>
  );
}

export default JobPositionItem;
