import Head from "next/head";
import React from "react";

interface BreadcrumbItem {
  name: string;
  item?: string;
}

interface BreadcrumbJsonLdProps {
  items: BreadcrumbItem[];
  baseUrl: string;
}

const BreadcrumbJsonLd: React.FC<BreadcrumbJsonLdProps> = ({ items, baseUrl }) => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "@id": `${baseUrl}#breadcrumb`,
    itemListElement: items?.map((breadcrumbItem, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: breadcrumbItem.name,
      ...(breadcrumbItem.item && { item: breadcrumbItem.item }),
    })),
  };

  return (
    <Head>
      <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
    </Head>
  );
};

export default BreadcrumbJsonLd;
