import React from "react";
import styled from "styled-components";

const CaseStudyStoriesSliderItemContentMobileContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 32px;
  padding-top: 24px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: var(--PP-Text-Primary);
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #677076;
`;

export default function CaseStudyStoriesSliderItemContentMobile({ story }) {
  return (
    <CaseStudyStoriesSliderItemContentMobileContainer>
      <Title>{story?.title}</Title>
      <Description>{story?.description}</Description>
    </CaseStudyStoriesSliderItemContentMobileContainer>
  );
}
