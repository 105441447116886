import HubspotInlineForm from "./HubspotInlineForm";

const HubspotInlineFormWithProps = ({ blok }) => {
  const randomId = Math.random().toString(36).substring(2, 15);
  return (
    <HubspotInlineForm
      sectionTitle={blok.sectionTitle}
      theme={blok.theme}
      randomId={randomId}
      hubspotFormID={blok.hubspotFormID}
    />
  );
};

export default HubspotInlineFormWithProps;
