import Breadcrumbs from "@/components/Breadcrumbs";
import ContentWrapper from "@/components/Core/ContentWrapper";
import NextLazyImage from "@/components/Core/NextLazyImage";
import mq from "@/utils/mq";
import styled from "styled-components";
import { css } from "styled-components";

const ContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 18px;
  padding-top: 64px;
  ${mq.mobile(css`
    padding-top: 96px;
  `)};
`;

const Title = styled.h1`
  color: var(--PP-Text-Primary, #10181c);
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  max-width: 530px;
  margin: 0;
  padding: 0;
`;

const Description = styled.h2`
  color: var(--PP-Text-Secondary, #677076);
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  padding: 0;
  max-width: 740px;
  font-weight: 400;
`;

const FeaturedImage = styled.div`
  width: 100%;
  //height: 550px;
  aspect-ratio: 16/7.5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
  border-radius: 4px;
  overflow: hidden;
`;

export default function CaseStudyHero({ customerIcon, customerName, title, description, featuredImage }) {
  return (
    <ContentWrapper>
      <ContentContainer>
        <Breadcrumbs
          breadcrumbs={[
            { title: "Case Studies", url: "/case-studies" },
            {
              title: customerName,
              hasIcon: customerIcon && customerIcon.filename,
              icon: customerIcon.filename,
            },
          ]}
        />
        <Title>{title}</Title>
        <Description>{description}</Description>
        <FeaturedImage>
          <NextLazyImage src={featuredImage.filename} alt={featuredImage.alt} fill />
        </FeaturedImage>
      </ContentContainer>
    </ContentWrapper>
  );
}
