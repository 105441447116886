import BlokNotFound from "@/lib/storyblok/BlokNotFound";
import components from "@/lib/storyblok/components";
import EmptyPageMessage from "@/lib/storyblok/EmptyPageMessage";
import ErrorBound from "@/utils/ErrorBound";
import { StoryblokComponent } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react";
import styled from "styled-components";

const StoryblokEditableContainer = styled.div``;

const BlokRenderer = ({
  blok,
  dynamic,
  dynamicMode,
  content,
  noEmptyAlert,
}: {
  blok: any;
  dynamic?: boolean;
  dynamicMode?: number;
  content?: any;
  noEmptyAlert?: boolean;
}) => {
  if (content && content?.length > 0) {
    return (
      <ErrorBound>
        <div>
          {content?.map((nestedBlok: any, i: any) => {
            if (nestedBlok.component in components) {
              return (
                <ErrorBound key={i}>
                  <StoryblokEditableContainer
                    {...storyblokEditable(nestedBlok)}
                    key={i}
                    className={`sbEdit_${blok._uid}`}>
                    <StoryblokComponent blok={nestedBlok} key={i} />
                  </StoryblokEditableContainer>
                </ErrorBound>
              );
            }
            return <BlokNotFound blok={nestedBlok} key={i} />;
          })}
        </div>
      </ErrorBound>
    );
  }

  if (!blok.body || blok.body.length === 0 || !blok?.body[0]?.component) {
    if (!noEmptyAlert) {
      return <EmptyPageMessage />;
    }
  }

  return blok?.body?.map((nestedBlok: any, i: any) => {
    if (nestedBlok.component in components) {
      return (
        <ErrorBound>
          <ErrorBound key={i}>
            <StoryblokEditableContainer {...storyblokEditable(nestedBlok)} key={i} className={`sbEdit_${blok._uid}`}>
              <StoryblokComponent blok={nestedBlok} key={i} />
            </StoryblokEditableContainer>
          </ErrorBound>
        </ErrorBound>
      );
    }
    return <BlokNotFound blok={nestedBlok} key={i} />;
  });
};

export default BlokRenderer;
