import styled from "styled-components";

const SectionPillItem = styled.div`
  display: flex;
  padding: 4px 18px;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #e8e8e8;
  background: #f3f4f6;
  color: #455250;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  width: fit-content;
  font-family: var(--font-inter);
`;

export default function SectionPill({ label }) {
  return <SectionPillItem>{label}</SectionPillItem>;
}
