import GenericHero from "@/components/Core/GenericHero";
import React from "react";
import JobPositionsList from "@/components/JobPositionsList/JobPositionsList";
import { useAllJobVacancies } from "@/hooks/useAllJobVacancies";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import styled, { css } from "styled-components";
import mq from "@/utils/mq";
import JobListingIcon from "@/assets/svg/jobListings.svg";

const AdditionalContentContainer = styled.div`
  width: 100%;
  padding: 84px 0px 46px 0px;

  ${mq.tablet(css`
    padding-top: 96px;
    padding-bottom: 26px;
  `)};
`;

const AdditionalContentWrapper = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
`;

export default function StoryblokVacanciesRoot({ blok }) {
  const { labelText, title, bodyText, additionalContent } = blok;
  const { data: jobs, isLoading, error } = useAllJobVacancies();

  if (error) {
    return <div>Error loading vacancies: {error.message}</div>;
  }

  return (
    <div>
      <GenericHero title={title} bodyText={bodyText} pillText={labelText} pillIcon={<JobListingIcon />} />
      {isLoading ? <div>Loading vacancies...</div> : <JobPositionsList jobs={jobs || []} />}

      <BlokRenderer blok={{ body: additionalContent }} />
    </div>
  );
}
