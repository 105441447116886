import BlogAllCollectionsGrid from "@/components/Blog/BlogCollectionGrid/BlogAllCollectionsGrid";
import BlogCollectionHero from "@/components/Blog/BlogCollectionHero";

import BlogRootHero from "@/components/BlogRootHero/BlogRootHero";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import { render } from "storyblok-rich-text-react-renderer";

const StoryblokBlogRoot = ({ blok }: { blok: any }) => {
  return (
    <div>
      <BlogCollectionHero
        title={blok.title}
        description={blok.description}
        breadcrumbs={[
          { title: "Blog", url: "/blog" },
          { title: "Collections", url: "/blog/d/collections", isCollections: true },
        ]}
        canShare={false}
        shareLink={""}
      />
      <BlogAllCollectionsGrid />
    </div>
  );
};

export default StoryblokBlogRoot;
