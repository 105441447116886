import styled, { css } from "styled-components";

import ContentWrapper from "@/components/Core/ContentWrapper";
import CollectionStoryCard from "@/components/BlogCollectionSlider/CollectionStoryCard";
import mq from "@/utils/mq";
import { useMemo } from "react";
import BlogItemCard from "@/components/BlogRework/BlogItemCard";

const StoriesOuterContainer = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

const StoriesContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1180px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default function BlogCollectionGrid({ collection }) {
  useMemo(() => {
    const filteredStories = collection?.stories.filter((story) => typeof story === "object" && "full_slug" in story);
    collection.stories = filteredStories;
  }, [collection]);
  return (
    <ContentWrapper>
      <StoriesOuterContainer>
        <StoriesContainer>
          {collection.stories?.map((story, i) => <BlogItemCard key={i} story={story} />)}
        </StoriesContainer>
      </StoriesOuterContainer>
    </ContentWrapper>
  );
}
