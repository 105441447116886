import BreadcrumbJsonLd from "@/components/Shared/JsonLD/BreadcrumbJsonLd";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import { generateSeoBreadcrumbs } from "@/utils/generateSeoBreadcrumbs";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

const StoryblokSectorPage = ({ blok }: { blok: any }) => {
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_ROOT_DOMAIN || "https://www.plantplan.co.uk/";
  return (
    <>
      <NextSeo additionalMetaTags={[{ name: "keywords", content: blok?.keywords?.value?.join(", ") }]} />
      {blok.breadcrumbs && blok.breadcrumbs.length > 0 && (
        <BreadcrumbJsonLd
          items={generateSeoBreadcrumbs({
            breadcrumbs: blok.breadcrumbs,
            baseUrl,
            currentPath: router.asPath,
            pageTitle: blok.title,
          })}
          baseUrl={baseUrl}
        />
      )}
      <BlokRenderer blok={blok} key={blok?._uid} />
    </>
  );
};

export default StoryblokSectorPage;
