import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";

const svgUnderline = `
<svg width="705" height="22" viewBox="0 0 705 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M30.9961 14.6468C36.7554 11.0157 37.1565 10.8484 82.6326 10.0462C130.856 9.19579 343.653 3.40842 674.132 11.9972" stroke="#1CD956" stroke-width="8" stroke-linecap="round"/>
</svg>
`;

const encodedSvg = encodeURIComponent(svgUnderline);

const UnderlineAnimationWrapper = styled.span`
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 10px;
  background-image: url("data:image/svg+xml,${encodedSvg}");
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: auto 100%;
  transform: scaleX(0);
  transform-origin: left center;
`;

interface GSAPUnderlineAnimationProps {
  isActive: boolean;
}

export default function GSAPUnderlineAnimation({ isActive }: GSAPUnderlineAnimationProps) {
  const underlineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!underlineRef.current) return;

    gsap.to(underlineRef.current, {
      scaleX: isActive ? 1 : 0,
      duration: 0.4,
      ease: "power2.out",
    });
  }, [isActive]);

  return <UnderlineAnimationWrapper ref={underlineRef} />;
}
