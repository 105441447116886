import CaseStudyStoryItem from "@/components/CaseStudyStoriesSlider/CaseStudyStoryItem";
import SectionContentWrapper from "@/components/Shared/SectionContentWrapper";
import { themeProvider } from "@/utils/ThemeProvider";
import { useCallback, useState } from "react";
import styled from "styled-components";

const CaseStudyStoriesSliderContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export default function CaseStudyStoriesSlider({
  sectionTitle,
  stories,
  theme,
  progressionSpeed = { value: 1.1 },
  slowProgressionOnHover,
}) {
  const [activeStory, setActiveStory] = useState(0);
  const [activeStoryWidth, setActiveStoryWidth] = useState(660);
  const [hasStoryWidth, setHasStoryWidth] = useState(false);

  const handleComplete = useCallback(() => {
    if (activeStory < stories.length - 1) {
      setActiveStory(activeStory + 1);
    } else {
      setActiveStory(0);
    }
  }, [activeStory, stories.length]);

  const handleCardClick = (index) => {
    setActiveStory(index);
  };

  return (
    <SectionContentWrapper sectionTitle={sectionTitle} theme={theme}>
      <CaseStudyStoriesSliderContainer>
        {stories &&
          stories?.map((story, i) => (
            <CaseStudyStoryItem
              key={i}
              storyCount={stories.length}
              id={i}
              brandName={story.brandName}
              activeStoryWidth={activeStoryWidth}
              setActiveStoryWidth={setActiveStoryWidth}
              hasStoryWidth={hasStoryWidth}
              setHasStoryWidth={setHasStoryWidth}
              brandImage={story.brandImage}
              tags={story.tags}
              backgroundMedia={story?.backgroundMedia[0]}
              backgroundOverlay={story.backgroundOverlay}
              title={story.title}
              active={i === activeStory}
              description={story.description}
              caseStudyLink={story.caseStudyLink}
              enablePopoverVideo={story.enablePopoverVideo}
              popoverVideoFile={story.popoverVideoFile}
              slideTimerIncrementSpeed={progressionSpeed?.value / 2 / 100}
              slowProgressionOnHover={slowProgressionOnHover}
              onComplete={handleComplete}
              onClick={handleCardClick}
            />
          ))}
      </CaseStudyStoriesSliderContainer>
    </SectionContentWrapper>
  );
}
