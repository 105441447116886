import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import styled, { css } from "styled-components";
import { Element } from "react-scroll";
import mq from "@/utils/mq";

const BlogPostContentItemWrapper = styled.div`
  padding-left: 0px;
  padding-right: 7rem;
  margin-top: 1.5rem;
  margin-bottom: 4rem;
  @media (max-width: 1000px) {
    padding-right: 0rem;
  }
  ${mq.mobile(css`
    padding-right: 0;
    margin-bottom: 2rem;
    margin-top: 1rem;
  `)};
`;

const SectionTitle = styled.div`
  letter-spacing: -0.00666rem;
  margin-top: 2rem;

  h2 {
    font-size: 2rem;
    line-height: 1.25;
    margin-bottom: 1.333rem;
    font-weight: 500;
    color: #09090b;
  }
  h3 {
    font-size: 1.6rem;
    line-height: 1.3333333333;
    font-weight: 500;
    margin-bottom: 1.333rem;
  }
  h4 {
    font-size: 1.3rem;
    line-height: 1.3333333333;
    font-weight: 500;
    margin-bottom: 1.333rem;
  }
  h5 {
    font-size: 1.25rem;
    line-height: 1.3333333333;
    font-weight: 500;
    margin-bottom: 1.333rem;
  }
  h6 {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-weight: 500;
    margin-bottom: 1rem;
  }
`;

export default function BlogContentItem({ blok }: { blok: any }) {
  const { title, content, hideFromTableOfContents, titleStyle } = blok;
  return (
    <Element name={hideFromTableOfContents ? "" : blok._uid}>
      <BlogPostContentItemWrapper>
        <SectionTitleProvider title={title} titleStyle={titleStyle} />
        <BlokRenderer blok={{ body: content }} key={blok?._uid} noEmptyAlert={true} />
      </BlogPostContentItemWrapper>
    </Element>
  );
}

export const SectionTitleProvider = ({ title, titleStyle }: { title: string; titleStyle: string }) => {
  if (titleStyle === "h3") {
    return (
      <SectionTitle>
        <h3>{title}</h3>
      </SectionTitle>
    );
  }
  if (titleStyle === "h4") {
    return (
      <SectionTitle>
        <h4>{title}</h4>
      </SectionTitle>
    );
  }
  if (titleStyle === "h5") {
    return (
      <SectionTitle>
        <h5>{title}</h5>
      </SectionTitle>
    );
  }
  if (titleStyle === "h6") {
    return (
      <SectionTitle>
        <h6>{title}</h6>
      </SectionTitle>
    );
  }
  return (
    <SectionTitle>
      <h2>{title}</h2>
    </SectionTitle>
  );
};
