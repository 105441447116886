import FaqJsonLd from "@/components/Shared/JsonLD/FaqJsonLd";
import { BREAKPOINT_SM } from "@/constants";
import mq from "@/utils/mq";
import { themeProvider } from "@/utils/ThemeProvider";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";

const OuterContainer = styled.div`
  width: 100%;
  padding-bottom: 24px;
`;

const InnerContainer = styled.div`
  max-width: calc(900px + 3rem);
  width: 100%;
  margin: 0 auto;
  color: var(--PP-Text-Primary, #10181c);
  font-family: var(--font-inter);
  ${mq.mobile(css`
    padding-bottom: 1.5rem;
  `)};
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: ${BREAKPOINT_SM}px) {
    max-width: calc(900px + 4.5rem);
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 8px;
  font-weight: 500;
  font-family: var(--font-pp-neue);
  letter-spacing: 0.02em;
  margin-top: 32px;

  b {
    font-weight: 500;
  }

  &:first-child {
    margin-top: 0;
  }

  &:has(> .reduceTitleTopMargin) {
    margin-top: 0;
  }
`;

const FAQItem = styled.div``;

const FAQItemQuestion = styled.h3`
  margin-bottom: 8px;
  font-weight: 500;
  font-family: var(--font-pp-neue);
  letter-spacing: 0.02em;
  margin-top: 32px;
  font-size: 24px;
  line-height: 34px;
  color: var(--PP-Text-Primary, #10181c);
`;

const FAQItemAnswer = styled.div`
  font-weight: 400;

  margin-bottom: 24px;
  color: var(--PP-Text-Secondary, #677076);
  b {
    color: var(--PP-Text-Primary, #10181c);
  }
  font-size: 18px;
  line-height: 32px;
`;

export default function BlogFAQ({ blok, theme }) {
  return (
    <OuterContainer id='faqs' style={{ backgroundColor: themeProvider(theme).backgroundColor }}>
      <FaqJsonLd data={blok.faqItems} />
      <InnerContainer>
        <Title>{blok.title}</Title>
        {blok.faqItems?.map((faqItem) => (
          <FAQItem key={faqItem._uid}>
            <FAQItemQuestion>{faqItem.question}</FAQItemQuestion>
            <FAQItemAnswer>{render(faqItem.answer)}</FAQItemAnswer>
          </FAQItem>
        ))}
      </InnerContainer>
    </OuterContainer>
  );
}
