// components/VideoLibrary/ui/VideoPlayButton.tsx
import React from "react";
import styled, { css } from "styled-components";
import PlayIcon from "@/assets/svg/playIcon.svg";
import mq from "@/utils/mq";

const VideoPlayButtonContainer = styled.button`
  display: flex;
  height: 32px;
  padding: 2px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #e6e6e6;
  width: fit-content;
  cursor: pointer;
  border: none;
  outline: none;
`;

const IconContainer = styled.div`
  width: 14px;
  height: 14px;
  color: #08090a;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Label = styled.span`
  color: #212223;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  ${mq.mobile(css`
    display: none;
  `)};
`;

const MobileLabel = styled(Label)`
  display: none;
  ${mq.mobile(css`
    display: flex;
  `)};
`;

const HotkeyLabel = styled.div`
  color: #212223;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.08);
  display: flex;
  width: 18px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  ${mq.mobile(css`
    display: none;
  `)};
`;

interface VideoPlayButtonProps {
  onClick: () => void;
}

/**
 * Play button component with hotkey indicator
 */
export default function VideoPlayButton({ onClick }: VideoPlayButtonProps) {
  return (
    <VideoPlayButtonContainer onClick={onClick}>
      <IconContainer>
        <PlayIcon />
      </IconContainer>
      <Label>Play video</Label>
      <MobileLabel>Play</MobileLabel>
      <HotkeyLabel>P</HotkeyLabel>
    </VideoPlayButtonContainer>
  );
}
