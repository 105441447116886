import { motion } from "motion/react";
import { useEffect, useState, useRef, useCallback } from "react";
import { createPortal } from "react-dom";
import { useHotkeys } from "react-hotkeys-hook";
import styled from "styled-components";

const PopoverContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopoverContent = styled.div``;

const Popover = ({
  children,
  popoverActive,
  setPopoverActive,
  id,
}: {
  children: React.ReactNode;
  popoverActive: boolean;
  setPopoverActive: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}) => {
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const [mount, setMount] = useState(false);
  const [unmounting, setUnmounting] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useHotkeys("escape", () => {
    setPopoverActive(false);
  });

  useEffect(() => {
    if (popoverActive) {
      setTarget(document.body);
    }
    if (target && popoverActive) {
      setMount(true);
    }
    if (!popoverActive && mount) {
      setUnmounting(true);
      setTimeout(() => {
        setUnmounting(false);
        setMount(false);
      }, 300);
    }
  }, [popoverActive, target]);

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (contentRef.current && !contentRef.current.contains(e.target as Node)) {
        setPopoverActive(false);
      }
    },
    [setPopoverActive],
  );

  useEffect(() => {
    if (popoverActive) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [popoverActive, handleOutsideClick]);

  if (!target || !mount) return null;

  return createPortal(
    <PopoverContainer animate={{ opacity: unmounting ? 0 : 1 }} initial={{ opacity: 0 }}>
      <PopoverContent ref={contentRef}>{children}</PopoverContent>
    </PopoverContainer>,
    target,
    id,
  );
};

export default Popover;
