// components/VideoLibrary/ui/VideoMeta.tsx
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { format } from "date-fns";
import { Video } from "../types";
// Remove react-share import
// import { EmailShareButton, LinkedinShareButton } from "react-share";

// Import the SVG icons as they were in the original implementation
import EmailIcon from "@/assets/svg/videoEmail.svg";
import LinkedInIcon from "@/assets/svg/videoLinkedin.svg";
import ShareIcon from "@/assets/svg/videoShare.svg";
import mq from "@/utils/mq";
import { motion } from "motion/react";
import VideoPlayButton from "@/components/VideoLibrary/ui/VideoPlayButton";

const VideoButtonsLayer = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
  z-index: 214748364710;
  position: relative;

  ${mq.mobile(css`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 16px;
  `)};
`;

const MobileTopLayer = styled.div`
  ${mq.mobile(css`
    display: flex;
    gap: 16px;
  `)};
`;

const VideoMetaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  ${mq.mobile(css`
    width: 100%;
    display: flex;
    gap: 8px;
  `)};
`;

const VideoMetaText = styled.div`
  color: #8a8f98;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
`;

const Divider = styled.div<{ $hideMobile?: boolean }>`
  color: #8a8f98;
  font-size: 12px;
  line-height: 17px;
  ${mq.mobile(css<{ $hideMobile?: boolean }>`
    ${({ $hideMobile }) =>
      $hideMobile &&
      css`
        display: none;
      `}
  `)}
`;

// Social Button Components
const SocialButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ShareButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #f7f8f8;
  background: none;
  border: none;
  outline: none;
  border-radius: 8px;
  transition:
    background 0.2s ease-in-out,
    transform 0.2s ease-in;
  backdrop-filter: blur(8px);
  background: hsla(0, 0%, 100%, 0.05);
  transform: scale(1);

  &:hover {
    transition:
      background 0.2s ease-in-out,
      transform 0.2s ease-in;
    background: hsla(0, 0%, 100%, 0.16);
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: #8a8f98;
`;

const VideoPlayButtonWrapper = styled(motion.div)`
  display: none;
  ${mq.mobile(css`
    display: block;
  `)};
`;

function VideoSocialButtons({ isPlayerOpen }: { isPlayerOpen: boolean }) {
  const [isShareAvailable, setIsShareAvailable] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [shareTitle, setShareTitle] = useState("Check out this video");

  // Set the share URL and check if Web Share API is available
  useEffect(() => {
    if (typeof window !== "undefined") {
      setShareUrl(window.location.href);
      setShareTitle(document.title || "Check out this video");
      setIsShareAvailable(typeof navigator !== "undefined" && !!navigator.share);
    }
  }, []);

  const handleNativeShare = async () => {
    if (isPlayerOpen) {
      return;
    }

    if (typeof navigator !== "undefined" && navigator.share) {
      try {
        await navigator.share({
          title: shareTitle,
          url: shareUrl,
        });
      } catch (error) {
        // Safari throws AbortError when user cancels share dialog
        // This is expected behavior, so we only log non-abort errors
        if (!(error instanceof Error) || error.name !== "AbortError") {
          console.error("Share error:", error);
        }
      }
    } else {
      // Fallback: Copy to clipboard
      if (typeof navigator !== "undefined" && navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(shareUrl);
          // Could show a toast notification here
        } catch (error) {
          console.error("Clipboard error:", error);
        }
      }
    }
  };

  const handleLinkedInShare = (e: React.MouseEvent) => {
    if (isPlayerOpen) {
      e.preventDefault();
      return;
    }

    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
    window.open(linkedinUrl, "_blank", "noopener,noreferrer");
  };

  const handleEmailShare = (e: React.MouseEvent) => {
    if (isPlayerOpen) {
      e.preventDefault();
      return;
    }

    const subject = encodeURIComponent(shareTitle);
    const body = encodeURIComponent(`Check out this video: ${shareUrl}`);
    const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;
    window.location.href = mailtoUrl;
  };

  return (
    <SocialButtonsWrapper>
      <Label>Share</Label>
      <ShareButtonWrapper onClick={handleLinkedInShare}>
        <LinkedInIcon />
      </ShareButtonWrapper>
      <ShareButtonWrapper onClick={handleEmailShare}>
        <EmailIcon />
      </ShareButtonWrapper>
      {isShareAvailable && (
        <ShareButtonWrapper onClick={handleNativeShare}>
          <ShareIcon />
        </ShareButtonWrapper>
      )}
    </SocialButtonsWrapper>
  );
}

interface VideoMetaProps {
  video: Video;
  isReady: boolean;
  openPlayer: () => void;
  isPlayerOpen: boolean;
}

/**
 * Video metadata component that shows date, duration and sharing options
 */
export default function VideoMeta({ video, isReady, openPlayer, isPlayerOpen }: VideoMetaProps) {
  return (
    <VideoButtonsLayer>
      <MobileTopLayer>
        <VideoPlayButtonWrapper
          initial={{ opacity: 0, y: 10, filter: "blur(6px)" }}
          animate={{
            opacity: isReady ? 1 : 0,
            y: isReady ? 0 : 10,
            filter: isReady ? "blur(0px)" : "blur(6px)",
          }}
          transition={{
            type: "spring",
            stiffness: 120,
            damping: 14,
            mass: 0.8,
            delay: isReady ? 0.18 : 0,
          }}>
          <VideoPlayButton onClick={openPlayer} />
        </VideoPlayButtonWrapper>
        <VideoSocialButtons isPlayerOpen={isPlayerOpen} />
      </MobileTopLayer>
      <Divider $hideMobile>·</Divider>
      <VideoMetaWrapper>
        <VideoMetaText>{format(new Date(video.publishDate), "MMM d, yyyy")}</VideoMetaText>
        <Divider>·</Divider>
        <VideoMetaText>{video.lengthMinutes} min</VideoMetaText>
      </VideoMetaWrapper>
    </VideoButtonsLayer>
  );
}
