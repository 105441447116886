/**
 * Converts minutes and seconds to ISO 8601 duration format
 * Example: formatDuration(30, 5) returns "PT00H30M05S" (30 minutes and 5 seconds)
 */
export function formatDuration(minutes?: number, seconds?: number): string {
  // Handle undefined or null values
  const mins = minutes || 0;
  const secs = seconds || 0;

  // Calculate hours from minutes
  const hours = Math.floor(mins / 60);
  const remainingMinutes = mins % 60;

  // Format with leading zeros
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(remainingMinutes).padStart(2, "0");
  const formattedSeconds = String(secs).padStart(2, "0");

  return `PT${formattedHours}H${formattedMinutes}M${formattedSeconds}S`;
}

/**
 * Converts minutes and seconds to total seconds for OpenGraph metadata
 * Example: formatSecondsForOG(30, 5) returns 1805 (30 minutes and 5 seconds in seconds)
 */
export function formatSecondsForOG(minutes?: number, seconds?: number): number {
  // Handle undefined or null values
  const mins = minutes || 0;
  const secs = seconds || 0;

  // Convert minutes to seconds and add the remaining seconds
  return mins * 60 + secs;
}
