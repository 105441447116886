import CollectionStoryCard from "@/components/BlogCollectionSlider/CollectionStoryCard";
import Breadcrumbs from "@/components/Breadcrumbs";
import ContentWrapper from "@/components/Core/ContentWrapper";
import styled from "styled-components";
import BlogCollectionHeroControls from "@/components/Blog/BlogCollectionHero/BlogCollectionHeroControls";

const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  @media (max-width: 1000px) {
    padding-top: 6rem;
  }
`;

const CollectionHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  max-width: 1180px;
  width: 100%;
`;

const BreadcrumbsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const Title = styled.h2`
  color: var(--PP-Text-Primary);
  margin: 0;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
`;

const Description = styled.p`
  margin: 0;
  overflow: hidden;
  color: #677076;
  max-width: 536px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

export default function BlogCollectionHero({ title, description, breadcrumbs, canShare, shareLink }) {
  return (
    <ContentWrapper>
      <OuterWrapper>
        <CollectionHeroContainer>
          <BreadcrumbsContainer>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </BreadcrumbsContainer>
          <MetaContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <BlogCollectionHeroControls canShare={canShare} shareLink={shareLink} />
          </MetaContainer>
        </CollectionHeroContainer>
      </OuterWrapper>
    </ContentWrapper>
  );
}
