import CardsSliderItem from "@/components/CardsSlider/CardsSliderItem";
import SectionContentWrapper from "@/components/Shared/SectionContentWrapper";
import { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import ContentWrapper from "@/components/Core/ContentWrapper";
import styled, { css } from "styled-components";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom } from "swiper/modules";

import Chevron from "@/assets/svg/chevronRight.svg";
import mq from "@/utils/mq";

import NextLazyImage from "@/components/Core/NextLazyImage";
import { themeProvider } from "@/utils/ThemeProvider";
import CardSliderItemWithText from "@/components/CardsSlider/CardSliderItemWithText";

const CardsSliderWrapper = styled.div`
  max-width: 100vw;
  overflow: hidden;
`;

const CardsSliderContainer = styled.div``;

const FullWidthGallerySliderContainer = styled.div`
  overflow: hidden;
`;

const GallerySliderContainer = styled.div<{ $hasSectionTitle: boolean }>`
  position: relative;
  width: 100%;
  overflow: visible;

  ${({ $hasSectionTitle }) =>
    $hasSectionTitle &&
    css`
      padding-top: 3rem;
    `};
`;

const SwiperWrapper = styled.div`
  .swiper {
    overflow: visible;
  }
  .swiper-wrapper {
    align-items: center;
  }
`;

const GalleryItem = styled.div<{ $isActive: boolean }>`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  aspect-ratio: 16/10;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  ${mq.mobile(css`
    width: 100%;
    //aspect-ratio: 336 / 469;
    height: unset;
    margin: 0;
  `)};
`;

const NavigationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 42px;
  ${mq.mobile(css`
    margin-top: 24px;
  `)};
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  ${mq.mobile(css`
    gap: 6px;
  `)};
`;

const NavigationButton = styled.button`
  display: flex;
  width: 36px;
  min-width: 36px;
  min-height: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #e8e8eb;
  cursor: pointer;
  border: 0px;
  flex-shrink: 0.1;
  position: relative;
  color: var(PP-Text-Primary);
  ${mq.mobile(css`
    --size: 26px;
    width: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    height: var(--size);
  `)};
`;

const NavButtonIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(PP-Text-Primary);
  svg {
    color: var(PP-Text-Primary);
    width: 100%;
    height: 100%;
    min-width: 20px;
    min-height: 20px;
  }
  ${mq.mobile(css`
    svg {
      min-width: 14px;
      min-height: 14px;
    }
  `)};
`;

const ProgressBar = styled.div`
  position: relative;
  flex-grow: 1;
  height: 4px;
  background: #e8eaed;
  .swiper-pagination-progressbar-fill {
    background: var(--PrimaryAccent);
    height: 100%;
  }
`;

const ControlsWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  ${mq.mobile(css`
    padding: 0;
  `)};
`;

export default function CardsSlider({ sectionTitle, cardItems, displayControls, displayProgressBar }) {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <CardsSliderWrapper>
      <SectionContentWrapper sectionTitle={sectionTitle}>
        <CardsSliderContainer>
          <SwiperWrapper>
            <Swiper
              spaceBetween={16}
              slidesPerView='auto'
              centeredSlides={false}
              loop={false}
              //autoplay={{
              //  delay: 4 * 1000, // Convert milliseconds to seconds
              //  disableOnInteraction: false,
              //}}
              modules={[
                ...(displayControls ? [Navigation] : []),
                ...(displayProgressBar ? [Pagination] : []),
                Scrollbar,
                A11y,
                Autoplay,
              ]}
              navigation={
                displayControls
                  ? {
                      prevEl: ".swiper-button-prev",
                      nextEl: ".swiper-button-next",
                    }
                  : false
              }
              pagination={
                displayProgressBar
                  ? {
                      el: ".swiper-pagination",
                      type: "progressbar",
                    }
                  : false
              }
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}>
              {cardItems?.map((card, index) => (
                <SwiperSlide key={index} style={{ width: "auto" }}>
                  {card.component === "sliderCardWithText" ? (
                    <CardSliderItemWithText item={card} />
                  ) : (
                    <CardsSliderItem item={card} />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperWrapper>
        </CardsSliderContainer>
        {(displayControls || displayProgressBar) && (
          <ControlsWrapper>
            <NavigationControls>
              {displayProgressBar && <ProgressBar className='swiper-pagination' />}
              {displayControls && (
                <NavigationButtons>
                  <NavigationButton className='swiper-button-prev'>
                    <NavButtonIcon style={{ transform: "rotate(180deg)" }}>
                      <Chevron color={"#10181c"} style={{ color: "#10181c" }} />
                    </NavButtonIcon>
                  </NavigationButton>
                  <NavigationButton className='swiper-button-next'>
                    <NavButtonIcon>
                      <Chevron color={"#10181c"} style={{ color: "#10181c" }} />
                    </NavButtonIcon>
                  </NavigationButton>
                </NavigationButtons>
              )}
            </NavigationControls>
          </ControlsWrapper>
        )}
      </SectionContentWrapper>
    </CardsSliderWrapper>
  );
}
