import React from "react";
import styled from "styled-components";
import FilterChip from "@/components/Search/SearchResultsBox/FilterChip";
import mq from "@/utils/mq";
import { css } from "styled-components";

const ActiveFiltersBoxContainer = styled.div`
  display: flex;
  width: 660px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1.5px solid #e0e8f0;
  background: var(--neutral-0, #fff);
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const TopLayer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
`;

const TopLayerLeft = styled.div``;

const TopLayerRight = styled.div``;

const Title = styled.h3`
  color: var(--PP-Text-Primary, #10181c);
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 175% */
  letter-spacing: 0.2px;
`;

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
`;

const Filter = styled.div``;

export default function ActiveFiltersBox({ filters, title, onRemove }) {
  if (!filters || filters.length === 0) {
    return null;
  }

  return (
    <ActiveFiltersBoxContainer>
      <TopLayer>
        <TopLayerLeft>
          <Title>{title}</Title>
        </TopLayerLeft>
        <TopLayerRight></TopLayerRight>
      </TopLayer>
      <FiltersContainer>
        {filters?.map((filter, i) => (
          <FilterChip
            key={filter.id || i}
            label={filter?.content?.menuTitle ? filter.content.menuTitle : filter.name}
            icon={filter?.content?.icon}
            isSelected
            onClick={() => onRemove(filter)}
          />
        ))}
      </FiltersContainer>
    </ActiveFiltersBoxContainer>
  );
}
