import styled, { css } from "styled-components";
import { useCombobox } from "downshift";
import PinpointResultsContainer from "@/components/Pinpoint/PinpointResultsContainer";
import { useFloating, offset } from "@floating-ui/react";
import { useMemo, useState } from "react";
import SearchIcon from "@/assets/svg/search.svg";
import mq from "@/utils/mq";

const OuterContainer = styled.div`
  width: 100%;
  height: 56px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchPositioner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Container = styled.div`
  height: auto;
  width: 100%;
  z-index: 55;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 660px;
  border-radius: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: var(--neutral-0, #fff);
  //box-shadow: 6px 6px 13px 0px rgba(0, 0, 0, 0.1);
  ${mq.mobile(css`
    max-width: 100vw;
  `)};
`;

const SearchSpacer = styled.div`
  width: 100%;
  height: 56px;
`;

const SearchInputWrapper = styled.div`
  padding: 0px 24px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
  height: 56px;
  ${mq.mobile(css`
    padding: 0 16px;
    height: 48px;
  `)};
`;

const SearchInputIcon = styled.div`
  width: 16px;
  height: 16px;
  color: rgba(0, 0, 12, 0.3);
`;

const SearchInputField = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border: none;
  outline: none;
  background: transparent;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  &::placeholder {
    color: rgba(0, 0, 12, 0.3);
  }
`;

export default function Pinpoint({ onSearch, placeholder = "Search", isLoading, results, selectedResults, onSelect }) {
  const [displayResults, setDisplayResults] = useState(true);
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getLabelProps,
    getToggleButtonProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    setInputValue,
    closeMenu,
  } = useCombobox({
    items: results,
    onSelectedItemChange: ({ selectedItem }) => {
      setInputValue("");
      setDisplayResults(false);
      onSelect(selectedItem);
    },
    onInputValueChange: ({ inputValue }) => {
      onSearch(inputValue);
    },
    selectedItem: null,
  });

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom",
    open: isOpen,
    onOpenChange: (open) => {
      if (!open) {
        closeMenu();
      }
    },
    middleware: [offset({ mainAxis: 16, alignmentAxis: 0 })],
    transform: false,
  });

  useMemo(() => {
    if (isOpen && results.length > 0) {
      setDisplayResults(true);
    } else {
      setDisplayResults(false);
    }
  }, [isOpen, results]);

  return (
    <OuterContainer>
      <SearchSpacer />
      <SearchPositioner>
        <Container>
          <SearchInputWrapper>
            <SearchInputIcon>
              <SearchIcon />
            </SearchInputIcon>
            <SearchInputField placeholder={placeholder} ref={refs.setReference} {...getInputProps()} />
          </SearchInputWrapper>

          <PinpointResultsContainer
            results={results}
            highlightedIndex={highlightedIndex}
            onSelect={onSelect}
            getMenuProps={getMenuProps}
            isOpen={displayResults}
            closeMenu={closeMenu}
            floatingStyles={floatingStyles}
            selectedItem={selectedItem}
          />
        </Container>
      </SearchPositioner>
    </OuterContainer>
  );
}
