import NextLazyImage from "@/components/Core/NextLazyImage";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";

const CardsSliderItemContainer = styled.div`
  width: 405px;
  height: 740px;
  border-radius: 8px;

  position: relative;
`;

const TextContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 32px;
`;

const BodyText = styled.div<{ $size: "normal" | "large" | "small" }>`
  font-weight: 600;
  font-family: var(--font-inter);
  font-size: 26px;
  line-height: 1.4;
  ${(props) =>
    props.$size === "large" &&
    css`
      font-size: 30px;
    `}

  ${(props) =>
    props.$size === "small" &&
    css`
      font-size: 21px;
    `};
`;

const Pretext = styled.div`
  font-weight: 600;
  font-family: var(--font-inter);
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 0.75rem;
  opacity: 0.9;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  bottom: 0;
  z-index: 0;
  left: 0;
  border-radius: 8px;
`;

export default function CardsSliderItem({ item }) {
  return (
    <CardsSliderItemContainer style={{ background: item.backgroundColor.color, color: item.textColor.color }}>
      <TextContent>
        {item.pretext && <Pretext>{item.pretext}</Pretext>}
        <BodyText $size={item.bodyTextSize}>{render(item.body)}</BodyText>
      </TextContent>
      <BackgroundContainer>
        <img src={item.backgroundImage.filename} alt={item.backgroundImage.alt} key={item.backgroundImage.filename} />
      </BackgroundContainer>
    </CardsSliderItemContainer>
  );
}
