import ContentWrapper from "@/components/Core/ContentWrapper";
import styled, { css } from "styled-components";
import mq from "@/utils/mq";
import { motion } from "motion/react";
import IconRenderer from "@/components/Shared/IconRenderer";
import CtaRenderer from "@/components/Shared/CtaRenderer";
import { render } from "storyblok-rich-text-react-renderer";
import FAQItem from "@/components/Faqs/FAQItem";
import FaqJsonLd from "@/components/Shared/JsonLD/FaqJsonLd";

const SectionTitleContainer = styled.div<{ $spacing?: "standard" | "expanded" | "tight" | "tabMode" }>`
  width: 100%;
  max-width: 1440px;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-items: center;
  align-items: center;
  padding-bottom: 3rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  ${mq.mobile(css`
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  `)};
  ${(props) =>
    props.$spacing === "expanded" &&
    css`
      gap: 1.5rem;
    `}
  ${(props) =>
    props.$spacing === "tight" &&
    css`
      gap: 0.5rem;
    `}
  
    gap: 22px;
  align-items: flex-start;
  ${(props) =>
    props.$spacing === "expanded" &&
    css`
      gap: 32px;
    `}
  ${(props) =>
    props.$spacing === "tight" &&
    css`
      gap: 16px;
    `}
    ${(props) =>
    props.$spacing === "tabMode" &&
    css`
      gap: 32px;
      padding-bottom: 32px;
      ${mq.mobile(css`
        padding-bottom: 24px;
      `)};
    `}
    ${mq.mobile(css`
    max-width: 100vw;
    overflow: hidden;
  `)};
`;

const STUpperLayer = styled.div<{ $spacing?: "standard" | "expanded" | "tight" | "tabMode" }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(223 227 230/1);
  padding-bottom: 8px;
  ${(props) =>
    props.$spacing === "expanded" &&
    css`
      padding-bottom: 12px;
    `}
  ${(props) =>
    props.$spacing === "tight" &&
    css`
      padding-bottom: 6px;
    `}
  ${(props) =>
    props.$spacing === "tabMode" &&
    css`
      padding-bottom: 12px;
      ${mq.mobile(css`
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      `)};
    `}
`;

const STUpperLayerLabelSection = styled.div<{ $spacing?: "standard" | "expanded" | "tight" | "tabMode" }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  ${(props) =>
    props.$spacing === "tabMode" &&
    css`
      ${mq.mobile(css`
        border-bottom: 1px solid rgb(223 227 230/1);
        padding-bottom: 6px;
        width: 100%;
      `)};
    `}
`;

const LabelIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5c5866;
`;

const LabelText = styled.div`
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-pp-neue);
  color: var(--PP-Section-Title, #4b5563);
  line-height: 13px;
`;

const STUpperLayerCallToActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
`;

const STContentContainer = styled.div`
  width: 100%;
  max-width: var(--max-content);
  display: grid;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  position: relative;
  gap: 0rem 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-items: start;
  align-items: start;
  @media (min-width: 768px) {
    gap: 0 4rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${mq.mobile(css`
    display: flex;
    flex-direction: column;
    gap: 3rem;
  `)};
`;

const STTitle = styled.div`
  color: var(--PP-Text-Primary);
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin-top: -2px;
  margin-bottom: 16px;
  max-width: 436px;
  color: var(--PP-Text-Primary);
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  ${mq.mobile(css`
    font-size: 24px;
    line-height: 32px;
    max-width: 100%;
  `)};
`;

const STBody = styled.div`
  color: #677076;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 24px;
  max-width: 436px;
  ${mq.mobile(css`
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
    max-width: 100%;
  `)};
`;

const TabsContainer = styled.div`
  display: flex;
  //justify-content: flex-end;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  position: relative;
  &::before,
  &::after {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    width: 20px;
    pointer-events: none;
    z-index: 1;
  }
  &::before {
    left: 0;
    background: linear-gradient(to right, var(--background-color, #ffffff) 0%, rgba(255, 255, 255, 0) 100%);
  }
  &::after {
    right: 0;
    background: linear-gradient(to left, var(--background-color, #ffffff) 0%, rgba(255, 255, 255, 0) 100%);
  }
  align-items: center;
  gap: 10px;
  max-width: 300px;

  flex: none;
  ${mq.mobile(css`
    margin-top: 32px;
    justify-content: flex-start;
  `)};
`;

const StTab = styled.div<{ $active: boolean }>`
  color: #a1a1aa;
  font-family: var(--font-inter);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  padding: 0 8px;
  white-space: nowrap;

  overflow-y: visible;
  ${(props) =>
    props.$active &&
    css`
      color: #52525b;
    `}
`;

const ActiveIndicator = styled(motion.div)`
  width: 100%;
  height: 1px;
  background: #52525b;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -13px;
`;

const LeftContent = styled.div``;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;
`;

const Ctas = styled.div``;

export default function Faqs({ pretext, icon, title, description, ctas, faqItems, theme, enableMetaJsonLD }) {
  return (
    <ContentWrapper theme={theme}>
      {enableMetaJsonLD !== false && <FaqJsonLd data={faqItems} />}
      <SectionTitleContainer>
        <STUpperLayer>
          <STUpperLayerLabelSection>
            <LabelIcon>
              <IconRenderer icon={icon} />
            </LabelIcon>
            <LabelText>{pretext}</LabelText>
          </STUpperLayerLabelSection>
          <STUpperLayerCallToActions></STUpperLayerCallToActions>
        </STUpperLayer>
        <STContentContainer>
          <LeftContent>
            <STTitle>{render(title)}</STTitle>
            <STBody>{render(description)}</STBody>
            <Ctas>
              <CtaRenderer ctas={ctas} />
            </Ctas>
          </LeftContent>
          <RightContent>{faqItems?.map((item, i) => <FAQItem key={i} item={item} />)}</RightContent>
        </STContentContainer>
      </SectionTitleContainer>
    </ContentWrapper>
  );
}
