import Button from "@/components/Buttons/Button";
import IconButton from "@/components/Buttons/IconButton";
import styled from "styled-components";
import ShareIcon from "@/assets/svg/share.svg";
import Search from "@/assets/svg/search.svg";

const ControlsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  display: none;
`;

export default function BlogCollectionHeroControls({ canShare, shareLink }) {
  const handleShare = () => {};

  return (
    <ControlsContainer style={{ justifyContent: canShare ? "space-between" : "flex-start" }}>
      {/*<IconButton style='primary' action={handleShare} icon={<Search />} />
      {canShare && (
        <Button
          style='primary'
          label='Share Collection'
          action={handleShare}
          iconPosition={"left"}
          icon={<ShareIcon />}
        />
      )}*/}
    </ControlsContainer>
  );
}
