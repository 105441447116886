import ContentWrapper from "@/components/Core/ContentWrapper";
import ResourceItemCard from "@/components/ResourceCentre/ResourceCentreContent/ResourceItemCard";
import mq from "@/utils/mq";
import styled, { css } from "styled-components";
import { motion, AnimatePresence, LayoutGroup } from "motion/react";
import BackgroundImage from "@/assets/svg/noResultBackground.svg";
import NoResultIcon from "@/assets/svg/noResultSearchIcon.svg";

const ResourceCentreGridContainer = styled.div`
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  will-change: auto;
`;

const ResourceCentreGrid = styled(motion.div)`
  margin: 0 auto;
  padding: 46px 0;
  display: grid;
  width: 100%;
  max-width: 1180px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  grid-row-gap: 46px;
  will-change: auto;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
  ${mq.mobile(css`
    padding-top: 26px;
  `)};
`;

const CardWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  will-change: auto;
`;

const NoResultsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 80px 20px;
  max-width: 1180px;
  margin: 0 auto;
  width: 100%;
  background-color: transparent;
  position: relative;
  will-change: auto;
`;

const NoResultsTitle = styled.h2`
  color: var(--PP-Text-Primary);
  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 4px;
  z-index: 4;
  margin-top: 18px;
`;

const NoResultsDescription = styled.p`
  color: var(--PP-Text-Secondary);
  font-size: 14px;
  line-height: 20px;
  max-width: 460px;
  margin: 0 auto;
  z-index: 4;
`;

const NoResultsBackgroundImage = styled.div`
  position: absolute;
  top: -178px;
  left: -178px;
  width: 400px;
  height: 400px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dae3e9;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const NoResultsIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: relative;
`;

const NoResultsIconInnerContainer = styled.div`
  width: 46px;
  height: 46px;
  svg {
    width: 46px;
    height: 46px;
    z-index: 4;
  }
`;

const cardVariants = {
  hidden: {
    opacity: 0,
    y: 20,
    scale: 0.95,
  },
  visible: (index: number) => ({
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      delay: index * 0.075,
      ease: [0.23, 1, 0.32, 1],
    },
  }),
  exit: {
    opacity: 0,
    scale: 0.9,
    transition: {
      duration: 0.2,
      ease: [0.23, 1, 0.32, 1],
    },
  },
};

const noResultsVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: [0.23, 1, 0.32, 1],
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2,
      ease: [0.23, 1, 0.32, 1],
    },
  },
};

export default function ResourceCentreContent({ resources }: { resources: any }) {
  return (
    <ContentWrapper backgroundColor={resources?.length !== 0 ? "#FFFFFF" : "#F3F4F6"}>
      <ResourceCentreGridContainer>
        <LayoutGroup>
          <AnimatePresence mode='wait'>
            {resources?.length === 0 ? (
              <NoResultsContainer
                key='no-results'
                variants={noResultsVariants}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <NoResultsIconContainer>
                  <NoResultsBackgroundImage>
                    <BackgroundImage />
                  </NoResultsBackgroundImage>
                  <NoResultsIconInnerContainer>
                    <NoResultIcon />
                  </NoResultsIconInnerContainer>
                </NoResultsIconContainer>
                <NoResultsTitle>No resources found</NoResultsTitle>
                <NoResultsDescription>
                  {`We couldn't find any resources matching your current filters. Try adjusting your search criteria or
                  removing some filters.`}
                </NoResultsDescription>
              </NoResultsContainer>
            ) : (
              <ResourceCentreGrid
                key='resource-grid'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <AnimatePresence mode='popLayout'>
                  {resources &&
                    resources?.length > 0 &&
                    resources?.map((resource: any, index: number) => (
                      <CardWrapper
                        key={resource._uid}
                        variants={cardVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        custom={index}
                        layout>
                        <ResourceItemCard
                          thumbnail={resource.thumbnail}
                          fileType={resource.fileType}
                          tags={resource.tags}
                          description={resource.description}
                          title={resource.title}
                          file={resource.file}
                          contentWall={resource.contentWall}
                        />
                      </CardWrapper>
                    ))}
                </AnimatePresence>
              </ResourceCentreGrid>
            )}
          </AnimatePresence>
        </LayoutGroup>
      </ResourceCentreGridContainer>
    </ContentWrapper>
  );
}
