import ContentWrapper from "@/components/Core/ContentWrapper";
import CaseStudyCard from "@/components/FilteredCaseStudies/CaseStudyCard";
import { useFilteredCaseStudies } from "@/hooks/useFilteredCaseStudies";
import { AlertCircle } from "lucide-react";
import Link from "next/link";
import styled, { css } from "styled-components";

const FilteredCaseStudiesContainer = styled.div<{ $count: number }>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(354px, 1fr));
  grid-row-gap: 16px;
  grid-column-gap: 8px;
  padding-bottom: 4rem;
  max-width: 100vw;
  ${(props) =>
    props.$count === 1 &&
    css`
      grid-template-columns: 354px;
    `}
`;

const LoadingMessage = styled.p`
  // Add your styles here
`;

const ErrorMessage = styled.p`
  // Add your styles here
`;

const NoCaseStudiesFound = styled.div`
  padding: 16px;
  background: var(--Light300);
  width: 500px;
  max-width: 100%;
  color: var(--PP-Text-Secondary);
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border: 1px solid #a6b8a827;
  border-radius: 4px;
`;

const NoCaseStudiesFoundContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function FilteredCaseStudies({ activeSectors }) {
  const {
    data: filteredCaseStudies,
    isLoading,
    error,
  } = useFilteredCaseStudies(activeSectors?.map((sector) => sector.uuid));

  if (isLoading) {
    return (
      <ContentWrapper>
        <div></div>
      </ContentWrapper>
    );
  }

  if (error) {
    return <ErrorMessage>Error loading case studies: {error.message}</ErrorMessage>;
  }

  if (filteredCaseStudies?.length === 0) {
    return (
      <ContentWrapper>
        <NoCaseStudiesFoundContainer>
          <NoCaseStudiesFound>
            <AlertCircle />
            No case studies found
          </NoCaseStudiesFound>
        </NoCaseStudiesFoundContainer>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <FilteredCaseStudiesContainer $count={filteredCaseStudies?.length}>
        {filteredCaseStudies?.map((caseStudy) => (
          <Link href={caseStudy.full_slug} key={caseStudy.id} style={{ textDecoration: "none" }}>
            <CaseStudyCard caseStudy={caseStudy} />
          </Link>
        ))}
      </FilteredCaseStudiesContainer>
    </ContentWrapper>
  );
}
