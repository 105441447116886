import NextLazyImage from "@/components/Core/NextLazyImage";
import { createPortal } from "react-dom";
import { useSbWikiCard } from "@/hooks/useSbWikiCard";
import { safePolygon, useHover, useInteractions } from "@floating-ui/react";
import { offset } from "@floating-ui/react";
import { useFloating } from "@floating-ui/react";
import { useState } from "react";
import styled from "styled-components";
import { render } from "storyblok-rich-text-react-renderer";
import CtaRenderer from "@/components/Shared/CtaRenderer";
import { motion } from "motion/react";

const WikiCardContainer = styled.div`
  width: 280px;
  height: auto;
  max-width: 100vw;
  z-index: 1000;
`;

const WikiCardInnerContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: auto;
  background-color: #eee;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: var(--neutral-0, #fff);

  z-index: 1000;
`;

const WikiCardImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  padding: 4px;
  img {
    border-radius: 10px;
  }
`;

const WikiCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const WikiCardBody = styled.span`
  color: var(--PP-Text-Secondary, #677076);
  font-size: 0.8em;
  line-height: 1.25em;
  font-weight: 500;
  text-wrap: pretty;
  max-height: 400px;
  overflow-y: auto;
`;

const CtaContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding-top: 10px;
`;

export default function WikiCardItem({ refs, floatingStyles, getFloatingProps, data }) {
  return createPortal(
    <WikiCardContainer ref={refs.setFloating} style={{ ...floatingStyles, left: 0 }} {...getFloatingProps()}>
      <WikiCardInnerContainer
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 10 }}
        transition={{ duration: 0.2 }}>
        {data[0]?.content?.thumbnail?.filename && (
          <WikiCardImageContainer>
            <NextLazyImage src={data[0].content.thumbnail.filename} alt={data[0].content.thumbnail.alt} fill />
          </WikiCardImageContainer>
        )}
        <WikiCardContentContainer>
          <WikiCardBody>{render(data[0].content.description)}</WikiCardBody>
          {data[0].content.cta && data[0].content.cta.length > 0 && (
            <CtaContainer>
              <CtaRenderer ctas={data[0].content.cta} />
            </CtaContainer>
          )}
        </WikiCardContentContainer>
      </WikiCardInnerContainer>
    </WikiCardContainer>,
    document.body,
  );
}
