import styled, { css } from "styled-components";
import ArrowToTopRight from "@/assets/svg/arrowToTopRight.svg";
import CtaRenderer from "@/components/Shared/CtaRenderer";
import mq from "@/utils/mq";
import IconRenderer from "@/components/Shared/IconRenderer";

const BentoTwoContainer = styled.div`
  width: 100%;
  padding: 34px 24px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #dddfe1;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: space-between;
  background-color: var(--PrimaryAccent);
  flex-direction: column;
  gap: 32px;
  @media (max-width: 1200px) {
    padding: 20px 22px;
    max-height: 475px;
  }
  ${mq.mobile(css`
    gap: 16px;
    padding: 16px;
  `)};
`;

const TopLayer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: space-between;
  flex-direction: column;
  height: 100%;
`;

const Decorator = styled.div<{ $customIcon: boolean }>`
  color: #008081;
  border-radius: 60px;
  background: #fff;

  display: flex;
  width: 42px;
  min-width: 42px;
  min-height: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 22px;
  svg {
    width: ${({ $customIcon }) => ($customIcon ? "24px" : "15px")};
    height: ${({ $customIcon }) => ($customIcon ? "24px" : "15px")};
  }
  ${mq.mobile(css<{ $customIcon: boolean }>`
    --size: 32px;
    min-width: var(--size);
    min-height: var(--size);
    height: var(--size);
    width: var(--size);
    margin-bottom: 16px;
    svg {
      width: 11px;
      height: 11px;
      width: ${({ $customIcon }) => ($customIcon ? "18px" : "11px")};
      height: ${({ $customIcon }) => ($customIcon ? "18px" : "11px")};
    }
  `)};
`;

const Title = styled.div`
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 16px;
  p {
    margin: 0;
  }
  @media (max-width: 1200px) {
    font-size: 30px;
    line-height: 34px;
  }
  ${mq.mobile(css`
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 8px;
  `)};
`;

const Description = styled.div`
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  max-width: 90%;
  letter-spacing: 0.4px;
  max-width: 100%;
  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export default function BentoTwo({ title, description, icon, cta }) {
  return (
    <BentoTwoContainer>
      <TopLayer>
        <Decorator $customIcon={icon && icon.length > 0}>
          {icon && icon.length > 0 ? <IconRenderer icon={icon} /> : <ArrowToTopRight />}
        </Decorator>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TopLayer>
      <CtaRenderer ctas={cta} />
    </BentoTwoContainer>
  );
}
