import styled from "styled-components";
import CollectionIcon from "@/assets/svg/collections.svg";
import { css } from "styled-components";
import mq from "@/utils/mq";
import Link from "next/link";

const CollectionPillWrapper = styled.div`
  max-width: 1136px;
  margin: 0 auto;
`;

const CollectionPill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #e8e8e8;
  background: var(--Light300);
  gap: 10px;
  padding: 4px 18px;
  width: fit-content;
`;

const IconContainer = styled.div`
  width: 12px;
  height: 12px;
  color: inherit;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Label = styled.div`
  color: #677076;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 22px;
  span {
    color: var(--PP-Text-Primary);
  }
  ${mq.mobile(css`
    white-space: normal;
  `)};
`;

const BlogCollectionPill = ({ collection }) => {
  return (
    <Link href={collection?.full_slug} style={{ textDecoration: "none", color: "unset" }}>
      <CollectionPill>
        <IconContainer>
          <CollectionIcon />
        </IconContainer>
        <Label>
          Part of the collection <span>{collection?.content?.title}</span>
        </Label>
      </CollectionPill>
    </Link>
  );
};

export default BlogCollectionPill;
