import ContentWrapper from "@/components/Core/ContentWrapper";
import { BREAKPOINT_SM } from "@/constants";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import styled, { css } from "styled-components";

const BlogRootDynamicContentContainer = styled.div`
  width: 100%;
`;

const BlogRootDynamicContentWrapper = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 46px 0;
  display: flex;
  flex-direction: column;
  gap: 46px;
`;

export default function BlogRootDynamicContent({ blok }: { blok: any }) {
  return (
    <BlogRootDynamicContentContainer>
      <BlogRootDynamicContentWrapper>
        <BlokRenderer blok={blok} key={blok?._uid} />
      </BlogRootDynamicContentWrapper>
    </BlogRootDynamicContentContainer>
  );
}
