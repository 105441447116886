import { validateAndNormalizeUrl } from "@/lib/utils/validateUrl";

interface Breadcrumb {
  path: string;
  title: string;
}

interface ParsedBreadcrumb {
  name: string;
  item: string;
}

interface GenerateSeoBreadcrumbsParams {
  breadcrumbs: Breadcrumb[];
  baseUrl: string;
  currentPath: string;
  pageTitle?: string;
}

export function generateSeoBreadcrumbs({
  breadcrumbs,
  baseUrl,
  currentPath,
  pageTitle,
}: GenerateSeoBreadcrumbsParams): ParsedBreadcrumb[] {
  const { normalizedUrl: validatedBaseUrl } = validateAndNormalizeUrl(baseUrl);
  const parsedBreadcrumbs: ParsedBreadcrumb[] = [];

  // Add home breadcrumb if first path is not root
  if (breadcrumbs[0]?.path !== "/") {
    parsedBreadcrumbs.push({
      name: "Home",
      item: validatedBaseUrl,
    });
  }

  // Get the current route's last segment without query parameters
  const currentSegment = currentPath.split("?")[0].split("/").filter(Boolean).pop() || "";

  // Process each breadcrumb
  breadcrumbs.forEach((breadcrumb) => {
    let cleanPath = breadcrumb.path.replace(baseUrl, "");
    // Remove trailing slash and URL parameters
    cleanPath = cleanPath.replace(/\/$/, "").split("?")[0];

    parsedBreadcrumbs.push({
      name: breadcrumb.title,
      item: validateAndNormalizeUrl(`${validatedBaseUrl}${cleanPath}`).normalizedUrl,
    });
  });

  // Check if the last breadcrumb matches the current route segment
  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
  const lastBreadcrumbSegment = lastBreadcrumb?.path.split("/").filter(Boolean).pop() || "";
  const lastBreadcrumbName = lastBreadcrumb?.title || "";

  // If they don't match, add the current page as the last breadcrumb
  if (currentSegment && lastBreadcrumbSegment !== currentSegment && lastBreadcrumbName !== currentSegment) {
    parsedBreadcrumbs.push({
      name: pageTitle || currentSegment,
      item: `${validatedBaseUrl}${currentPath.replace(/^\//, "")}`,
    });
  }

  return parsedBreadcrumbs;
}
