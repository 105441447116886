import styled from "styled-components";
import ChevronRight from "@/assets/svg/chevronRight.svg";
import Link from "next/link";
import parseStoryblokLink from "@/utils/parseStoryblokLink";
import { useState } from "react";
import HubspotForm from "@/components/HubspotForm/HubspotForm";

const ContactUsHeroActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 8px;
  background: #f3f4f6;
  padding: 22px;
`;

const TopLayer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ActionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const Label = styled.h3`
  color: #4b5563;
  font-family: var(--font-pp-neue);
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
`;

const Text = styled.p`
  color: var(--PP-Text-Primary, #10181c);
  font-family: var(--font-pp-neue);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

const ActionChevron = styled.div`
  width: 16px;
  height: 16px;
  color: #10181c;
  svg {
    color: #10181c;
  }
`;

const BottomLayer = styled.div`
  width: 100%;
`;

export default function ContactUsFormAction({ hubspotFormId }) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <ContactUsHeroActionWrapper>
      <TopLayer onClick={toggleExpanded}>
        <ActionContent>
          <Text>Write to us</Text>
        </ActionContent>
      </TopLayer>

      <BottomLayer>
        <HubspotForm hubspotFormID={hubspotFormId} />
      </BottomLayer>
    </ContactUsHeroActionWrapper>
  );
}
