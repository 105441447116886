import SectionContentWrapper from "@/components/Shared/SectionContentWrapper";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import StoryItemMediaHandler from "@/components/CaseStudyStoriesSlider/CaseStudyStoryItem/StoryItemMediaHandler";
import CaseStudyStoriesSliderItemContentMobile from "@/components/CaseStudyStoriesSlider/CaseStudyStoriesSliderItemContentMobile";
import { themeProvider } from "@/utils/ThemeProvider";
import type { Swiper as SwiperType } from "swiper";

const StoriesMobileWrapper = styled.div`
  width: 100%;
  padding: 0 32px;
`;

const GalleryItem = styled.div`
  width: 100%;
  aspect-ratio: 2.5/3;
  background-color: #eee;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SLIDE_DURATION = 5000;
const PROGRESS_UPDATE_INTERVAL = 16; // Matching typical 60fps refresh rate
const INCREMENT_AMOUNT = (10 * PROGRESS_UPDATE_INTERVAL) / SLIDE_DURATION;

export default function CaseStudyStoriesSliderMobile({
  sectionTitle,
  stories,
  theme,
  progressionSpeed = { value: 1.1 },
  slowProgressionOnHover,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [count, setCount] = useState(0);
  const swiperRef = useRef<SwiperType>(undefined);
  const progressIntervalRef = useRef<NodeJS.Timeout>(undefined);
  const isTransitioning = useRef(false);

  const goToNextSlide = useCallback(() => {
    if (swiperRef.current && !isTransitioning.current) {
      isTransitioning.current = true;
      swiperRef.current.slideNext();
      setTimeout(() => {
        isTransitioning.current = false;
      }, 300);
    }
  }, []);

  const updateProgress = useCallback(() => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }

    let currentCount = 0;
    setCount(0);

    progressIntervalRef.current = setInterval(() => {
      currentCount += INCREMENT_AMOUNT;
      if (currentCount >= 10) {
        currentCount = 0;
        goToNextSlide();
      }
      setCount(currentCount);
    }, PROGRESS_UPDATE_INTERVAL);
  }, [goToNextSlide]);

  const handlePauseResume = useCallback(() => {
    setIsPaused((prev) => !prev);
  }, []);

  // Initialize progress timer
  useEffect(() => {
    if (!isPaused) {
      updateProgress();
    }
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, [isPaused, updateProgress]);

  // Handle slide changes
  const handleSlideChange = useCallback(
    (swiper: SwiperType) => {
      const newIndex = swiper.realIndex;
      setActiveIndex(newIndex);
      if (!isPaused) {
        updateProgress();
      }
    },
    [isPaused, updateProgress],
  );

  // Initialize Swiper
  const handleSwiperInit = useCallback((swiper: SwiperType) => {
    swiperRef.current = swiper;
    setActiveIndex(swiper.realIndex);
  }, []);

  return (
    <SectionContentWrapper sectionTitle={sectionTitle} theme={theme} noMobilePadding mobilePaddingTitle>
      <Swiper
        spaceBetween={8}
        slidesPerView='auto'
        centeredSlides
        loop={true}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        onSwiper={handleSwiperInit}
        onSlideChange={handleSlideChange}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}>
        {stories?.map((story, i) => (
          <SwiperSlide key={i} style={{ width: "calc(100% - 44px)" }}>
            <GalleryItem>
              <StoryItemMediaHandler
                onClick={() => {
                  if (i === activeIndex) {
                    //console.log("clicked active slide");
                  } else {
                    swiperRef.current?.slideTo(i);
                  }
                }}
                active={i === activeIndex}
                isHovered={false}
                backgroundMedia={story.backgroundMedia[0]}
                backgroundOverlay={story.backgroundOverlay[0]}
                brandImage={story.brandImage}
                brandName={story.brandName}
                id={i}
                caseStudyLink={story.caseStudyLink}
                tags={story.tags}
                count={i === activeIndex ? count : 0}
                handlePauseResume={handlePauseResume}
                isPaused={isPaused}
              />
            </GalleryItem>
          </SwiperSlide>
        ))}
      </Swiper>
      <CaseStudyStoriesSliderItemContentMobile story={stories[activeIndex]} />
    </SectionContentWrapper>
  );
}
