import styled, { css } from "styled-components";
import { motion } from "motion/react";
import NextLazyImage from "@/components/Core/NextLazyImage";
import mq from "@/utils/mq";

const InactivePreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  background: transparent;
  z-index: 150;
  position: absolute;
  pointer-events: none;
  ${mq.mobile(css`
    display: none;
  `)};
`;

const AvatarContainer = styled(motion.div)`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const Title = styled(motion.div)`
  font-weight: 600;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 20px; /* 181.818% */
`;

export default function StoryItemInactivePreview({ active, brandName, brandImage }) {
  const avatarAnim = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.22,
      },
    },
  };

  const titleAnim = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25,
      },
    },
  };

  return (
    <InactivePreviewContainer>
      <AvatarContainer
        animate={!active ? "visible" : "hidden"}
        initial={!active ? "visible" : "hidden"}
        variants={avatarAnim}>
        <NextLazyImage src={brandImage.filename} alt={brandImage.alt} width={46} height={46} />
      </AvatarContainer>
      <Title animate={!active ? "visible" : "hidden"} initial={!active ? "visible" : "hidden"} variants={titleAnim}>
        {brandName}
      </Title>
    </InactivePreviewContainer>
  );
}
