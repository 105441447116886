import React, { ErrorInfo, ReactNode, useEffect, useState } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

const ErrorBound: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [state, setState] = useState<ErrorBoundaryState>({
    hasError: false,
  });

  const $componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
    console.warn({ error, errorInfo });
    setState({ hasError: true });
  };

  useEffect(() => {
    return () => {
      setState({ hasError: false });
    };
  }, []);

  if (state.hasError) {
    if (!process.env.NEXT_PUBLIC_PRODUCTION_SITE) {
      return (
        <div className='error-boundary' key={new Date().toISOString()}>
          <h1>Error displaying component.</h1>
          <p>Something went wrong displaying this component. This message will not appear on the live site.</p>
        </div>
      );
    }
    return null;
  }

  return (
    <React.Fragment>
      {React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<any>, {
          $componentDidCatch,
        }),
      )}
    </React.Fragment>
  );
};

export default ErrorBound;
