import { useRouter } from "next/router";
import { TailSpin } from "react-loader-spinner";
import styled, { css } from "styled-components";

import ArrowRight from "@/assets/svg/arrowRight.svg";

const ButtonIcon = styled.div`
  color: #737373d6;
  transition: var(--ease-out-expo) 0.4s;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const ButtonWrapper = styled.button<{ $disabled?: boolean }>`
  white-space: nowrap;
  border: none;
  color: #fff;
  outline: none;
  backdrop-filter: saturate(180%) blur(20px);
  background: rgba(22, 22, 23, 0.8);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  width: fit-content;
  padding: 6.5px 24px;
  transition: background var(--ease-out-expo) 0.4s;

  &:hover {
    background: rgba(22, 22, 23, 0.9);
  }

  ${ButtonIcon} {
    color: #171717;
    transition: var(--ease-out-expo) 0.4s;
  }

  ${(props) =>
    props.$disabled &&
    css`
      background: #f6f6f6;
      color: #1717171f;
      opacity: 0.5;
      cursor: not-allowed;
      ${ButtonIcon} {
        color: #737373d6;
      }
    `}
`;

const ButtonLabel = styled.span`
  margin: 0;
  padding: 0;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.084px;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  user-select: none;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8080d81f;
  backdrop-filter: blur(3px) contrast(90%);
  color: #4165dc;
`;

const ButtonArrow = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ButtonArrowInner = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #09090b;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  color: inherit;
`;

interface ButtonProps {
  label: string;
  link?: string;
  labelColor?: string;
  style: "primary" | "secondary" | "ghost";
  iconPosition: "hidden" | "left" | "right";
  icon?: React.ReactNode;
  action?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  loading?: boolean;
  enableArrow?: boolean;
}

export default function GlassButton({
  label,
  link,
  action,
  loading,
  labelColor,
  style,
  iconPosition,
  icon,
  enableArrow,
  disabled,
}: ButtonProps) {
  const router = useRouter();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (action) {
      action(e);
    }
    if (link) {
      router.push(link);
    }
  };

  return (
    <ButtonWrapper $disabled={disabled} onClick={handleClick}>
      {loading && (
        <LoadingOverlay>
          <TailSpin width={20} color='#fff' strokeWidth={6} />
        </LoadingOverlay>
      )}
      {iconPosition === "left" && icon && <IconContainer style={{ marginRight: "6px" }}>{icon}</IconContainer>}
      <ButtonLabel>{label}</ButtonLabel>
      {iconPosition === "right" && icon && <IconContainer style={{ marginLeft: "6px" }}>{icon}</IconContainer>}
      {iconPosition !== "right" && enableArrow && (
        <ButtonArrow>
          <ButtonArrowInner>
            <ArrowRight />
          </ButtonArrowInner>
        </ButtonArrow>
      )}
    </ButtonWrapper>
  );
}
