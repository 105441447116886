import styled from "styled-components";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { AudioVisualizer } from "react-audio-visualize";

import useAudioPlayerStore from "@/store/audioPlayerStore";
import { useEffect, useRef, useState } from "react";
import Button from "@/components/Buttons/Button";
import { Play, Rewind } from "lucide-react";
import { SeekBackward15Icon, SeekForward15Icon } from "@vidstack/react/icons";

const AudioPlayerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
`;

const AudioPlayerContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 1rem;
  border-radius: 50px;
  padding: 5px;
  z-index: 5;
  background-color: #eee;
`;

const ControlButton = styled.button`
  border: none;
  background: #fff;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SkipButtons = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const Title = styled.div`
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.4px;
  margin-right: 20px;
`;

export default function SiteAudioPlayer() {
  const { playbackSrc, isPlaying, setIsPlaying, setPlayer, audioTitle } = useAudioPlayerStore();
  const playerRef = useRef<AudioPlayer>(null);

  const [blob, setBlob] = useState<Blob | null>(null);
  const visualizerRef = useRef(null);

  useEffect(() => {
    const fetchBlob = async (url: string) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch the file");
        }
        const blob = await response.blob();
        setBlob(blob);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    };

    if (playbackSrc) {
      fetchBlob(playbackSrc);
    }
  }, [playbackSrc]);

  if (!playbackSrc) return null;
  return (
    <AudioPlayerWrapper>
      <AudioPlayerContainer>
        <ControlButton>
          <Play />
        </ControlButton>
        <Title>{audioTitle}</Title>
        <SkipButtons>
          <ControlButton>
            <SeekBackward15Icon />
          </ControlButton>
          <ControlButton>
            <SeekForward15Icon />
          </ControlButton>
        </SkipButtons>
      </AudioPlayerContainer>
    </AudioPlayerWrapper>
  );
}
