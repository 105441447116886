import LabelCta from "@/components/Buttons/LabelCta";
import NextLazyImage from "@/components/Core/NextLazyImage";
import mq from "@/utils/mq";
import React from "react";
import styled, { css } from "styled-components";

const StoryCardContainer = styled.div<{ $hasMaxWidth?: boolean }>`
  border-radius: 8px;
  border: 1px solid #e1e3e4;
  background: #fff;
  height: 405px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${(props) =>
    props.$hasMaxWidth &&
    css`
      max-width: 350px;
    `}
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const ThumbnailContainer = styled.div`
  height: 205px;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const MetaContainer = styled.div`
  padding: 16px;
  flex-grow: 1;
`;

const Title = styled.h3`
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  margin: 0;
  color: var(--PP-Text-Primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Excerpt = styled.p`
  margin: 0;
  overflow: hidden;
  color: #677076;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ActionContainer = styled.div`
  padding: 12px 16px;
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: 1;
`;

export default function CollectionStoryCard({
  story,
  customCtaLabel,
  hasMaxWidth,
}: {
  story: any;
  customCtaLabel?: string;
  hasMaxWidth?: boolean;
}) {
  return (
    <StoryCardContainer $hasMaxWidth={hasMaxWidth}>
      <ThumbnailContainer>
        <NextLazyImage src={story?.content?.thumbnail?.filename} alt={story?.content?.title} fill />
      </ThumbnailContainer>
      <ContentContainer>
        <MetaContainer>
          <Title>{story?.content?.title}</Title>
          <Excerpt>{story?.content?.description}</Excerpt>
        </MetaContainer>
        <ActionContainer>
          <LabelCta
            label={customCtaLabel ? customCtaLabel : "Read more"}
            color={"#007152"}
            rawLink={"/" + story?.full_slug}
            includeArrow
          />
        </ActionContainer>
      </ContentContainer>
    </StoryCardContainer>
  );
}
