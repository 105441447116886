import { motion, Variants } from "motion/react";
import React from "react";
import styled, { css } from "styled-components";

import ProgressBar from "@/components/CaseStudyStoriesSlider/CaseStudyStoryItem/StoryItemProgressBar";
import NextLazyImage from "@/components/Core/NextLazyImage";
import PlayIcon from "@/assets/svg/playIcon.svg";
import PauseIcon from "@/assets/svg/pauseIcon.svg";
import mq from "@/utils/mq";

const StoryControlsContainer = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

const ControlsTopLayer = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
`;

const ControlsRightLayer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
`;

const ControlsAvatarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const ControlsAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  user-select: none;
`;

const ControlsTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #fff;
  user-select: none;
  white-space: nowrap;
`;

const ControlsButtonContainer = styled.div`
  width: 20px;
  height: 20px;
  color: #fff;
  cursor: pointer;
`;

const ControlsTagsContainer = styled(motion.div)`
  display: flex;
  gap: 4px;
  ${mq.mobile(css`
    display: none;
  `)};
`;

const ControlsTagsContainerMobile = styled(motion.div)`
  display: none;
  ${mq.mobile(css`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  `)};
`;

const ControlsTag = styled(motion.div)`
  user-select: none;
  white-space: nowrap;
  border-radius: 4px;
  background: rgba(156, 154, 154, 0.6);
  backdrop-filter: blur(2px);
  padding: 0px 8px;
  color: var(--neutral-0, #fff);
  font-family: var(--font-pp-neue);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const tagContainerVariants: Variants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05,
      delay: 0.4,
    },
  },
};

const tagVariants: Variants = {
  hidden: { opacity: 0, y: 5 },
  visible: { opacity: 1, y: 0 },
};

export default function StoryItemControls({ active, brandImage, brandName, handlePauseResume, count, isPaused, tags }) {
  return (
    <StoryControlsContainer animate={{ opacity: active ? 1 : 0 }}>
      <ControlsTopLayer>
        <ControlsAvatarContainer>
          <ControlsAvatar>
            <NextLazyImage src={brandImage.filename} alt={brandImage.alt} width={32} height={32} />
          </ControlsAvatar>
          <ControlsTitle>{brandName}</ControlsTitle>
        </ControlsAvatarContainer>

        <ControlsRightLayer>
          <ControlsTagsContainer
            variants={tagContainerVariants}
            initial={active ? "visible" : "hidden"}
            animate={active ? "visible" : "hidden"}>
            {tags?.value?.map((tag, index) => (
              <ControlsTag key={index} variants={tagVariants}>
                {tag}
              </ControlsTag>
            ))}
          </ControlsTagsContainer>
          <ControlsButtonContainer onClick={handlePauseResume}>
            {isPaused ? <PlayIcon /> : <PauseIcon />}
          </ControlsButtonContainer>
        </ControlsRightLayer>
      </ControlsTopLayer>
      <ControlsTagsContainerMobile
        variants={tagContainerVariants}
        initial={active ? "visible" : "hidden"}
        animate={active ? "visible" : "hidden"}>
        {tags?.value?.map((tag, index) => (
          <ControlsTag key={index} variants={tagVariants}>
            {tag}
          </ControlsTag>
        ))}
      </ControlsTagsContainerMobile>
      <ProgressBar completedPercent={(count / 10) * 100} />
    </StoryControlsContainer>
  );
}
