import "@/styles/globals.css";
import { apiPlugin, storyblokInit } from "@storyblok/react";
import { DefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import NextAdapterPages from "next-query-params/pages";
import { QueryParamProvider } from "use-query-params";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import components from "@/lib/storyblok/components";
import { HubspotProvider } from "next-hubspot";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

import { GlobalStyle } from "@/styles/globalStyle";
import theme from "@/styles/theme";
import "@/styles/globals.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";

import "react-loading-skeleton/dist/skeleton.css";
import "react-indiana-drag-scroll/dist/style.css";

import { SITE_DESCRIPTION, SITE_HANDLE, SITE_TITLE, SITE_URL } from "@/constants";
import AudioPlayer from "@/components/AudioPlayer";

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  use: [apiPlugin],
  components: components,

  apiOptions: {
    rateLimit: 10,
    maxRetries: 20,
  },
});

const queryClient = new QueryClient();

export default function PlantplanApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { setPathPageView } = useTrackingCode();

  setPathPageView(router.asPath);

  return (
    <QueryParamProvider adapter={NextAdapterPages}>
      <QueryClientProvider client={queryClient}>
        <HubspotProvider>
          <ThemeProvider theme={theme}>
            <DefaultSeo
              title={SITE_TITLE}
              description={SITE_DESCRIPTION}
              openGraph={{
                type: "website",
                url: SITE_URL,
                site_name: SITE_TITLE,
                locale: "en_GB",
              }}
              twitter={{
                handle: SITE_HANDLE,
                cardType: "summary_large_image",
              }}
              dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_PRODUCTION_SITE !== "true"}
              dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_PRODUCTION_SITE !== "true"}
            />

            <Head>
              <meta name='viewport' content='width=device-width, initial-scale=1.0' />
              <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
              <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
              <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
              <link rel='manifest' href='/site.webmanifest' />
              <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
              <meta name='msapplication-TileColor' content='#da532c' />
              <meta name='language' content='en-GB' />
            </Head>
            <div className='Root'>
              <GlobalStyle />
              <Component {...pageProps} />
              <AudioPlayer />
            </div>
          </ThemeProvider>
        </HubspotProvider>
      </QueryClientProvider>
    </QueryParamProvider>
  );
}
