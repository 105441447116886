import { SectionTitleProvider } from "@/components/BlogRework/BlogContentItem";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";
import TickIcon from "@/assets/svg/tick.svg";
import MinusIcon from "@/assets/svg/minus.svg";
import InfoIcon from "@/assets/svg/info.svg";
import mq from "@/utils/mq";

const ProConListContainer = styled.aside`
  white-space: nowrap;
  border: none;
  color: #09090b;
  outline: none;
  border: 1px solid #e2e2e2;
  background: #f6f6f6;
  border-radius: 10px;
  padding: 24px;
  position: relative;
  ${mq.mobile(css`
    width: 100%;
    padding: 16px;
  `)};
`;

const SectionTitle = styled.h4`
  margin-bottom: 1rem;
`;

const ProConList = styled.ul`
  padding-left: 0px;
  margin-bottom: 0;
  margin-top: 0;
  box-sizing: border-box;
  list-style-type: none;
  li {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Title = styled.h3`
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1.25rem;
  color: #09090b;
  span {
    color: #52525c;
  }
`;

const ProConItemWrapper = styled.li`
  display: flex;
  align-items: center;
  gap: 0.65rem;
`;

const ProConIcon = styled.div<{ $isPro: boolean }>`
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  max-width: 17px;
  max-height: 17px;
  color: #09090b;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke-width: 1.5px;
    width: 100%;
    height: 100%;
  }
`;

const ProConLabel = styled.div`
  font-family: var(--font-inter);
  font-weight: 400;
  letter-spacing: -0.00666rem;
  font-size: 0.9rem;
  line-height: 1.5;
  white-space: normal;
  p,
  span {
    color: #52525c;
  }
  b {
    color: #09090b;
  }
  a {
    color: var(--PrimaryAccent);
    text-decoration: underline;
    font-weight: 500;
    text-underline-offset: 2px;
    span {
      color: inherit !important;
    }
  }
`;

const InfoIconWrapper = styled.div`
  position: absolute;
  display: flex;
  top: -24px;
  right: -16px;
  border-radius: 50%;
  padding: 6px;
  color: #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid #f9fafb;
  background: #497b55;
`;

const InfoIconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function BlogProConList({ blok }: { blok: any }) {
  const { productName, pros, cons } = blok;
  return (
    <ProConListContainer>
      <InfoIconWrapper>
        <InfoIconContainer>
          <InfoIcon />
        </InfoIconContainer>
      </InfoIconWrapper>
      <Title>
        {productName} <span>pros & cons</span>
      </Title>

      <ProConList>
        {pros?.map((pro: any) => <ProConItem key={pro._uid} content={pro.content} isPro />)}
        {cons?.map((con: any) => <ProConItem key={con._uid} content={con.content} isPro={false} />)}
      </ProConList>
    </ProConListContainer>
  );
}

const ProConItem = ({ content, isPro }: { content: any; isPro: boolean }) => {
  return (
    <ProConItemWrapper>
      <ProConIcon $isPro={isPro}>{isPro ? <TickIcon /> : <MinusIcon />}</ProConIcon>
      <ProConLabel>{render(content)}</ProConLabel>
    </ProConItemWrapper>
  );
};
