import styled from "styled-components";
import { useRouter } from "next/router";
import Button from "@/components/Buttons/Button";
import NextLazyImage from "@/components/Core/NextLazyImage";
import { useState, useRef } from "react";
import { motion } from "motion/react";
import DownloadIcon from "@/assets/svg/download.svg";
import { css } from "styled-components";
import mq from "@/utils/mq";

const ResourceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  will-change: auto;
`;

const ThumbnailContainer = styled(motion.div)`
  height: 240px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;
`;

const ResourceCardContent = styled.div``;

const ResourceCardMeta = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const MetaItem = styled.span`
  color: #4c7c56;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
`;

const MetaDivider = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #4c7c56;
  margin: 0 6px;
`;

const ResourceCardTitleContainer = styled.div``;

const ResourceCardTitle = styled.h3`
  margin: 0;
  margin-top: 8px;
  margin-bottom: 2px;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;

  color: var(--PP-Text-Primary);
  font-weight: 500;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ResourceCardIcon = styled.div``;

const ResourceCardDescription = styled.p`
  margin-bottom: 24px;
  overflow: hidden;
  color: #677076;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${mq.mobile(css`
    margin-bottom: 16px;
    margin-top: 4px;
  `)};
`;

const ResourceCardCta = styled.div``;

const HoveredContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
`;

const HoverLabel = styled(motion.span)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const HoverIcon = styled(motion.div)`
  --size: 20px;
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  z-index: 10;
`;

const containerAnim = {
  hidden: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    transition: {
      delay: 0.1,
    },
  },
  visible: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const labelAnim = {
  hidden: {
    opacity: 0,
    y: -15,
    transition: {
      delay: 0.03,
      ease: [0.26, 1, 0.5, 1],
    },
  },
  visible: {
    opacity: 1,
    y: [15, 0],
    transition: {
      delay: 0.125,
      ease: [0.26, 1, 0.5, 1],
    },
  },
};

const iconAnim = {
  hidden: {
    opacity: 0,
    y: -15,
    transition: {
      delay: 0.0,
      ease: [0.26, 1, 0.5, 1],
    },
  },
  visible: {
    opacity: 1,
    y: [15, 0],
    transition: {
      delay: 0.15,
      ease: [0.26, 1, 0.5, 1],
    },
  },
};

export default function ResourceItemCard({ thumbnail, fileType, tags, description, title, file, contentWall }) {
  const [hovered, setHovered] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const handleThumbnailClick = () => {
    if (buttonRef.current) {
      (buttonRef.current.firstChild as HTMLButtonElement).click();
    }
  };

  return (
    <ResourceCardContainer>
      <ThumbnailContainer
        onHoverStart={() => setHovered(true)}
        onHoverEnd={() => setHovered(false)}
        onClick={handleThumbnailClick}>
        <HoveredContainer
          animate={hovered ? "visible" : "hidden"}
          initial={hovered ? "visible" : "hidden"}
          variants={containerAnim}>
          <HoverIcon
            animate={hovered ? "visible" : "hidden"}
            initial={hovered ? "visible" : "hidden"}
            variants={iconAnim}>
            <DownloadIcon />
          </HoverIcon>
          <HoverLabel
            animate={hovered ? "visible" : "hidden"}
            initial={hovered ? "visible" : "hidden"}
            variants={labelAnim}>
            Download
          </HoverLabel>
        </HoveredContainer>
        <NextLazyImage src={thumbnail.filename} alt={title} fill style={{ backgroundColor: "#F3F4F6" }} />
      </ThumbnailContainer>
      <ResourceCardContent>
        <ResourceCardMeta>
          <MetaItem>{fileType}</MetaItem>
          {tags && tags?.length > 0 && <MetaDivider />}
          {tags && tags?.length > 0 && <MetaItem>{tags?.map((tag) => tag.name).join(", ")}</MetaItem>}
        </ResourceCardMeta>
        <ResourceCardTitleContainer>
          <ResourceCardTitle>{title}</ResourceCardTitle>
          <ResourceCardIcon></ResourceCardIcon>
        </ResourceCardTitleContainer>
        <ResourceCardDescription>{description}</ResourceCardDescription>
        <ResourceCardCta>
          <div ref={buttonRef}>
            <Button
              link={file?.filename}
              target='_blank'
              label='Download'
              style='primary'
              customIcon={<DownloadIcon width={12} height={12} />}
              iconPosition='right'
              contentWall={contentWall}
            />
          </div>
        </ResourceCardCta>
      </ResourceCardContent>
    </ResourceCardContainer>
  );
}
