import NextLazyImage from "@/components/Core/NextLazyImage";
import mq from "@/utils/mq";
import styled, { css } from "styled-components";

const FullWidthImageContainer = styled.div<{ $aspectRatio: string }>`
  position: relative;
  width: 100%;
  aspect-ratio: ${(props) => props.$aspectRatio};
  display: flow-root;
  border-radius: 8px;
  overflow: hidden;
`;

const InlineImageContainer = styled.div<{
  $aspectRatio: string;
  $position: string;
  $sideMargin: string;
  $size: string;
  $topOffset: string;
  $enableBoundaryBreak: boolean;
}>`
  position: relative;
  width: 100%;
  aspect-ratio: ${(props) => props.$aspectRatio};

  float: left;
  padding-left: 0;

  shape-outside: border-box;
  clip-path: border-box;
  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: ${(props) => props.$sideMargin}px;
  margin-top: ${(props) => props.$topOffset}px;
  max-width: ${(props) => props.$size}px;
  min-width: ${(props) => props.$size}px;
  border-radius: 8px;
  overflow: hidden;
  ${(props) =>
    props.$enableBoundaryBreak &&
    css`
      margin-left: -118px;
      @media (max-width: 1135px) {
        margin-left: 0px;
      }
    `};
  img {
    object-position: center;
    background-size: contain;
    object-fit: contain;
  }
  ${(props) =>
    props.$position === "right" &&
    css<{ $sideMargin: string; $enableBoundaryBreak: boolean }>`
      float: right;
      padding-left: ${(props) => props.$sideMargin}px;
      //margin-right: 0;
      ${(props) =>
        props.$enableBoundaryBreak &&
        css`
          margin-right: -118px;
          margin-left: 0;
          @media (max-width: 1135px) {
            margin-right: 0px;
          }
          @media (max-width: 1010px) {
            margin-right: 0px;
          }
          @media (max-width: 950px) {
            margin-right: 0;
          }
        `};
    `};
  @media (max-width: 900px) {
    float: unset;
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    min-width: unset;
    aspect-ratio: unset;

    img {
      max-width: unset !important;
    }
  }
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 8px;
`;

const sizes = {
  extraSmall: 200,
  small: 400,
  standard: 500,
  large: 600,
  extraLarge: 700,
};

function calculateAspectHeight(width, aspectRatio) {
  // Parse aspect ratio
  const [aspectWidth, aspectHeight] = aspectRatio.split("/")?.map(Number);

  // Calculate height
  return ((width * aspectHeight) / aspectWidth).toFixed(0) as unknown as number;
}

export default function BlogInlineImage({ asset, aspectRatio, mode, subtitle }) {
  if (mode && mode[0]?.component === "blogInlineImageFullWidthMode") {
    return (
      <FullWidthImageContainer $aspectRatio={aspectRatio}>
        <NextLazyImage src={asset.filename} alt={asset.alt} fill />
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </FullWidthImageContainer>
    );
  }
  if (mode && mode[0]?.component === "blogInlineImageInlineMode") {
    const { position, size, sideMargin, topOffset, enableBoundaryBreak } = mode[0];

    return (
      <InlineImageContainer
        $size={sizes[size]}
        $aspectRatio={aspectRatio}
        $position={position}
        $topOffset={topOffset?.value}
        $enableBoundaryBreak={enableBoundaryBreak}
        $sideMargin={sideMargin?.value}>
        <NextLazyImage
          src={asset.filename}
          alt={asset.alt}
          width={sizes[size]}
          height={calculateAspectHeight(sizes[size], aspectRatio)}
          layout='constrained'
        />
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </InlineImageContainer>
    );
  }
}
