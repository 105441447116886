import cssModule from "@vercel/turbopack-next/internal/font/local/cssmodule.module.css?{%22path%22:%22generateFonts.ts%22,%22import%22:%22%22,%22arguments%22:[{%22src%22:[{%22path%22:%22../assets/fonts/PPNeueMontreal-Book.otf%22,%22weight%22:%22400%22,%22style%22:%22normal%22},{%22path%22:%22../assets/fonts/PPNeueMontreal-Medium.otf%22,%22weight%22:%22500%22,%22style%22:%22normal%22},{%22path%22:%22../assets/fonts/PPNeueMontreal-Bold.otf%22,%22weight%22:%22700%22,%22style%22:%22normal%22},{%22path%22:%22../assets/fonts/PPNeueMontreal-Italic.otf%22,%22weight%22:%22400%22,%22style%22:%22italic%22}],%22display%22:%22swap%22,%22variable%22:%22--font-pp-neue-montreal%22}],%22variableName%22:%22ppNeueMontreal%22}";
const fontData = {
    className: cssModule.className,
    style: {
        fontFamily: "'ppNeueMontreal', 'ppNeueMontreal Fallback'",
        
    },
};

if (cssModule.variable != null) {
    fontData.variable = cssModule.variable;
}

export default fontData;
