import { create } from "zustand";

interface StoreState {
  playbackSrc: string;
  setPlaybackSrc: (newItem: any) => void;
  isPlaying: boolean;
  setIsPlaying: (newItem: any) => void;
  audioTitle: string;
  setAudioTitle: (newItem: any) => void;
  articleSlug: string;
  setArticleSlug: (newItem: any) => void;
  player: any;
  setPlayer: (newItem: any) => void;
}

const useAudioPlayerStore = create<StoreState>((set) => ({
  playbackSrc: "",
  setPlaybackSrc: (newItem) => set({ playbackSrc: newItem }),
  isPlaying: false,
  setIsPlaying: (newItem) => set({ isPlaying: newItem }),
  audioTitle: "",
  setAudioTitle: (newItem) => set({ audioTitle: newItem }),
  articleSlug: "",
  setArticleSlug: (newItem) => set({ articleSlug: newItem }),
  player: null,
  setPlayer: (newItem) => set({ player: newItem }),
}));

export default useAudioPlayerStore;
