import { useQuery } from "@tanstack/react-query";
import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID!,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY!,
);

const index = searchClient.initIndex("content_index");

interface SectorEntry {
  objectID: string;
  title: string;
  icon: any;
  _uid: string;
  content_type: string;
  [key: string]: any;
}

const fetchAllSectors = async (): Promise<SectorEntry[]> => {
  try {
    const { hits } = await index.search("", {
      hitsPerPage: 1000, // Adjust this number based on your total number of sectors
      filters: "content_type:sectorPage",
    });

    return hits as SectorEntry[];
  } catch (error) {
    console.error("Error fetching sector entries from Algolia:", error);
    throw error;
  }
};

export const useAllSectors = () => {
  return useQuery<SectorEntry[], Error>({
    queryKey: ["allSectors"],
    queryFn: fetchAllSectors,
  });
};
