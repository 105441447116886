import CtaRenderer from "@/components/Shared/CtaRenderer";
import mq from "@/utils/mq";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";

const BlogCallToActionOuterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`;

const BlogCallToActionWrapper = styled.div`
  min-height: 500px;
  border-radius: 0.75rem;
  padding: 1.5rem 3em;
  border: 1px solid #e2e2e2;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1000px) {
    padding: 1rem 1rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  line-height: 1.1;
  font-weight: 500;
  color: #09090b;
  margin-bottom: 1rem;
  text-align: center;
  @media (max-width: 1000px) {
    font-size: 1.5rem;
  }
`;

const BodyContent = styled.div`
  width: min(700px, 80%);
  margin: 0 auto;
  font-family: var(--font-inter);
  font-weight: 400;
  letter-spacing: -0.00666rem;
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: center;
  margin-bottom: 2rem;
  text-wrap: pretty;
  * {
    color: #52525c !important;
  }
  @media (max-width: 1000px) {
    font-size: 1rem;
  }
  ${mq.mobile(css`
    width: min(700px, 90%);
  `)};
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export default function BlogCallToAction({ blok }) {
  return (
    <BlogCallToActionOuterWrapper>
      <BlogCallToActionWrapper>
        <Title>{blok.title}</Title>
        <BodyContent>{render(blok.content)}</BodyContent>
        <CtaContainer>
          <CtaRenderer ctas={blok.ctas} />
        </CtaContainer>
      </BlogCallToActionWrapper>
    </BlogCallToActionOuterWrapper>
  );
}
