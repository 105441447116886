import IconRenderer from "@/components/Shared/IconRenderer";
import React from "react";
import { render } from "storyblok-rich-text-react-renderer";
import styled from "styled-components";

const CardGalleryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  background-color: var(--Light300);
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
`;

const IconOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
`;

const IconContainer = styled.div`
  width: 42px;

  svg {
    width: 100%;
    height: 100%;
    color: var(--PP-Text-Primary);
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--PP-Text-Primary);
`;

const Description = styled.div`
  font-size: 1rem;
  color: var(--PP-Text-Secondary);
`;

export default function CardGalleryItem({ item }) {
  if (!item.description) return null;

  return (
    <CardGalleryItemContainer>
      {item.icon && item.icon.length > 0 && (
        <IconOuterContainer>
          <IconContainer>
            <IconRenderer icon={item.icon} debug color={"#292f33"} size={42} nonFixedHeight />
          </IconContainer>
        </IconOuterContainer>
      )}
      <TextContainer>
        <Title>{item.title}</Title>
        <Description>{render(item.description)}</Description>
      </TextContainer>
    </CardGalleryItemContainer>
  );
}
