import NextLazyImage from "@/components/Core/NextLazyImage";
import CtaRenderer from "@/components/Shared/CtaRenderer";
import IconRenderer from "@/components/Shared/IconRenderer";
import SectionContentWrapper from "@/components/Shared/SectionContentWrapper";
import mq from "@/utils/mq";
import { useState } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";

const CardsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(368px, 1fr));
  grid-gap: 16px;
  width: 100%;

  justify-content: center;
  ${mq.mobile(css`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 8px;
  `)};
`;

const TopLayerImageContainer = styled.div`
  width: 100%;
  height: 200px;

  flex-grow: 1;
  margin-bottom: 24px;
  position: relative;
  @media (max-width: 370px) {
    height: 150px;
    margin-bottom: 14px;
  }
`;

const CardContentContainer = styled.div`
  flex-shrink: 0.1;
  padding: 0px 18px 24px 18px;
  ${mq.mobile(css`
    padding: 0px 14px 14px 14px;
  `)};
`;

const CardTitle = styled.div`
  color: var(--PP-Text-Primary, #10181c);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  transition: color 0.3s var(--ease-in-out-circ);
  ${mq.mobile(css`
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 4px;
  `)};
`;

const CardDescription = styled.div`
  color: var(--PP-Text-Secondary, #677076);
  font-family: var(--font-pp-neue);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  transition: color 0.3s var(--ease-in-out-circ);
  ${mq.mobile(css`
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  `)};
`;

const CtaContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e1e3e4;
`;

const TopLayerIconContainer = styled.div`
  padding: 38px 18px 8px 18px;
  ${mq.mobile(css`
    padding: 38px 14px 10px 14px;
  `)};
`;

const IconContainer = styled.div`
  border-radius: 50%;
  border: 1px solid rgba(131, 138, 143, 0.5);
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3c4346;
  transition: all 0.3s var(--ease-in-out-expo);
  svg {
    width: 20px;
    color: #3c4346;
    height: 20px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #e1e3e4;
  background: #fff;
  overflow: hidden;
  transition: all 0.3s var(--ease-in-out-circ);
  &:hover {
    background: #4a7b55;
    color: #fff;
    transition: all 0.3s var(--ease-in-out-circ);
    ${CardTitle} {
      color: #fff;
      transition: color 0.3s var(--ease-in-out-circ);
    }
    ${CardDescription} {
      color: #e1e3e4;
      transition: color 0.3s var(--ease-in-out-circ);
    }
    ${IconContainer} {
      background: #e1e3e4;
      transition: all 0.3s var(--ease-in-out-circ);
    }
  }
`;

export default function CardsGrid({ sectionTitle, cards, theme }) {
  return (
    <SectionContentWrapper sectionTitle={sectionTitle} theme={theme}>
      <CardsGridContainer>{cards && cards?.map((card, i) => <Card key={i} card={card} />)}</CardsGridContainer>
    </SectionContentWrapper>
  );
}

const Card = ({ card }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <CardContainer onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {card?.topLayer[0] && <CardTopLayerProvider content={card.topLayer[0]} />}
      <CardContentContainer>
        <CardTitle>{card.title}</CardTitle>
        <CardDescription>{render(card.description)}</CardDescription>
        {card.cta && card.cta.length > 0 && (
          <CtaContainer>
            <CtaRenderer
              ctas={card.cta?.map((cta) => ({ ...cta, color: hovered ? "#fff" : cta.color, colorOverride: hovered }))}
            />
          </CtaContainer>
        )}
      </CardContentContainer>
    </CardContainer>
  );
};

const CardTopLayerProvider = ({ content }) => {
  if (content.component === "cardsGridCardTopLayerImage") {
    return (
      <TopLayerImageContainer>
        <NextLazyImage src={content.image.filename} alt={content.image.alt} fill />
      </TopLayerImageContainer>
    );
  }
  if (content.component === "cardsGridCardTopLayerIcon") {
    return (
      <TopLayerIconContainer>
        <IconContainer>
          <IconRenderer icon={content.icon} />
        </IconContainer>
      </TopLayerIconContainer>
    );
  }
};
