import BlogInlineImage from "@/components/RichText/BlogInlineImage";
import WikiCard from "@/components/WikiCard/WikiCard";
import { BREAKPOINT_SM } from "@/constants";
import mq from "@/utils/mq";
import { themeProvider } from "@/utils/ThemeProvider";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";

const OuterContainer = styled.div`
  width: 100%;
`;

const RichTextContainer = styled.div`
  max-width: calc(900px + 3rem);
  width: 100%;
  margin: 0 auto;
  color: var(--PP-Text-Primary, #10181c);
  font-family: var(--font-inter);
  word-break: break-word;
  padding-bottom: 2.5rem;
  display: flow-root;
  ${mq.mobile(css`
    padding-bottom: 1.5rem;
  `)};
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: ${BREAKPOINT_SM}px) {
    max-width: calc(900px + 4.5rem);
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  p {
    font-weight: 400;

    margin-bottom: 24px;
    color: var(--PP-Text-Secondary, #677076);
    b {
      color: var(--PP-Text-Primary, #10181c);
    }
    font-size: 18px;
    line-height: 32px;
    &:has(> .addBottomMargin) {
      padding-bottom: 16px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 8px;
    font-weight: 500;
    font-family: var(--font-pp-neue);
    letter-spacing: 0.02em;
    margin-top: 32px;

    b {
      font-weight: 500;
    }

    &:first-child {
      margin-top: 0;
    }

    &:has(> .reduceTitleTopMargin) {
      margin-top: 0;
    }
  }
  h1 {
    font-size: 32px;
    line-height: 44px;
  }

  h2 {
    font-size: 28px;
    line-height: 38px;
  }

  h3 {
    font-size: 24px;
    line-height: 34px;
  }

  h4 {
    font-size: 22px;
    line-height: 30px;
  }

  h5 {
    font-size: 20px;
    line-height: 28px;
  }

  h6 {
    font-size: 18px;
    line-height: 26px;
  }

  a {
    color: var(--PrimaryAccent);
    text-decoration: underline;
    font-weight: 500;
    //offset underline
    text-underline-offset: 2px;
  }

  .fontSeasons {
    font-family: var(--font-the-seasons);
  }
  .fontNeue {
    font-family: var(--font-pp-neue);
  }

  img {
    max-width: 300px;
    border-radius: 8px;
    overflow: hidden;
    float: left;
    margin-right: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  hr {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const Title = styled.h2`
  font-size: 32px;
  line-height: 44px;
`;

export default function BlogRichTextSection({ id, title, content, theme }) {
  return (
    <OuterContainer style={{ backgroundColor: themeProvider(theme).backgroundColor }}>
      <RichTextContainer>
        <Title id={id}>{title}</Title>
        {render(content, {
          markResolvers: {
            link: (children, props) => {
              if (props.custom?.card && props?.custom?.card_id) {
                return (
                  <WikiCard
                    cardId={props.custom.card_id}
                    link={props.href}
                    target={props.target}
                    disableLink={props.custom.card_disable_link === "true"}>
                    {children}
                  </WikiCard>
                );
              }

              return (
                <a href={props.href} target={props.target}>
                  {children}
                </a>
              );
            },
          },
          blokResolvers: {
            ["blogInlineImage"]: (props) => (
              <BlogInlineImage
                aspectRatio={props.aspectRatio}
                mode={props.mode}
                asset={props.asset}
                subtitle={props.subtitle}
              />
            ),
          },
        })}
      </RichTextContainer>
    </OuterContainer>
  );
}
