import styled from "styled-components";
import { MediaPlayer, MediaProvider, Poster } from "@vidstack/react";
import { DefaultAudioLayout, defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import ContentWrapper from "@/components/Core/ContentWrapper";
import NextLazyImage from "@/components/Core/NextLazyImage";
import Image from "next/image";
import { useState } from "react";
import { PlayIcon } from "@vidstack/react/icons";
import mq from "@/utils/mq";
import { css } from "styled-components";
import { themeProvider } from "@/utils/ThemeProvider";
import VideoJsonLd from "@/components/Shared/JsonLD/VideoJsonLd";

const MediaPlayerContainer = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
  ${mq.mobile(css`
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  `)};
  .vds-youtube {
    .ytp-large-play-button-red-bg {
      display: none !important;
    }
  }
  img[data-visible] {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    object-fit: cover;
  }
  img {
    display: none;
  }
`;

const PosterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  object-fit: cover;
`;

const PlayButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px) saturate(1.5) brightness(0.9);
  color: #fff;
  cursor: pointer;
  width: 56px;
  height: 56px;
  border-radius: 8px;

  svg {
    width: 32px;
  }
`;

const StyledPoster = styled(Poster)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  object-fit: cover;
`;

export default function EmbeddedVideo({
  videoLink,
  poster,
  theme,
  title,
  description,
  lengthMinutes,
  lengthSeconds,
  uploadDate,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <ContentWrapper theme={theme}>
      <VideoJsonLd
        name={title}
        description={description}
        thumbnailUrl={[poster.filename]}
        duration={`PT${lengthMinutes}M${lengthSeconds}S`}
        contentUrl={videoLink}
        embedUrl={videoLink}
        uploadDate={uploadDate}
      />
      <MediaPlayerContainer>
        <MediaPlayer
          title='...'
          src={videoLink}
          onPlay={() => setIsPlaying(true)}
          style={{ backgroundColor: themeProvider(theme).backgroundColor }}>
          <MediaProvider>
            {!isPlaying && poster.filename && (
              <PosterContainer>
                <PlayButtonContainer>
                  <PlayButton>
                    <PlayIcon />
                  </PlayButton>
                </PlayButtonContainer>
                <Poster src={poster.filename} />
              </PosterContainer>
            )}
          </MediaProvider>
          <DefaultAudioLayout icons={defaultLayoutIcons} />
          <DefaultVideoLayout icons={defaultLayoutIcons} />
        </MediaPlayer>
      </MediaPlayerContainer>
    </ContentWrapper>
  );
}
