import React from "react";
import styled from "styled-components";
import { motion } from "motion/react";
import UpwardArrowCta from "@/components/Buttons/UpwardArrowCta";
import parseStoryblokLink from "@/utils/parseStoryblokLink";
import Link from "next/link";

const StoryContentContainer = styled.div<{ $activeStoryWidth: number }>`
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 24px 8px 0 8px;
  gap: 4px;
  width: ${({ $activeStoryWidth }) => $activeStoryWidth}px;
  overflow: hidden;
`;

const StoryTitle = styled(motion.h2)`
  margin: 0;
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  color: var(--PP-Text-Primary);
  max-width: 98%;
  margin-bottom: 4px;
`;

const StoryDescription = styled(motion.p)`
  font-size: 16px;
  line-height: 24px;
  color: #677076;
  max-width: 100%;
  margin: 0;
  margin-bottom: 8px;
`;

const CtaContainer = styled(motion.div)``;

const titleAnim = {
  hidden: {
    opacity: 0,
    y: 5,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.27,
    },
  },
};

const descAnim = {
  hidden: {
    opacity: 0,
    y: 5,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
    },
  },
};

const ctaAnim = {
  hidden: {
    opacity: 0,
    y: 5,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.33,
    },
  },
};

export default function StoryItemDetailsHandler({ active, title, description, activeStoryWidth, caseStudyLink }) {
  return (
    <StoryContentContainer $activeStoryWidth={activeStoryWidth}>
      <StoryTitle animate={active ? "visible" : "hidden"} initial={active ? "visible" : "hidden"} variants={titleAnim}>
        {title}
      </StoryTitle>
      <StoryDescription
        animate={active ? "visible" : "hidden"}
        initial={active ? "visible" : "hidden"}
        variants={descAnim}>
        {description}
      </StoryDescription>
      {caseStudyLink && caseStudyLink.cached_url && (
        <Link href={parseStoryblokLink(caseStudyLink)} style={{ textDecoration: "none" }}>
          <CtaContainer
            animate={active ? "visible" : "hidden"}
            initial={active ? "visible" : "hidden"}
            variants={ctaAnim}>
            <UpwardArrowCta label='Read Story' color='#000' />
          </CtaContainer>
        </Link>
      )}
    </StoryContentContainer>
  );
}
