import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { css } from "styled-components";
import { usePathname } from "next/navigation";
import { useHotkeys } from "react-hotkeys-hook";

import VideoPreviewBackground from "./VideoPreviewBackground";
import ContentWrapper from "@/components/Core/ContentWrapper";
import { useVideoPlayerStore } from "../store/videoPlayerStore";
import { useVideoNavigation } from "../hooks/useVideoNavigation";
import { Video } from "../types";
import VideoPreview from "@/components/VideoLibrary/core/VideoPreview";
import mq from "@/utils/mq";
import dynamic from "next/dynamic";

const VideoPlayer = dynamic(() => import("./VideoPlayer"), { ssr: false });

const VideoLibraryPreviewOuterWrapper = styled.div`
  --wrapper-padding: 176px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  width: 100vw;
  height: calc(100vh - var(--wrapper-padding));
  z-index: 5;
  padding-top: calc(var(--wrapper-padding) / 2);
  ${mq.mobile(css`
    justify-content: flex-end;
    --wrapper-padding: 142px;
  `)};
`;

interface VideoLibraryProps {
  video: Video;
}

export default function VideoLibrary({ video }: VideoLibraryProps) {
  const [isReady, setIsReady] = useState(false);
  const [isNavigatingAway, setIsNavigatingAway] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  const pathname = usePathname();
  const { registerNavigationCallback } = useVideoNavigation();
  const {
    isOpen: isPlayerOpen,
    open: openPlayer,
    close: closePlayer,
    playBackgroundVideo,
    shouldAutoPlay,
  } = useVideoPlayerStore();

  // Smooth scroll to top when pathname changes (new video selected)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    setIsNavigatingAway(false);
    setIsReady(false);

    setTimeout(() => {
      setAnimationKey((prevKey) => prevKey + 1);
      requestAnimationFrame(() => {
        setIsReady(true);
      });
    }, 50);
  }, [pathname]);

  useEffect(() => {
    const unregister = registerNavigationCallback((targetPath) => {
      if (targetPath !== pathname) {
        setIsNavigatingAway(true);
      }
    });

    return unregister;
  }, [pathname, registerNavigationCallback]);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 50);
  }, []);

  useHotkeys("p", () => {
    if (!isPlayerOpen) {
      openPlayer();
    }
  });

  useHotkeys("escape", () => {
    if (isPlayerOpen) {
      closePlayer();
    }
  });

  return (
    <VideoLibraryPreviewOuterWrapper>
      <ContentWrapper backgroundColor='#0A0B0B'>
        <VideoPreview
          video={video}
          contentRef={contentRef}
          animationKey={animationKey}
          isNavigatingAway={isNavigatingAway}
          isReady={isReady}
          openPlayer={openPlayer}
          isPlayerOpen={isPlayerOpen}
        />
      </ContentWrapper>

      <VideoPlayer video={video} isOpen={isPlayerOpen} setIsOpen={closePlayer} />

      <VideoPreviewBackground
        video={video}
        autoPlay={shouldAutoPlay}
        playing={isPlayerOpen ? false : playBackgroundVideo}
      />
    </VideoLibraryPreviewOuterWrapper>
  );
}
