import ContentWrapper from "@/components/Core/ContentWrapper";
import NextLazyImage from "@/components/Core/NextLazyImage";
import CtaRenderer from "@/components/Shared/CtaRenderer";
import IconRenderer from "@/components/Shared/IconRenderer";
import mq from "@/utils/mq";
import Marquee from "react-fast-marquee";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #e1e3e4;
  background: #fff;
  height: 412px;
  overflow: hidden;
  @media (max-width: 976px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  height: 100%;
  padding: 36px;
  width: 100%;
  @media (max-width: 1100px) {
    padding: 24px;
  }
  @media (max-width: 976px) {
    gap: 36px;
    padding: 16px;
  }
  @media (max-width: 576px) {
    gap: 12px;
    padding-top: 46px;
  }
`;

const UpperLayer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

const PretextLabel = styled.div`
  color: #4b5563;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
`;

const LowerLayer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 420px;
  @media (max-width: 976px) {
    max-width: 100%;
  }
  ${mq.mobile(css`
    gap: 8px;
  `)};
`;

const Title = styled.h2`
  color: var(--PP-Text-Primary);
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin-top: -2px;
  text-wrap: pretty;
  ${mq.mobile(css`
    font-size: 24px;
    line-height: 32px;
  `)};
`;

const Body = styled.div`
  color: #677076;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  max-width: 500px;
  justify-self: end;
  text-wrap: pretty;
  margin-bottom: 8px;
  ${mq.mobile(css`
    margin-top: 0px;
    font-size: 14px;
    line-height: 24px;
    max-width: 100%;
    justify-self: start;
  `)};
`;

const CtaButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const RightContent = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 52px;
  align-items: center;
  width: 100%;
  justify-content: center;
  @media (max-width: 1100px) {
    padding: 0 24px;
  }
  @media (max-width: 1000px) {
    padding: 0 8px;
  }
  @media (max-width: 976px) {
    margin-top: -24px;
  }
`;

const ImageRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  &.image-row-1 {
    margin-top: -164px;
  }
  &.image-row-2 {
    margin-top: -278px;
  }
  @media (max-width: 976px) {
    flex-direction: row;
    &.image-row-1 {
      margin-top: 0;
    }
    &.image-row-2 {
      margin-top: 0;
    }
  }
`;

const ImageObj = styled.div`
  width: 220px;
  height: 255px;
  border-radius: 8px;
  overflow: hidden;
  background: #f9fafb;
  @media (max-width: 980px) {
    width: 180px;
    height: 200px;
  }
  @media (max-width: 576px) {
    width: 120px;
    height: 140px;
  }
`;

export default function LargeCallToAction({
  theme,
  pretextLabel,
  pretextIcon,
  title,
  body,
  ctas,
  imageOne,
  imageTwo,
  imageThree,
  imageFour,
  imageFive,
  imageSix,
  addVerticalPaddingTop,
  addVerticalPaddingBottom,
}) {
  return (
    <ContentWrapper
      theme={theme}
      addVerticalPaddingTop={addVerticalPaddingTop}
      addVerticalPaddingBottom={addVerticalPaddingBottom}>
      <ContentContainer>
        <LeftContent>
          <UpperLayer>
            <IconRenderer icon={pretextIcon} />
            <PretextLabel>{pretextLabel}</PretextLabel>
          </UpperLayer>
          <LowerLayer>
            <Title>{title}</Title>
            <Body>{render(body)}</Body>
            <CtaButtons>
              <CtaRenderer ctas={ctas} />
            </CtaButtons>
          </LowerLayer>
        </LeftContent>
        <RightContent>
          <ImageRow className='image-row-1'>
            <ImageObject image={imageOne} />
            <ImageObject image={imageTwo} />
            <ImageObject image={imageThree} />
          </ImageRow>
          <ImageRow className='image-row-2'>
            <ImageObject image={imageFour} />
            <ImageObject image={imageFive} />
            <ImageObject image={imageSix} />
          </ImageRow>
        </RightContent>
      </ContentContainer>
    </ContentWrapper>
  );
}

const ImageObject = ({ image }) => {
  return (
    <ImageObj>
      <NextLazyImage src={image.filename} alt={image.alt} />
    </ImageObj>
  );
};
