import BlogInlineImage from "@/components/RichText/BlogInlineImage";
import WikiCard from "@/components/WikiCard/WikiCard";
import { BREAKPOINT_SM } from "@/constants";
import mq from "@/utils/mq";
import { themeProvider } from "@/utils/ThemeProvider";
import { render } from "storyblok-rich-text-react-renderer";
import styled, { css } from "styled-components";

const OuterContainer = styled.div`
  width: 100%;
`;

const RichTextContainer = styled.div`
  font-family: var(--font-inter);
  font-weight: 400;
  letter-spacing: -0.00666rem;
  font-size: 1rem;
  color: #52525c;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  margin-top: 0.65rem;

  b {
    font-weight: 600;
  }
  p {
    color: #52525c !important;
  }
  p + p {
    margin-top: 0.625rem;
  }
  span {
    color: inherit !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #09090b !important;
    letter-spacing: -0.00666rem;
    margin-top: 2rem;
  }
  ul {
    padding-left: 30px;
    margin-bottom: 0;
    margin-top: 0;
    box-sizing: border-box;
    list-style-type: disc;
  }

  li {
    margin-bottom: 16px;
  }

  a {
    color: var(--PrimaryAccent);
    text-decoration: underline;
    font-weight: 500;
    text-underline-offset: 2px;
  }

  .fontSeasons {
    font-family: var(--font-the-seasons);
  }
  .fontNeue {
    font-family: var(--font-pp-neue);
  }
  h2 {
    font-size: 1.9rem;
    line-height: 1.25;
    margin-bottom: 1.333rem;
    font-weight: 600;
  }
  h3 {
    font-size: 1.6rem;
    line-height: 1.3333333333;
    font-weight: 500;
    margin-bottom: 1.333rem;
  }
  h4 {
    font-size: 1.375rem;
    line-height: 1.3333333333;
    font-weight: 500;
    margin-bottom: 1.333rem;
  }
  h5 {
    font-size: 1.25rem;
    line-height: 1.3333333333;
    font-weight: 500;
    margin-bottom: 1.333rem;
  }
  h6 {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-weight: 500;
    margin-bottom: 1rem;
  }
`;

export default function BlogRichText({ content }) {
  return (
    <RichTextContainer>
      {render(content, {
        markResolvers: {
          link: (children, props) => {
            if (props.custom?.card && props?.custom?.card_id) {
              return (
                <WikiCard
                  cardId={props.custom.card_id}
                  link={props.href}
                  target={props.target}
                  disableLink={props.custom.card_disable_link === "true"}>
                  {children}
                </WikiCard>
              );
            }

            return (
              <a href={props.href} target={props.target}>
                {children}
              </a>
            );
          },
        },
        blokResolvers: {
          ["blogInlineImage"]: (props) => (
            <BlogInlineImage
              aspectRatio={props.aspectRatio}
              mode={props.mode}
              asset={props.asset}
              subtitle={props.subtitle}
            />
          ),
        },
      })}
    </RichTextContainer>
  );
}
