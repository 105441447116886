import { useQuery } from "@tanstack/react-query";
import StoryblokClient from "storyblok-js-client";

// Initialize the Storyblok client
const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
});

// Define the type for a case study story
interface CaseStudyStory {
  id: string;
  name: string;
  full_slug: string;
  content: {
    component: string;
    customerSectors: string[];
    // Add other relevant fields from your case study content type
  };
}

// Define the type for the Storyblok API response
interface StoryblokResponse {
  data: {
    stories: CaseStudyStory[];
  };
}

const fetchFilteredCaseStudies = async (activeSectors: string[]): Promise<CaseStudyStory[]> => {
  try {
    const response = await Storyblok.get("cdn/stories", {
      version: process.env.NEXT_PUBLIC_PRODUCTION_SITE === "true" ? "published" : "draft",
      filter_query: {
        component: {
          in: "caseStudy",
        },
        customerSectors: {
          any_in_array: activeSectors.join(","),
        },
      },

      per_page: 100, // Adjust this number based on your needs
    });

    const typedResponse = response as StoryblokResponse;
    return typedResponse.data.stories;
  } catch (error) {
    console.error("Error fetching filtered case studies:", error);
    throw error;
  }
};

export const useFilteredCaseStudies = (activeSectors: string[]) => {
  return useQuery<CaseStudyStory[], Error>({
    queryKey: ["filteredCaseStudies", activeSectors],
    queryFn: () => fetchFilteredCaseStudies(activeSectors),
    enabled: activeSectors.length > 0,
  });
};
