import ContentWrapper from "@/components/Core/ContentWrapper";
import NextLazyImage from "@/components/Core/NextLazyImage";
import SectionPill from "@/components/Shared/SectionPill";
import mq from "@/utils/mq";
import styled, { css } from "styled-components";

const PageHeroContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
  padding: 62px 0;
  @media (max-width: 1000px) {
    padding: 7rem 0 3rem 0;
  }
  ${mq.mobile(css`
    padding: 7rem 0 3rem 0;
    align-items: center;
    gap: 12px;
  `)};
`;

const Title = styled.h1`
  margin: 0;
  color: var(--PP-Text-Primary, #10181c);
  text-align: center;
  font-family: var(--font-inter);
  max-width: 548px;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  text-wrap: pretty;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 28px;
    line-height: 36px;
  `)};
`;

const Description = styled.h2`
  color: var(--PP-Text-Secondary, #677076);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  max-width: 822px;
  text-wrap: pretty;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 16px;
    line-height: 28px;
  `)};
`;

const ImageContainer = styled.div`
  aspect-ratio: 1.85/1;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 42px;
  ${mq.mobile(css`
    margin-top: 24px;
  `)};
`;

export default function PageHero({ pillText, title, description, media, theme }) {
  return (
    <ContentWrapper theme={theme}>
      <PageHeroContainer>
        {pillText && <SectionPill label={pillText} />}
        <Title>{title}</Title>
        <Description>{description}</Description>
        {media && media.length > 0 && (
          <ImageContainer>
            <MediaProvider media={media[0]} />
          </ImageContainer>
        )}
      </PageHeroContainer>
    </ContentWrapper>
  );
}

const MediaProvider = ({ media }) => {
  if (!media) return null;
  if (media?.component === "imageAsset") {
    return (
      <NextLazyImage
        key={media.image.filename}
        src={media.image.filename} 
        alt={media.image.alt || "Page hero image"}
        width={1150}
        height={620}
        quality={85}
        priority
        loading='eager'
        blur={false}
        fill
      />
    );
  }
};
