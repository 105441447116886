import { useState, useEffect, useMemo } from "react";
import ContentWrapper from "@/components/Core/ContentWrapper";
import { useAllBlogPosts } from "@/hooks/useAllBlogPosts";
import mq from "@/utils/mq";
import styled, { css } from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import BlogItemCard from "@/components/BlogRework/BlogItemCard";
import { useAllBlogCategories } from "@/hooks/useAllBlogCategories";
import { motion, AnimatePresence, LayoutGroup } from "motion/react";
import { useAllBlogTags } from "@/hooks/useAllBlogTags";
import BlogPostsControls from "./BlogPostsControls";
import NoResultIcon from "@/assets/svg/noResultSearchIcon.svg";
import BackgroundImage from "@/assets/svg/noResultBackground.svg";

const StoriesOuterContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

const StoriesContainer = styled(motion.div)`
  display: grid;
  width: 100%;
  max-width: 1180px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  grid-row-gap: 24px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const BlogContentContainer = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
  ${mq.mobile(css`
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  `)};
`;

const LoadingMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  padding: 2rem 0;
`;

const TitleSection = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 12px;
`;

const Title = styled.h2`
  color: #10181c;
  font-family: var(--font-family-inter);
  font-size: 32px;
  text-wrap: pretty;
  font-weight: 400;
  letter-spacing: -0.96px;
  line-height: 40px;
  margin-top: -2px;
  user-select: none;
  ${mq.mobile(css`
    font-size: 24px;
    line-height: 32px;
  `)};
`;

const NoResultsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 80px 20px;
  max-width: 1180px;
  margin: 0 auto;
  width: 100%;
  background-color: transparent;
  position: relative;
  will-change: auto;
`;

const NoResultsTitle = styled.h2`
  color: var(--PP-Text-Primary);
  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 4px;
  z-index: 4;
  margin-top: 18px;
`;

const NoResultsDescription = styled.p`
  color: var(--PP-Text-Secondary);
  font-size: 14px;
  line-height: 20px;
  max-width: 460px;
  margin: 0 auto;
  z-index: 4;
`;

const NoResultsBackgroundImage = styled.div`
  position: absolute;
  top: -178px;
  left: -178px;
  width: 400px;
  height: 400px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dae3e9;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const NoResultsIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: relative;
`;

const NoResultsIconInnerContainer = styled.div`
  width: 46px;
  height: 46px;
  svg {
    width: 46px;
    height: 46px;
    z-index: 4;
  }
`;

const cardVariants = {
  hidden: { opacity: 0, y: 20, scale: 0.95 },
  visible: (index: number) => ({
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      delay: index * 0.075,
      ease: [0.23, 1, 0.32, 1],
    },
  }),
  exit: {
    opacity: 0,
    scale: 0.9,
    transition: {
      duration: 0.2,
      ease: [0.23, 1, 0.32, 1],
    },
  },
};

const noResultsVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: [0.23, 1, 0.32, 1],
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2,
      ease: [0.23, 1, 0.32, 1],
    },
  },
};

export default function BlogAllPosts() {
  const { data: categories } = useAllBlogCategories();
  const { data, isLoading } = useAllBlogPosts();
  const { data: tags, isLoading: isLoadingTags } = useAllBlogTags();
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(["all"]);
  const [selectedTags, setSelectedTags] = useState<string[]>(["all"]);
  const [searchQuery, setSearchQuery] = useState("");
  const postsPerPage = 9;

  const filteredPosts = useMemo(() => {
    if (!data) return [];

    return data.filter((post) => {
      const matchesCategory =
        selectedCategories.includes("all") ||
        (post.content.category?.slug && selectedCategories.includes(post.content.category.slug));

      const matchesTags =
        selectedTags.includes("all") ||
        post?.content?.tags?.some((tag: { uuid: string }) => selectedTags.includes(tag.uuid));

      const matchesSearch =
        post?.content?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        post?.content?.excerpt?.toLowerCase().includes(searchQuery.toLowerCase());

      return matchesCategory && matchesTags && matchesSearch;
    });
  }, [data, selectedCategories, selectedTags, searchQuery]);

  useEffect(() => {
    setDisplayedPosts(filteredPosts.slice(0, postsPerPage));
    setHasMore(filteredPosts.length > postsPerPage);
  }, [filteredPosts]);

  const fetchMoreData = () => {
    if (displayedPosts.length >= filteredPosts.length) {
      setHasMore(false);
      return;
    }

    setDisplayedPosts(filteredPosts.slice(0, displayedPosts.length + postsPerPage));
  };

  const handleCategoryChange = (category: string) => {
    let newCategories: string[];
    if (category === "all") {
      newCategories = ["all"];
    } else {
      const withoutAll = selectedCategories.filter((c) => c !== "all");
      if (withoutAll.includes(category)) {
        newCategories = withoutAll.filter((c) => c !== category);
      } else {
        newCategories = [...withoutAll, category];
      }
      if (newCategories.length === 0) newCategories = ["all"];
    }
    setSelectedCategories(newCategories);
  };

  const handleTagChange = (tagUuid: string) => {
    let newTags: string[];
    if (tagUuid === "all") {
      newTags = ["all"];
    } else {
      const withoutAll = selectedTags.filter((t) => t !== "all");
      if (withoutAll.includes(tagUuid)) {
        newTags = withoutAll.filter((t) => t !== tagUuid);
      } else {
        newTags = [...withoutAll, tagUuid];
      }
      if (newTags.length === 0) newTags = ["all"];
    }
    setSelectedTags(newTags);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  if (isLoading) {
    return <LoadingMessage>Loading...</LoadingMessage>;
  }

  return (
    <ContentWrapper>
      <TitleSection>
        <Title>All posts</Title>
      </TitleSection>
      <BlogPostsControls
        categories={categories || []}
        tags={tags || []}
        selectedCategories={selectedCategories}
        selectedTags={selectedTags}
        searchQuery={searchQuery}
        onCategoryChange={handleCategoryChange}
        onTagChange={handleTagChange}
        onSearchChange={setSearchQuery}
      />

      <InfiniteScroll
        style={{ width: "100%" }}
        dataLength={displayedPosts.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<LoadingMessage>Loading...</LoadingMessage>}>
        <StoriesOuterContainer>
          {displayedPosts.length === 0 ? (
            <NoResultsContainer
              key='no-results'
              variants={noResultsVariants}
              initial='hidden'
              animate='visible'
              exit='exit'>
              <NoResultsIconContainer>
                <NoResultsBackgroundImage>
                  <BackgroundImage />
                </NoResultsBackgroundImage>
                <NoResultsIconInnerContainer>
                  <NoResultIcon />
                </NoResultsIconInnerContainer>
              </NoResultsIconContainer>
              <NoResultsTitle>No resources found</NoResultsTitle>
              <NoResultsDescription>
                {`We couldn't find any resources matching your current filters. Try adjusting your search criteria or
                  removing some filters.`}
              </NoResultsDescription>
            </NoResultsContainer>
          ) : (
            <LayoutGroup>
              <StoriesContainer layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <AnimatePresence mode='popLayout'>
                  {displayedPosts?.map((post, i) => (
                    <motion.div
                      key={post.uuid}
                      layout
                      variants={cardVariants}
                      initial='hidden'
                      animate='visible'
                      exit='exit'
                      custom={i % 3}>
                      <BlogItemCard story={post} />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </StoriesContainer>
            </LayoutGroup>
          )}
        </StoriesOuterContainer>
      </InfiniteScroll>
    </ContentWrapper>
  );
}
