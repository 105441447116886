import styled, { css } from "styled-components";
import IconRenderer from "@/components/Shared/IconRenderer";
import parseStoryblokLink from "@/utils/parseStoryblokLink";
import Link from "next/link";

const SectorCardContainer = styled.div`
  width: 100%;
  display: flex;
  //flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  background-color: #fefefe;
  cursor: pointer;
  padding: 12px;
  height: 100%;
  border-radius: 4px;
  transition: 0.1s;
  border: 1px solid #e0e1e2;

  &:hover {
    background-color: #f3f4f6;
    transition: 0.1s;
  }
`;

const IconContainer = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;

  svg {
    width: 18px;
    height: 18px;
    color: var(--PP-Text-Secondary);
  }
`;

const SectorCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  //min-height: 40px;
  //justify-content: flex-end;
`;

const SectorLabel = styled.div`
  color: #171717;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  padding-top: 2px;
`;

const SectorDescription = styled.div`
  color: #666;
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  //text-wrap: pretty;
`;

export default function SectorCard({ sector }) {
  return (
    <Link href={sector.full_slug} style={{ textDecoration: "none" }}>
      <SectorCardContainer>
        <IconContainer>
          <IconRenderer icon={sector.content.icon} size={32} />
        </IconContainer>
        <SectorCardContentWrapper>
          <SectorLabel>{sector.content.menuTitle}</SectorLabel>
          <SectorDescription>{sector.content.menuDescription}</SectorDescription>
        </SectorCardContentWrapper>
      </SectorCardContainer>
    </Link>
  );
}
