import BlogAllPosts from "@/components/Blog/BlogAllPosts/BlogAllPosts";
import BlogRootHero from "@/components/BlogRootRework/BlogRootHero";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import { generateSeoBreadcrumbs } from "@/utils/generateSeoBreadcrumbs";
import BreadcrumbJsonLd from "@/components/Shared/JsonLD/BreadcrumbJsonLd";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { render } from "storyblok-rich-text-react-renderer";
import styled from "styled-components";
import BlogRootContent from "@/components/BlogRootRework/BlogRootContent";
import BlogRootDynamicContent from "@/components/BlogRootRework/BlogRootDynamicContent";

const BlogRootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StoryblokBlogRoot = ({ blok }: { blok: any }) => {
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_ROOT_DOMAIN || "https://www.plantplan.co.uk/";
  return (
    <BlogRootContainer>
      {blok.breadcrumbs && blok.breadcrumbs.length > 0 && (
        <BreadcrumbJsonLd
          items={generateSeoBreadcrumbs({
            breadcrumbs: blok.breadcrumbs,
            baseUrl,
            currentPath: router.asPath,
            pageTitle: blok.title,
          })}
          baseUrl={baseUrl}
        />
      )}
      <NextSeo additionalMetaTags={[{ name: "keywords", content: blok?.keywords?.value?.join(", ") }]} />
      <BlogRootHero
        title={blok.title}
        bodyText={render(blok.bodyText)}
        quickLinkCards={blok.quickLinkCards}
        enableQuickLinkCards={blok.enableQuickLinkCards}
        pillText={blok.labelText}
      />
      <BlogRootDynamicContent blok={blok} />
      <BlogAllPosts />
    </BlogRootContainer>
  );
};

export default StoryblokBlogRoot;
