import styled, { css } from "styled-components";
import { motion } from "motion/react";
import IconRenderer from "@/components/Shared/IconRenderer";
import Close from "@/assets/svg/closeIcon.svg";

const FilterChipContainer = styled(motion.div)<{ $isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 4px 14px 4px 12px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #f3f4f6;
  height: 31px;
  color: var(--PP-Section-Title, #4b5563);
  cursor: pointer;
  ${(props) =>
    props.$isSelected &&
    css`
      background-color: var(--PrimaryAccent);
      color: #fff;
    `}
`;

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
`;

const Label = styled.div`
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  user-select: none;
`;

const SkeletonContainer = styled.div`
  height: 24px;
`;

const RemoveIconOuterContainer = styled(motion.div)`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -2px;
`;

const RemoveIcon = styled(motion.div)`
  width: 16px;
  min-width: 16px;
  height: 16px;
  color: #fff;
  cursor: pointer;
`;

export default function FilterChip({
  label,
  icon,
  id,
  isLoading,
  width,
  isSelected,
  onClick,
}: {
  label?: string;
  icon?: any;
  id?: any;
  isLoading?: boolean;
  width?: number;
  isSelected?: boolean;
  onClick?: any;
}) {
  return (
    <FilterChipContainer $isSelected={isSelected} onClick={onClick}>
      <IconContainer>
        <IconRenderer icon={icon} color={"#fff"} />
      </IconContainer>
      <Label>{label}</Label>

      <RemoveIconOuterContainer>
        <RemoveIcon>
          <Close />
        </RemoveIcon>
      </RemoveIconOuterContainer>
    </FilterChipContainer>
  );
}
