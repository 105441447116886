import ContentWrapper from "@/components/Core/ContentWrapper";
import styled from "styled-components";
import { Spotify } from "react-spotify-embed";

const SpotifyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpotifyContainer = styled.div<{ $enableVerticalPadding?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: ${(props) => (props.$enableVerticalPadding ? "2rem 0" : "0")};
`;

export default function EmbeddedSpotifyLink({ spotifyLink, wideMode, theme, enableVerticalPadding }) {
  return (
    <ContentWrapper theme={theme}>
      <SpotifyWrapper>
        <SpotifyContainer $enableVerticalPadding={enableVerticalPadding}>
          <Spotify link={spotifyLink} wide={wideMode} width='100%' />
        </SpotifyContainer>
      </SpotifyWrapper>
    </ContentWrapper>
  );
}
