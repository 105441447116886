import Faqs from "./faqs";

const FaqsWithProps = ({ blok }) => {
  return (
    <Faqs
      pretext={blok.pretext}
      icon={blok.icon}
      title={blok.title}
      description={blok.description}
      ctas={blok.ctas}
      faqItems={blok.faqItems}
      theme={blok.theme}
      enableMetaJsonLD={blok.enableMetaJsonLD}
    />
  );
};

export default FaqsWithProps;
