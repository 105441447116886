import ContentWrapper from "@/components/Core/ContentWrapper";
import JobListingBackButton from "@/components/JobListing/JobListingBackButton";
import JobListingBody from "@/components/JobListing/JobListingBody";
import JobListingHero from "@/components/JobListing/JobListingHero/JobListingHero";
import JobListingTableOfContents from "@/components/JobListing/JobListingTableOfContents";
import BreadcrumbJsonLd from "@/components/Shared/JsonLD/BreadcrumbJsonLd";
import BlokRenderer from "@/lib/storyblok/BlokRenderer";
import { generateSeoBreadcrumbs } from "@/utils/generateSeoBreadcrumbs";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import styled, { css } from "styled-components";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from "react-scroll";
import mq from "@/utils/mq";
import BlogContentWrapper from "@/components/BlogRework/BlogContentWrapper";
import { EmploymentLocationParser, SalaryParser } from "@/components/JobListing/Shared";
import { EmploymentTypeParser } from "@/components/JobListing/Shared";
import JobListingJsonLd from "@/components/Shared/JsonLD/JobListingJsonLd";

const JobContentOuterWrapper = styled.div`
  background-color: #fff;
`;

const JobContentWrapper = styled.div`
  width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 92.5rem;
  padding-inline: 1rem 0;
  padding-top: 64px;
  padding-bottom: 64px;
  ${mq.mobile(css`
    padding-inline: 20px;
    padding-top: 26px;
    padding-bottom: 26px;
  `)};
`;

const JobListingUpperLayer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 46px;
  padding-top: 2rem;
  margin-top: 2rem;
  position: relative;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 16px;
    padding-top: 62px;
  }
`;

const JobListingBodyLayer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8rem;
  flex-direction: row;
  background-color: #fff;
  @media (max-width: 1000px) {
    gap: 2rem;
  }
  ${mq.mobile(css`
    flex-direction: column;
  `)};
`;

const BlogPostTableOfContentsWrapper = styled.div`
  z-index: 99;
  position: initial;
  padding-right: 0px;
  padding-left: 4rem;
  width: 30%;
  ${mq.mobile(css`
    width: 100%;
    padding-left: 0;
    display: none;
  `)};
`;

const BlogPostContentWrapper = styled.div`
  width: 56%;
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const StoryblokJobListing = ({ blok }: { blok: any }) => {
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_ROOT_DOMAIN || "https://www.plantplan.co.uk/";
  const fullUrl = `${baseUrl}${router.asPath}`;

  return (
    <JobContentOuterWrapper>
      <JobListingJsonLd job={blok} baseUrl={baseUrl} fullUrl={fullUrl} />
      <JobListingHero
        title={blok.title}
        description={blok.description}
        employmentType={EmploymentTypeParser(blok.employmentType)}
        location={EmploymentLocationParser({ type: blok.location[0].type, town: blok.location[0].town })}
        salary={SalaryParser({
          minValue: blok.salary[0].minValue,
          maxValue: blok.salary[0].maxValue,
          type: blok.salary[0].type,
        })}
        workingHours={blok.workingHours}
        datePosted={blok.datePosted}
      />
      <JobContentWrapper>
        {/* <JobListingUpperLayer>
          <JobListingBackButton />
          <JobListingHero title={blok.title} />
        </JobListingUpperLayer> */}
        <JobListingBodyLayer>
          <BlogPostTableOfContentsWrapper>
            <JobListingTableOfContents content={[...blok.content, { title: "Apply for this job", _uid: "apply" }]} />
          </BlogPostTableOfContentsWrapper>
          <BlogPostContentWrapper>
            <JobListingBody
              title={blok.title}
              content={blok.content}
              description={blok.jobDescription}
              hubspotFormEmbed={blok.hubspotFormEmbed}
            />
          </BlogPostContentWrapper>
        </JobListingBodyLayer>
      </JobContentWrapper>
    </JobContentOuterWrapper>
  );
};

export default StoryblokJobListing;
