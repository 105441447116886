import HomepageHero from "@/components/HomepageHero/HomepageHero";
import React from "react";
import { render } from "storyblok-rich-text-react-renderer";

export default function index({ blok }) {
  return (
    <HomepageHero
      title={render(blok.title)}
      bodyText={render(blok.bodyText)}
      ctas={blok.ctas}
      backgroundMedia={blok.backgroundMedia}
      mediaOverlay={blok.mediaOverlay}
      theme={blok.theme}
    />
  );
}
