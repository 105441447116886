import { useVisibleSection } from "@/hooks/useVisibleSection";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from "react-scroll";
import mq from "@/utils/mq";

export const TableOfContentsOuterContainer = styled.aside`
  position: relative;
  height: 100%;
  ${mq.mobile(css`
    display: none;
  `)};
`;

export const TableOfContentsContainer = styled.div`
  z-index: 10;
  width: 100%;
  background: transparent;
  position: sticky;
  top: 104px;
  padding-bottom: 4rem;
  ${mq.mobile(css`
    top: 0;
    padding-bottom: 0;
  `)};
`;

export const TableOfContentsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;

  padding: 4px 0;
  justify-content: flex-start;
  -webkit-box-pack: start;
`;

export const TableOfContentsTitle = styled.h6`
  margin-bottom: 1rem;
  font-size: 0.68rem;
`;

export const TableOfContentsItems = styled.ul`
  padding-inline-start: 2px;
`;

export const ItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  height: 2rem;
  font-size: 0.9rem;

  color: #67676f;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
  &.active {
    color: #424243;
    transition: all 0.2s ease-in-out;
    opacity: 1;
  }
`;

export const TableOfContentsItemWrapper = styled.li`
  list-style: none;
  line-height: 140%;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 0;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding-bottom: 0.4rem;
  border-left: 1px solid #67676f45;

  transition: all 0.2s ease-in-out;
  &:has(.active) {
    border-left: 2px solid #424243 !important;
    transition: all 0.2s ease-in-out;
  }
`;

export default function BlogPostTableOfContents({ content }) {
  const [activeSection, setActiveSection] = useState(content[0]?._uid || "");
  const visibleSection = useVisibleSection({
    sections: content?.map((item) => ({ id: item._uid })),
  });

  useEffect(() => {
    setActiveSection(visibleSection);
  }, [visibleSection]);

  if (!content || content.length === 0) {
    return null;
  }

  return (
    <TableOfContentsOuterContainer>
      <TableOfContentsContainer>
        <TableOfContentsContent>
          <TableOfContentsTitle>Table of Contents</TableOfContentsTitle>

          <TableOfContentsItems>
            {content &&
              content?.length > 0 &&
              content?.map((item) => {
                if (item.hideFromTableOfContents) {
                  return null;
                }
                return (
                  <TableOfContentsItem
                    label={item.title}
                    key={item._uid}
                    id={item._uid}
                    isActive={activeSection === item._uid}
                    setActiveSection={setActiveSection}
                    customLabel={item.customLabel}
                  />
                );
              })}
          </TableOfContentsItems>
        </TableOfContentsContent>
      </TableOfContentsContainer>
    </TableOfContentsOuterContainer>
  );
}

export const TableOfContentsItem = ({
  label,
  id,
  isActive,
  setActiveSection,
  customLabel,
}: {
  label: string;
  id: string;
  isActive: boolean;
  setActiveSection: Dispatch<SetStateAction<string>>;
  customLabel: string;
}) => {
  useEffect(() => {
    Events.scrollEvent.register("begin", (to, element) => {});

    Events.scrollEvent.register("end", (to, element) => {});

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <TableOfContentsItemWrapper>
      <ItemLink activeClass='active' to={id} spy={true} smooth={true} offset={-94} duration={500}>
        {customLabel && customLabel !== "" ? customLabel : label}
      </ItemLink>
    </TableOfContentsItemWrapper>
  );
};
