import styled from "styled-components";
import { useRouter } from "next/router";
import Button from "@/components/Buttons/Button";
import NextLazyImage from "@/components/Core/NextLazyImage";
import { useState, useRef } from "react";
import { motion } from "motion/react";
import ArrowToRightIcon from "@/assets/svg/arrowToRight.svg";
import { css } from "styled-components";
import mq from "@/utils/mq";
import LabelCta from "@/components/Buttons/LabelCta";
import { format } from "date-fns";

const BlogItemCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  will-change: auto;
  width: 100%;
  height: 100%;
  ${mq.mobile(css`
    height: unset;
  `)};
`;

const ThumbnailContainer = styled(motion.div)`
  height: 240px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
  background-color: #f3f4f6;
  * {
    user-select: none;
  }
`;

const BlogItemCardContent = styled.div`
  width: 100%;
`;

const BlogItemTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  margin-top: 4px;
  margin-bottom: 8px;
`;

const BlogItemCardMeta = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const MetaItem = styled.span`
  color: #4c7c56;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  user-select: none;
`;

const MetaDivider = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #4c7c56;
  margin: 0 6px;
`;

const BlogItemCardTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BlogItemCardTitle = styled.h3`
  margin: 0;
  margin-top: 8px;
  margin-bottom: 2px;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  color: var(--PP-Text-Primary);
  font-weight: 500;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  user-select: none;
`;

const BlogItemCardIcon = styled.div``;

const BlogItemCardDescription = styled.p`
  margin-bottom: 24px;
  overflow: hidden;
  color: #677076;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  user-select: none;
  ${mq.mobile(css`
    margin-bottom: 16px;
    margin-top: 4px;
  `)};
`;

const BlogItemCardCta = styled.div``;

const HoveredContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
`;

const HoverLabel = styled(motion.span)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const HoverIcon = styled(motion.div)`
  --size: 20px;
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  z-index: 10;
`;

const containerAnim = {
  hidden: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    transition: {
      delay: 0.1,
    },
  },
  visible: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const labelAnim = {
  hidden: {
    opacity: 0,
    y: -15,
    transition: {
      delay: 0.03,
      ease: [0.26, 1, 0.5, 1],
    },
  },
  visible: {
    opacity: 1,
    y: [15, 0],
    transition: {
      delay: 0.125,
      ease: [0.26, 1, 0.5, 1],
    },
  },
};

const iconAnim = {
  hidden: {
    opacity: 0,
    y: -15,
    transition: {
      delay: 0.0,
      ease: [0.26, 1, 0.5, 1],
    },
  },
  visible: {
    opacity: 1,
    y: [15, 0],
    transition: {
      delay: 0.15,
      ease: [0.26, 1, 0.5, 1],
    },
  },
};

export default function BlogItemCard({ story }) {
  const [hovered, setHovered] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const handleThumbnailClick = () => {
    if (buttonRef.current) {
      (buttonRef.current.firstChild as HTMLButtonElement).click();
    }
  };

  return (
    <BlogItemCardContainer>
      <ThumbnailContainer
        onHoverStart={() => setHovered(true)}
        onHoverEnd={() => setHovered(false)}
        onClick={handleThumbnailClick}>
        <HoveredContainer
          animate={hovered ? "visible" : "hidden"}
          initial={hovered ? "visible" : "hidden"}
          variants={containerAnim}>
          <HoverIcon
            animate={hovered ? "visible" : "hidden"}
            initial={hovered ? "visible" : "hidden"}
            variants={iconAnim}>
            <ArrowToRightIcon />
          </HoverIcon>
          <HoverLabel
            animate={hovered ? "visible" : "hidden"}
            initial={hovered ? "visible" : "hidden"}
            variants={labelAnim}>
            Read more
          </HoverLabel>
        </HoveredContainer>
        <motion.div
          animate={{
            scale: hovered ? 1.1 : 1,
            filter: hovered ? "brightness(0.8)" : "brightness(1)",
            transition: {
              ease: [0.26, 1, 0.5, 1],
              duration: 0.7,
            },
          }}
          style={{ width: "100%", height: "100%", position: "relative" }}>
          <NextLazyImage
            src={story.content.thumbnail.filename}
            alt={story.content.title}
            fill
            style={{ backgroundColor: "#F3F4F6", objectFit: "cover", width: "100%", height: "100%" }}
          />
        </motion.div>
      </ThumbnailContainer>
      <BlogItemCardContent>
        <BlogItemTextContent>
          <BlogItemCardMeta>
            {story?.content?.category?.content?.title && (
              <MetaItem>{story?.content?.category?.content?.title}</MetaItem>
            )}
            {story?.content?.category?.content?.title && <MetaDivider />}
            {story?.content?.publishDate && (
              <MetaItem>{format(new Date(story?.content.publishDate), "do MMMM yyyy")}</MetaItem>
            )}
            {story?.content?.timeToRead && <MetaDivider />}
            {story?.content?.timeToRead && <MetaItem>{story?.content?.timeToRead} min read</MetaItem>}
          </BlogItemCardMeta>
          <BlogItemCardTitleContainer>
            <BlogItemCardTitle>{story.content.title}</BlogItemCardTitle>
            <BlogItemCardIcon></BlogItemCardIcon>
          </BlogItemCardTitleContainer>
          <BlogItemCardDescription>{story.content.description}</BlogItemCardDescription>
        </BlogItemTextContent>
        <BlogItemCardCta>
          <div ref={buttonRef}>
            <LabelCta rawLink={"/" + story.full_slug} label='Read more' includeArrow color='#4C7C55' />
          </div>
        </BlogItemCardCta>
      </BlogItemCardContent>
    </BlogItemCardContainer>
  );
}
