import styled, { css } from "styled-components";
import CaseStudyStoriesSliderMobile from "@/components/CaseStudyStoriesSlider/CaseStudyStoriesSliderMobile";
import CaseStudyStoriesSlider from "./CaseStudyStoriesSlider";
import mq from "@/utils/mq";

const Desktop = styled.div`
  display: block;
  ${mq.mobile(css`
    display: none;
  `)};
`;

const Mobile = styled.div`
  display: none;
  ${mq.mobile(css`
    display: block;
  `)};
`;

const CaseStudyStoriesSliderWithProps = ({ blok }) => {
  return (
    <>
      <Desktop>
        <CaseStudyStoriesSlider
          sectionTitle={blok.sectionTitle}
          stories={blok.stories}
          theme={blok.theme}
          progressionSpeed={blok.progressionSpeed}
          slowProgressionOnHover={blok.slowProgressionOnHover}
        />
      </Desktop>
      <Mobile>
        <CaseStudyStoriesSliderMobile
          sectionTitle={blok.sectionTitle}
          stories={blok.stories}
          theme={blok.theme}
          progressionSpeed={blok.progressionSpeed}
          slowProgressionOnHover={blok.slowProgressionOnHover}
        />
      </Mobile>
    </>
  );
};

export default CaseStudyStoriesSliderWithProps;
