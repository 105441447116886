import styled from "styled-components";
import ChevronRight from "@/assets/svg/chevronRight.svg";
import Link from "next/link";
import parseStoryblokLink from "@/utils/parseStoryblokLink";

const ContactUsHeroActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #f3f4f6;
  padding: 22px;
`;

const ActionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const Label = styled.h3`
  color: #4b5563;
  font-family: var(--font-pp-neue);
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
`;

const Text = styled.p`
  color: var(--PP-Text-Primary, #10181c);
  font-family: var(--font-pp-neue);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

const ActionChevron = styled.div`
  width: 16px;
  height: 16px;
  color: #10181c;
  svg {
    color: #10181c;
  }
`;

export default function ContactUsHeroAction({ label, text, link }) {
  return (
    <Link href={parseStoryblokLink(link)} style={{ textDecoration: "none" }} target={link.target}>
      <ContactUsHeroActionWrapper>
        <ActionContent>
          <Label>{label}</Label>
          <Text>{text}</Text>
        </ActionContent>
        <ActionChevron>
          <ChevronRight />
        </ActionChevron>
      </ContactUsHeroActionWrapper>
    </Link>
  );
}
