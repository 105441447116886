import { useQuery } from "@tanstack/react-query";
import StoryblokClient from "storyblok-js-client";

interface BlogCategory {
  id: string;
  name: string;
  slug: string;
}

interface StoryblokResponse {
  stories: BlogCategory[];
}

const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
});

const fetchAllCategories = async (): Promise<BlogCategory[]> => {
  try {
    const response = await Storyblok.get("cdn/stories/", {
      version: process.env.NEXT_PUBLIC_PRODUCTION_SITE === "true" ? "published" : "draft",
      filter_query: {
        component: {
          in: "blogPostCategory",
        },
      },
      per_page: 100,
    });

    return (response.data as StoryblokResponse).stories;
  } catch (error) {
    console.error("Error fetching blog categories:", error);
    throw error;
  }
};

export const useAllBlogCategories = () => {
  return useQuery<BlogCategory[], Error>({
    queryKey: ["allBlogCategories"],
    queryFn: fetchAllCategories,
  });
};
