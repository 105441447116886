import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import { useAlgoliaSearch } from "@/hooks/useAlgoliaSearch";
import { SearchInput } from "@/components/Search";
import ActiveFiltersBox from "@/components/Search/SearchResultsBox/SearchResultsBox";
import SearchFilterPill from "@/components/SearchLLM/SearchFilterPill";
import SectorPillGrid from "@/components/CaseStudiesRootHero/SectorPillGrid";
import Pinpoint from "@/components/Pinpoint";

const SectorSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  width: 100%;
`;

interface Sector {
  objectID: string;
  title: string;
  icon?: any;
  _uid: string;
  [key: string]: any;
}

export default function CaseStudySectorSearch({ onSectorToggle, activeSectors, setActiveSectors }) {
  const [searchQuery, setSearchQuery] = useState("");

  const router = useRouter();

  const { data: searchResults, isLoading } = useAlgoliaSearch({
    query: searchQuery,
    contentTypes: ["sectorPage"],
  });

  // Ensure searchResults is an array
  const formattedResults = Array.isArray(searchResults) ? searchResults : [];

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleSelect = (result: Sector) => {
    onSectorToggle(result);
  };

  const handleRemove = (sector: Sector) => {
    onSectorToggle(sector);
  };

  return (
    <SectorSearchContainer>
      <Pinpoint
        onSearch={handleSearch}
        placeholder='Search for your sector'
        isLoading={isLoading}
        results={formattedResults} // Updated line
        selectedResults={activeSectors}
        onSelect={handleSelect}
      />

      <ActiveFiltersBox filters={activeSectors} title='Selected Sectors' onRemove={handleRemove} />
      <SectorPillGrid activeSectors={activeSectors} onSectorToggle={onSectorToggle} />
    </SectorSearchContainer>
  );
}
